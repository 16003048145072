import { InMemoryCache } from '@apollo/client/cache/inmemory/inMemoryCache'
import { Cache } from '@apollo/client/cache/core/types/Cache'
import { Reference } from '@apollo/client/utilities'
import { InMemoryCacheConfig } from '@apollo/client/cache/inmemory/types'

export class MultiContextMemoryCache extends InMemoryCache {
  private cacheChannel: BroadcastChannel

  constructor(config?: InMemoryCacheConfig & { channelId?: string }) {
    super(config)
    this.cacheChannel = new BroadcastChannel(
      `apollo_cache_channel_${config?.channelId ?? 'main'}`,
    )

    this.cacheChannel.onmessage = (message: MessageEvent) => {
      try {
        const payload = JSON.parse(message.data)
        super.write(payload)
      } catch (error) {
        console.error('Error in received message', {
          message,
          error,
        })
      }
    }
  }

  write(options: Cache.WriteOptions): Reference | undefined {
    if (
      options.query.definitions.every(
        (node) =>
          node.kind !== 'OperationDefinition' || node.operation === 'query',
      ) &&
      options.result._context?.dontBroadcast !== true
    ) {
      this.cacheChannel.postMessage(JSON.stringify(options))
    }

    return super.write(options)
  }
}
