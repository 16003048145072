<template>
  <q-drawer
    :breakpoint="500"
    :class="drawerClasses"
    :mini="isMini"
    :mini-width="2"
    show-if-above
    :side="side"
    :style="`--drawer-width: ${width}px`"
    :width="width"
  >
    <div class="drawer__inner">
      <slot />
    </div>
    <div
      class="drawer__deep"
      :class="{ open: !!workspaceView.deepDrawersOpen[side] }"
    >
      <template v-if="deepDrawerProperties">
        <component
          :is="deepDrawerProperties.component"
          v-bind="deepDrawerProperties.props ?? {}"
          @close="workspaceView.closeDeepDrawer(side)"
        />
      </template>
    </div>
    <button
      v-if="!workspaceView.uiIsBlocked && !fixed"
      class="drawer__toggle"
      @click="toggleDrawer"
    >
      <q-icon
        v-if="side === 'left'"
        :name="isOpen ? 'chevron_left' : 'chevron_right'"
      />
      <q-icon v-else :name="isOpen ? 'chevron_right' : 'chevron_left'" />
    </button>
  </q-drawer>
</template>
<script setup lang="ts">
import { defineProps, computed, provide } from 'vue'
import {
  DeepDrawerProperties,
  DrawerSide,
  useWorkspaceView,
} from 'src/stores/useWorkspaceView'

const workspaceView = useWorkspaceView()
const props = withDefaults(
  defineProps<{
    side?: DrawerSide
    fixed?: boolean
    width?: number
  }>(),
  {
    side: DrawerSide.left,
    fixed: false,
    width: 290,
  },
)
const toggleDrawer = () => {
  if (workspaceView.uiIsBlocked) {
    return
  }
  workspaceView.toggleDrawer(props.side)
}

const isOpen = computed<boolean>(() => {
  return workspaceView.drawersOpen[props.side]
})
const isMini = computed<boolean>(() => {
  return (
    !props.fixed &&
    (workspaceView.uiIsBlocked ||
      (workspaceView.uiIsHidden && !isOpen.value) ||
      !isOpen.value)
  )
})
const deepDrawerProperties = computed<DeepDrawerProperties | null>(() => {
  const properties = workspaceView.deepDrawersOpen[props.side]
  if (!properties) {
    return null
  }
  return properties as DeepDrawerProperties
})
const drawerClasses = computed<Record<string, boolean>>(() => ({
  drawer: true,
  [`drawer--${props.side}`]: true,
  'drawer--mini': isMini.value,
  'drawer--fixed': props.fixed,
}))
provide('drawerSide', props.side)
</script>

<style lang="scss">
.drawer {
  background-color: var(--base-color) !important;
  overflow-x: hidden;

  &__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: visible;
    width: var(--drawer-width);
    background-color: var(--lighten-color);
    transition: all ease-out var(--discover-drawer-transition-speed);
    margin-top: 5px;
  }

  &__toggle {
    position: absolute;
    z-index: 1;

    height: 40px;
    padding: 0;
    top: 50%;
    border: none;

    color: var(--text-color);
    background-color: var(--lighten-color);

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:hover {
      background-color: var(--highlight-color);
      color: var(--highlight-text-color);
    }
  }

  &__deep {
    position: absolute;
    overflow-x: hidden;
    z-index: 100;
    bottom: 0;
    top: 0;
    padding: 5px;
    width: var(--drawer-width);
    background-color: var(--lighten-color);
    transition: all ease-out var(--discover-deep-drawer-transition-speed);
  }

  &--left {
    border-right: 2px solid var(--lighten-color) !important;

    .drawer__inner {
      left: 0;
    }

    .drawer__toggle {
      right: -14px;

      &:before {
        border-width: 10px 0 0 14px;
        border-color: transparent transparent transparent var(--lighten-color);
      }

      &:after {
        border-width: 10px 14px 0 0;
        border-color: var(--lighten-color) transparent transparent transparent;
      }

      &:hover {
        &:before {
          border-color: transparent transparent transparent
            var(--highlight-color);
        }

        &:after {
          border-color: var(--highlight-color) transparent transparent
            transparent;
        }
      }
    }

    &.drawer--mini .drawer__inner {
      left: calc(-1 * var(--drawer-width));
    }

    .drawer__deep {
      left: calc(-1 * var(--drawer-width));

      &.open {
        right: 0;
        left: 0;
      }
    }
  }

  &--right {
    border-left: 2px solid var(--lighten-color) !important;

    .drawer__toggle {
      left: -14px;

      &:before {
        border-width: 0 0 10px 14px;
        border-color: transparent transparent var(--lighten-color) transparent;
      }

      &:after {
        border-width: 10px 0 0 14px;
        border-color: var(--lighten-color) transparent transparent transparent;
      }

      &:hover {
        &:before {
          border-color: transparent transparent var(--highlight-color)
            transparent;
        }

        &:after {
          border-color: var(--highlight-color) transparent transparent
            transparent;
        }
      }
    }

    .drawer__deep {
      right: calc(-1 * var(--drawer-width));

      &.open {
        //left: 0;
        right: 0;
      }
    }
  }

  &--fixed {
    .q-drawer,
    .drawer__inner {
      transition: none !important;
    }
  }

  .menu-separator {
    margin: 15px 0 15px 0;
    opacity: 0.25;
  }

  .menu-input {
    text-align: center;
    background: none;
    border: none;
    color: white;
    width: 100%;
    outline: none;

    &--bold {
      font-weight: bold;
    }
  }
}

.inFullscreen {
  .q-drawer {
    top: 0 !important;
  }
}

.q-drawer {
  transition: width ease-out var(--discover-drawer-transition-speed);
}
</style>
