export enum ZoomMode {
  box = 'box',
  scroll = 'scroll',
}

export enum KMLDisplayMode {
  line = 'line',
  marker = 'marker',

  both = 'both',
}
