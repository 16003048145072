import { clamp } from 'lodash'

export const convertWeightToCounts = (
  maxCounts: number,
  weight: number,
  kev: number,
): number => {
  kev = kev / 1000
  return maxCounts * Math.pow(Math.E, (1 / 8) * Math.log(0.4) * kev) * weight
}

export const convertCountsToWeight = (
  maxCounts: number,
  position: number,
  kev: number,
): number => {
  kev = kev / 1000
  const weight =
    position / (maxCounts * Math.exp((1 / 8) * Math.log(0.4) * kev))
  return clamp(weight, 0, 1)
}
