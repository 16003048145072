import {
  ApolloClient,
  ApolloQueryResult,
  createHttpLink,
  DefaultOptions,
  DocumentNode,
  OperationVariables,
  split,
  SubscribeToMoreOptions,
} from '@apollo/client/core'
import { merge, cloneDeep } from 'lodash'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import Singleton from 'src/classes/Singleton'
import type { ApolloLink as ApolloLinkType } from '@apollo/client/link/core'
import { ApolloLink } from 'apollo-link'
import { ZenObservable } from 'zen-observable-ts'
import { onError } from '@apollo/client/link/error'
import { provideApolloClient, useQuery } from '@vue/apollo-composable'
import { showToast } from 'src/composables/showDialogs'
import { TypedDocumentNode } from '@apollo/client'
import { VariablesParameter } from '@vue/apollo-composable/dist/useQuery'
import { computed, ComputedRef, ref, Ref } from 'vue'
import { MultiContextMemoryCache } from 'src/api/MultiContextMemoryCache'

class ApolloManager extends Singleton {
  private _baseUrl: Ref<string | null> = ref(null)
  private _client: null | ApolloClient<any> = null
  private _defaultOptions: Partial<DefaultOptions> = {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  constructor() {
    super(ApolloManager)
  }

  setBaseUrl(url: string | null) {
    this._baseUrl.value = url
    this.stopClient()
  }

  getBaseUrl(): string | null {
    return this._baseUrl.value
  }

  getBaseUrlRef(): Ref<string | null> {
    return this._baseUrl
  }

  getClient(url: string | null = null): ApolloClient<any> {
    if (url !== null && this._baseUrl.value !== url) {
      return this.getSimpleClient(url)
    }
    if (!this._client) {
      this.startClient()
    }
    if (!this._client) {
      throw Error(`Unable to start client for [${url}]`)
    }
    return this._client
  }

  stopClient() {
    if (!this._client) {
      return
    }
    this._client.stop()
    this._client = null
  }

  provideDefaultClient(url = null) {
    provideApolloClient(this.getClient(url))
  }

  query<ResultType>(
    query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
    variables: VariablesParameter<OperationVariables> = {},
    onResult: (data: any) => ResultType,
    defaultValue: ResultType,
    pollInterval: number | null = null,
    fetchPolicy = 'cache-and-network',
  ): {
    result: ComputedRef<ResultType>
    loading: Ref<boolean>
    refetch: (
      variables?: any,
    ) => Promise<ApolloQueryResult<ResultType>> | undefined
    subscribeToMore: (options: SubscribeToMoreOptions) => void
  } {
    this.provideDefaultClient()
    const { result, loading, refetch, subscribeToMore } = useQuery(
      query,
      // @ts-ignore (type definition is incomplete)
      variables,
      { pollInterval, fetchPolicy },
    )

    return {
      result: computed<ResultType>(() => {
        if (result.value) {
          return onResult(result.value)
        }
        return defaultValue
      }),
      loading,
      refetch,
      subscribeToMore,
    }
  }

  async mutation<ResultType>(
    mutation: DocumentNode | TypedDocumentNode<any, OperationVariables>,
    variables?: object,
    throwErrors = false,
  ): Promise<ResultType | undefined> {
    try {
      const response = await this.getClient().mutate<ResultType>({
        mutation,
        variables,
      })
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      if (!response.data) {
        return
      }
      return response.data
    } catch (e: any) {
      if (throwErrors) {
        throw e
      }
      console.error('Mutation error:', e?.message)
      showToast('common.Error', '', 'negative')
    }
  }

  subscribeToMore<QueryType extends ReturnType<typeof this.query>>(
    valueRef: QueryType,
    document: DocumentNode | TypedDocumentNode<any, OperationVariables>,
    onResult: (data: any) => object,
  ): QueryType {
    valueRef.subscribeToMore({
      document,
      updateQuery: (
        previousValue: {},
        { subscriptionData }: { subscriptionData: { data: {} } },
      ) => {
        return merge(
          cloneDeep(previousValue),
          onResult(subscriptionData.data),
          {
            _context: {
              dontBroadcast: true,
            },
          },
        )
      },
    })
    return valueRef
  }

  subscription<ResultType>(
    query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
    onResult: (
      result: ResultType | null | undefined,
    ) => Promise<boolean> | void,
  ): ZenObservable.Subscription {
    const subscription = this.getClient()
      .subscribe<ResultType>({
        query,
      })
      .subscribe({
        next: async ({ data }) => {
          if (await onResult(data)) {
            subscription.unsubscribe()
          }
        },
      })
    return subscription
  }

  startClient() {
    if (!this._baseUrl.value) {
      throw Error('Host url is unknown. Can not start SEM Connection Client')
    }

    const wsLink = this._createWSLink(this._baseUrl.value)

    const connectedLink = this._createHttpLink(
      this._baseUrl.value,
    ) as ApolloLink
    const disconnectedLink = this._createDisconnectedLink() as ApolloLink
    const httpLink = ApolloLink.from([
      disconnectedLink,
      connectedLink,
    ]) as unknown as ApolloLinkType

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )

    this._client = new ApolloClient({
      link,
      cache: this._createCache(),
      defaultOptions: this._defaultOptions,
    })
  }

  getSimpleClient(url: string): ApolloClient<any> {
    const link = this._createHttpLink(url) as ApolloLinkType
    return new ApolloClient({
      link,
      cache: this._createCache(),
      defaultOptions: this._defaultOptions,
    })
  }

  getGraphqlUrl(url: string): string {
    if (!import.meta.env.VITE_GRAPHQL_PORT) {
      console.error(
        'VITE_GRAPHQL_PORT not set in env. Unable to connect graphQL api',
      )
    }
    return `http://${url}:${import.meta.env.VITE_GRAPHQL_PORT}/graphql`
  }

  _createHttpLink(url: string): ApolloLinkType | ApolloLink {
    return createHttpLink({
      uri: this.getGraphqlUrl(url),
    })
  }

  _createWSLink(url: string): WebSocketLink {
    if (!import.meta.env.VITE_GRAPHQL_PORT) {
      console.error(
        'VITE_GRAPHQL_PORT not set in env. Unable to connect graphQL api',
      )
    }
    return new WebSocketLink({
      uri: `ws://${url}:${import.meta.env.VITE_GRAPHQL_PORT}/graphql`,
      options: {
        reconnect: true,
      },
    })
  }

  _createCache(): MultiContextMemoryCache {
    return new MultiContextMemoryCache({
      addTypename: true,
      dataIdFromObject(responseObject) {
        return responseObject.__typename
      },
    })
  }

  _createDisconnectedLink(): ApolloLinkType | ApolloLink {
    return onError(({ operation, networkError }) => {
      if (!networkError) return
      const context = operation.getContext()
      if (context.dontDisconnect) return
      console.error('Disconnected because of network error', networkError)
    })
  }
}

export default new ApolloManager()
