import { ElementSymbol } from 'src/types/ElementSymbol'
import { PeriodicElementProperties } from 'src/types/PeriodicElement'

export default {
  Ru: {
    physicalProperties: {
      density: 12.37,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.33039,
          energy: 2.6833,
        },
        Kb: {
          weight: 0.15,
          energy: 21.6566,
        },
        Ka: {
          weight: 1.0,
          energy: 19.2793,
        },
        Lb2: {
          weight: 0.07259,
          energy: 2.8359,
        },
        La: {
          weight: 1.0,
          energy: 2.5585,
        },
        Ln: {
          weight: 0.0126,
          energy: 2.3819,
        },
        Ll: {
          weight: 0.0411,
          energy: 2.2529,
        },
        Lb3: {
          weight: 0.0654,
          energy: 2.7634,
        },
        Lg3: {
          weight: 0.0115,
          energy: 3.1809,
        },
        Lg1: {
          weight: 0.02176,
          energy: 2.9649,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 279.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 279.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 461.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 483.0,
        },
      },
    },
    generalProperties: {
      number: 44,
      atomicWeight: 101.07,
      name: 'ruthenium',
    },
  },
  Re: {
    physicalProperties: {
      density: 21.02,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4408,
          energy: 10.0098,
        },
        Kb: {
          weight: 0.15,
          energy: 69.3091,
        },
        Ka: {
          weight: 1.0,
          energy: 61.1411,
        },
        M2N4: {
          weight: 0.01,
          energy: 2.4079,
        },
        Ma: {
          weight: 1.0,
          energy: 1.8423,
        },
        Lb4: {
          weight: 0.09869,
          energy: 9.8451,
        },
        La: {
          weight: 1.0,
          energy: 8.6524,
        },
        Ln: {
          weight: 0.0151,
          energy: 9.027,
        },
        M3O4: {
          energy: 2.36124,
          weight: 0.001,
        },
        Ll: {
          weight: 0.05299,
          energy: 7.6036,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.9083,
        },
        Mg: {
          weight: 0.08505,
          energy: 2.1071,
        },
        Lb2: {
          weight: 0.21219,
          energy: 10.2751,
        },
        Lb3: {
          weight: 0.1222,
          energy: 10.1594,
        },
        M3O5: {
          energy: 2.36209,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.0331,
          energy: 12.0823,
        },
        Lg1: {
          weight: 0.08864,
          energy: 11.685,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.4385,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1883.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1949.0,
        },
      },
    },
    generalProperties: {
      number: 75,
      atomicWeight: 186.207,
      name: 'rhenium',
    },
  },
  Ra: {
    physicalProperties: {
      density: 5.0,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4,
          energy: 15.2359,
        },
        Kb: {
          weight: 0.15,
          energy: 100.1302,
        },
        Ka: {
          weight: 1.0,
          energy: 88.4776,
        },
        M2N4: {
          weight: 0.00674,
          energy: 3.8536,
        },
        Lb4: {
          weight: 0.06209,
          energy: 14.7472,
        },
        La: {
          weight: 1.0,
          energy: 12.3395,
        },
        Ln: {
          weight: 0.0133,
          energy: 13.6623,
        },
        Ll: {
          weight: 0.06429,
          energy: 10.6224,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.9495,
        },
        Mg: {
          weight: 0.33505,
          energy: 3.1891,
        },
        Lb2: {
          weight: 0.23579,
          energy: 14.8417,
        },
        Lg3: {
          weight: 0.017,
          energy: 18.3576,
        },
        Lg1: {
          weight: 0.08,
          energy: 17.8484,
        },
        Lb3: {
          weight: 0.06,
          energy: 15.4449,
        },
        Mz: {
          weight: 0.03512,
          energy: 2.2258,
        },
      },
    },
    generalProperties: {
      number: 88,
      atomicWeight: 226,
      name: 'radium',
    },
  },
  Rb: {
    physicalProperties: {
      density: 1.532,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.39095,
          energy: 1.7521,
        },
        Kb: {
          weight: 0.1558,
          energy: 14.9612,
        },
        Ka: {
          weight: 1.0,
          energy: 13.3953,
        },
        La: {
          weight: 1.0,
          energy: 1.6941,
        },
        Ln: {
          weight: 0.01709,
          energy: 1.5418,
        },
        Ll: {
          weight: 0.0441,
          energy: 1.4823,
        },
        Lb3: {
          weight: 0.04709,
          energy: 1.8266,
        },
        Lg3: {
          weight: 0.0058,
          energy: 2.0651,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 247.0,
        },
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 110.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 110.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1864.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1804.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 238.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 2065.0,
        },
      },
    },
    generalProperties: {
      number: 37,
      atomicWeight: 85.4678,
      name: 'rubidium',
    },
  },
  Rn: {
    physicalProperties: {
      density: 0.00973,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.38463,
          energy: 14.3156,
        },
        Kb: {
          weight: 0.15,
          energy: 94.866,
        },
        Ka: {
          weight: 1.0,
          energy: 83.7846,
        },
        M2N4: {
          weight: 0.00863,
          energy: 3.5924,
        },
        Lb4: {
          weight: 0.06,
          energy: 13.89,
        },
        La: {
          weight: 1.0,
          energy: 11.727,
        },
        Ln: {
          weight: 0.0134,
          energy: 12.8551,
        },
        Ll: {
          weight: 0.0625,
          energy: 10.1374,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.80187,
        },
        Mg: {
          weight: 0.21845,
          energy: 3.001,
        },
        Lb2: {
          weight: 0.2325,
          energy: 14.0824,
        },
        Lg3: {
          weight: 0.017,
          energy: 17.281,
        },
        Lg1: {
          weight: 0.08,
          energy: 16.7705,
        },
        Lb3: {
          weight: 0.0607,
          energy: 14.511,
        },
        Mz: {
          weight: 0.0058,
          energy: 2.1244,
        },
      },
    },
    generalProperties: {
      number: 86,
      atomicWeight: 222,
      name: 'radon',
    },
  },
  Rh: {
    physicalProperties: {
      density: 12.45,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.33463,
          energy: 2.8344,
        },
        Kb: {
          weight: 0.15,
          energy: 22.7237,
        },
        Ka: {
          weight: 1.0,
          energy: 20.2161,
        },
        Lb2: {
          weight: 0.08539,
          energy: 3.0013,
        },
        Lb4: {
          weight: 0.0395,
          energy: 2.8909,
        },
        La: {
          weight: 1.0,
          energy: 2.6968,
        },
        Ln: {
          weight: 0.0126,
          energy: 2.519,
        },
        Ll: {
          weight: 0.0411,
          energy: 2.3767,
        },
        Lb3: {
          weight: 0.06669,
          energy: 2.9157,
        },
        Lg3: {
          weight: 0.0121,
          energy: 3.364,
        },
        Lg1: {
          weight: 0.02623,
          energy: 3.1436,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 308.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 312.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 496.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 521.0,
        },
      },
    },
    generalProperties: {
      number: 45,
      atomicWeight: 102.9055,
      name: 'rhodium',
    },
  },
  H: {
    physicalProperties: {
      density: 8.99e-5,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.0013598,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 13.598,
        },
      },
    },
    generalProperties: {
      number: 1,
      atomicWeight: 1.00794,
      name: 'hydrogen',
    },
  },
  He: {
    physicalProperties: {
      density: 1.785e-4,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.0024587,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 24.587,
        },
      },
    },
    generalProperties: {
      number: 2,
      atomicWeight: 4.002602,
      name: 'helium',
    },
  },
  Be: {
    physicalProperties: {
      density: 1.848,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.10258,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 111.0,
        },
      },
    },
    generalProperties: {
      number: 4,
      atomicWeight: 9.012182,
      name: 'beryllium',
    },
  },
  Ba: {
    physicalProperties: {
      density: 3.51,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43048,
          energy: 4.8275,
        },
        Kb: {
          weight: 0.15,
          energy: 36.3784,
        },
        Ka: {
          weight: 1.0,
          energy: 32.1936,
        },
        Lb2: {
          weight: 0.1905,
          energy: 5.1571,
        },
        Lb4: {
          weight: 0.08859,
          energy: 4.8521,
        },
        La: {
          weight: 1.0,
          energy: 4.4663,
        },
        Ln: {
          weight: 0.0151,
          energy: 4.3308,
        },
        Ll: {
          weight: 0.04299,
          energy: 3.9542,
        },
        Lb3: {
          weight: 0.13779,
          energy: 4.9266,
        },
        Lg3: {
          weight: 0.0331,
          energy: 5.8091,
        },
        Lg1: {
          weight: 0.07487,
          energy: 5.5311,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 781.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 796.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1062.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1137.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 90.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 90.0,
        },
      },
    },
    generalProperties: {
      number: 56,
      atomicWeight: 137.327,
      name: 'barium',
    },
  },
  Bi: {
    physicalProperties: {
      density: 9.78,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4,
          energy: 13.0235,
        },
        Kb: {
          weight: 0.15,
          energy: 87.349,
        },
        Ka: {
          weight: 1.0,
          energy: 77.1073,
        },
        M2N4: {
          weight: 0.00863,
          energy: 3.2327,
        },
        Ma: {
          weight: 1.0,
          energy: 2.4222,
        },
        Lb4: {
          weight: 0.05639,
          energy: 12.6912,
        },
        La: {
          weight: 1.0,
          energy: 10.839,
        },
        Ln: {
          weight: 0.0134,
          energy: 11.712,
        },
        M3O4: {
          energy: 3.1504,
          weight: 0.01,
        },
        Ll: {
          weight: 0.06,
          energy: 9.4195,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.5257,
        },
        Mg: {
          weight: 0.21845,
          energy: 2.7369,
        },
        Lb2: {
          weight: 0.2278,
          energy: 12.9786,
        },
        Lb3: {
          weight: 0.0607,
          energy: 13.2106,
        },
        M3O5: {
          energy: 3.1525,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.017,
          energy: 15.7086,
        },
        Lg1: {
          weight: 0.08,
          energy: 15.2475,
        },
        Mz: {
          weight: 0.0058,
          energy: 1.9007,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2580.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2688.0,
        },
      },
    },
    generalProperties: {
      number: 83,
      atomicWeight: 208.9804,
      name: 'bismuth',
    },
  },
  Br: {
    physicalProperties: {
      density: 3.12,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.39175,
          energy: 1.5259,
        },
        Kb: {
          weight: 0.15289,
          energy: 13.2922,
        },
        Ka: {
          weight: 1.0,
          energy: 11.9238,
        },
        La: {
          weight: 1.0,
          energy: 1.4809,
        },
        Ln: {
          weight: 0.0182,
          energy: 1.3395,
        },
        Ll: {
          weight: 0.0462,
          energy: 1.2934,
        },
        Lb3: {
          weight: 0.04629,
          energy: 1.6005,
        },
      },
      bindingEnergies: {
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1596.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1550.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1782.0,
        },
      },
    },
    generalProperties: {
      number: 35,
      atomicWeight: 79.904,
      name: 'bromine',
    },
  },
  P: {
    physicalProperties: {
      density: 1.823,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.0498,
          energy: 2.13916,
        },
        Ka: {
          weight: 1.0,
          energy: 2.0133,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 2146.0,
        },
        L2_3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 132.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 189.0,
        },
      },
    },
    generalProperties: {
      number: 15,
      atomicWeight: 30.973762,
      name: 'phosphorus',
    },
  },
  Os: {
    physicalProperties: {
      density: 22.59,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43207,
          energy: 10.3542,
        },
        Kb: {
          weight: 0.15,
          energy: 71.4136,
        },
        Ka: {
          weight: 1.0,
          energy: 62.9999,
        },
        M2N4: {
          weight: 0.02901,
          energy: 2.5028,
        },
        Ma: {
          weight: 1.0,
          energy: 1.9138,
        },
        Lb4: {
          weight: 0.08369,
          energy: 10.1758,
        },
        La: {
          weight: 1.0,
          energy: 8.9108,
        },
        Ln: {
          weight: 0.01479,
          energy: 9.3365,
        },
        M3O4: {
          energy: 2.45015,
          weight: 0.005,
        },
        Ll: {
          weight: 0.05389,
          energy: 7.8224,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.9845,
        },
        Mg: {
          weight: 0.08505,
          energy: 2.1844,
        },
        Lb2: {
          weight: 0.2146,
          energy: 10.5981,
        },
        Lb3: {
          weight: 0.1024,
          energy: 10.5108,
        },
        M3O5: {
          energy: 2.45117,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.028,
          energy: 12.4998,
        },
        Lg1: {
          weight: 0.08768,
          energy: 12.0956,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.4919,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1960.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2031.0,
        },
      },
    },
    generalProperties: {
      number: 76,
      atomicWeight: 190.23,
      name: 'osmium',
    },
  },
  Ge: {
    physicalProperties: {
      density: 5.323,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.16704,
          energy: 1.2191,
        },
        Kb: {
          weight: 0.1322,
          energy: 10.9823,
        },
        Ka: {
          weight: 1.0,
          energy: 9.8864,
        },
        La: {
          weight: 1.0,
          energy: 1.188,
        },
        Ln: {
          weight: 0.02,
          energy: 1.0678,
        },
        Ll: {
          weight: 0.0511,
          energy: 1.0367,
        },
        Lb3: {
          weight: 0.04429,
          energy: 1.2935,
        },
      },
      bindingEnergies: {
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1248.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1217.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1414.0,
        },
      },
    },
    generalProperties: {
      number: 32,
      atomicWeight: 72.64,
      name: 'germanium',
    },
  },
  Gd: {
    physicalProperties: {
      density: 7.901,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.44127,
          energy: 6.7131,
        },
        Kb: {
          weight: 0.15,
          energy: 48.6951,
        },
        Ka: {
          weight: 1.0,
          energy: 42.9963,
        },
        M2N4: {
          weight: 0.014,
          energy: 1.5478,
        },
        Ma: {
          weight: 1.0,
          energy: 1.17668,
        },
        Lb4: {
          weight: 0.08789,
          energy: 6.6873,
        },
        La: {
          weight: 1.0,
          energy: 6.0576,
        },
        Ln: {
          weight: 0.01489,
          energy: 6.0495,
        },
        Ll: {
          weight: 0.04629,
          energy: 5.362,
        },
        Mb: {
          weight: 0.88,
          energy: 1.20792,
        },
        Mg: {
          weight: 0.261,
          energy: 1.4035,
        },
        Lb2: {
          weight: 0.2014,
          energy: 7.1023,
        },
        Lb3: {
          weight: 0.1255,
          energy: 6.8316,
        },
        Lg3: {
          weight: 0.032,
          energy: 8.1047,
        },
        Lg1: {
          weight: 0.08207,
          energy: 7.7898,
        },
        Mz: {
          weight: 0.06,
          energy: 0.9143,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1185.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1217.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1544.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1688.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 141.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 141.0,
        },
      },
    },
    generalProperties: {
      number: 64,
      atomicWeight: 157.25,
      name: 'gadolinium',
    },
  },
  Ga: {
    physicalProperties: {
      density: 5.904,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.16704,
          energy: 1.1249,
        },
        Kb: {
          weight: 0.1287,
          energy: 10.2642,
        },
        Ka: {
          weight: 1.0,
          energy: 9.2517,
        },
        La: {
          weight: 1.0,
          energy: 1.098,
        },
        Ln: {
          weight: 0.02509,
          energy: 0.9842,
        },
        Ll: {
          weight: 0.0544,
          energy: 0.9573,
        },
        Lb3: {
          weight: 0.0461,
          energy: 1.1948,
        },
      },
      bindingEnergies: {
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1142.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1115.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1298.0,
        },
      },
    },
    generalProperties: {
      number: 31,
      atomicWeight: 69.723,
      name: 'gallium',
    },
  },
  Pr: {
    physicalProperties: {
      density: 6.64,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.42872,
          energy: 5.4893,
        },
        Kb: {
          weight: 0.15,
          energy: 40.7484,
        },
        Ka: {
          weight: 1.0,
          energy: 36.0263,
        },
        M2N4: {
          weight: 0.055,
          energy: 1.2242,
        },
        Ma: {
          weight: 1.0,
          energy: 0.8936,
        },
        Lb4: {
          weight: 0.0864,
          energy: 5.4974,
        },
        La: {
          weight: 1.0,
          energy: 5.0333,
        },
        Ln: {
          weight: 0.01489,
          energy: 4.9294,
        },
        Ll: {
          weight: 0.044,
          energy: 4.4533,
        },
        Mb: {
          weight: 0.85,
          energy: 0.9476,
        },
        Mg: {
          weight: 0.6,
          energy: 1.129,
        },
        Lb2: {
          weight: 0.19519,
          energy: 5.8511,
        },
        Lb3: {
          weight: 0.13089,
          energy: 5.5926,
        },
        Lg3: {
          weight: 0.0321,
          energy: 6.6172,
        },
        Lg1: {
          weight: 0.07687,
          energy: 6.3272,
        },
        Mz: {
          weight: 0.068,
          energy: 0.7134,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 931.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 951.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1242.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1337.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 114.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 114.0,
        },
      },
    },
    generalProperties: {
      number: 59,
      atomicWeight: 140.90765,
      name: 'praseodymium',
    },
  },
  Pt: {
    physicalProperties: {
      density: 21.09,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4088,
          energy: 11.0707,
        },
        Kb: {
          weight: 0.15,
          energy: 75.7494,
        },
        Ka: {
          weight: 1.0,
          energy: 66.8311,
        },
        M2N4: {
          weight: 0.02901,
          energy: 2.6957,
        },
        Ma: {
          weight: 1.0,
          energy: 2.0505,
        },
        Lb4: {
          weight: 0.0662,
          energy: 10.8534,
        },
        La: {
          weight: 1.0,
          energy: 9.4421,
        },
        Ln: {
          weight: 0.01399,
          energy: 9.9766,
        },
        M3O4: {
          energy: 2.63796,
          weight: 0.005,
        },
        Ll: {
          weight: 0.0554,
          energy: 8.2677,
        },
        Mb: {
          weight: 0.59443,
          energy: 2.1276,
        },
        Mg: {
          weight: 0.08505,
          energy: 2.3321,
        },
        Lb2: {
          weight: 0.21829,
          energy: 11.2504,
        },
        Lb3: {
          weight: 0.0783,
          energy: 11.2345,
        },
        M3O5: {
          energy: 2.63927,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.0218,
          energy: 13.3609,
        },
        Lg1: {
          weight: 0.08448,
          energy: 12.9418,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.6026,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2122.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2202.0,
        },
      },
    },
    generalProperties: {
      number: 78,
      atomicWeight: 195.084,
      name: 'platinum',
    },
  },
  C: {
    physicalProperties: {
      density: 2.26,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.2774,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 284.0,
        },
        K1a: {
          relevance: 'Major',
          threshold: '',
          edge: '',
          onsetEnergy: 284.0,
        },
      },
    },
    generalProperties: {
      number: 6,
      atomicWeight: 12.0107,
      name: 'carbon',
    },
  },
  Pb: {
    physicalProperties: {
      density: 11.34,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.3836,
          energy: 12.6144,
        },
        Kb: {
          weight: 0.15,
          energy: 84.9381,
        },
        Ka: {
          weight: 1.0,
          energy: 74.9693,
        },
        M2N4: {
          weight: 0.00863,
          energy: 3.119,
        },
        Ma: {
          weight: 1.0,
          energy: 2.3459,
        },
        Lb4: {
          weight: 0.055,
          energy: 12.3066,
        },
        La: {
          weight: 1.0,
          energy: 10.5512,
        },
        Ln: {
          weight: 0.0132,
          energy: 11.3493,
        },
        M3O4: {
          energy: 3.0446,
          weight: 0.01,
        },
        Ll: {
          weight: 0.0586,
          energy: 9.1845,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.4427,
        },
        Mg: {
          weight: 0.21845,
          energy: 2.6535,
        },
        Lb2: {
          weight: 0.2244,
          energy: 12.6223,
        },
        Lb3: {
          weight: 0.06049,
          energy: 12.7944,
        },
        M3O5: {
          energy: 3.0472,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.017,
          energy: 15.2163,
        },
        Lg1: {
          weight: 0.08256,
          energy: 14.7648,
        },
        Mz: {
          weight: 0.0058,
          energy: 1.8395,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2484.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2586.0,
        },
      },
    },
    generalProperties: {
      number: 82,
      atomicWeight: 207.2,
      name: 'lead',
    },
  },
  Pa: {
    physicalProperties: {
      density: 15.37,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4,
          energy: 16.7025,
        },
        Kb: {
          weight: 0.15,
          energy: 108.4272,
        },
        Ka: {
          weight: 1.0,
          energy: 95.8679,
        },
        M2N4: {
          weight: 0.00674,
          energy: 4.2575,
        },
        Ma: {
          weight: 1.0,
          energy: 3.0823,
        },
        Lb4: {
          weight: 0.04,
          energy: 16.1037,
        },
        La: {
          weight: 1.0,
          energy: 13.2913,
        },
        Ln: {
          weight: 0.0126,
          energy: 14.9468,
        },
        M3O4: {
          energy: 4.07712,
          weight: 0.01,
        },
        Ll: {
          weight: 0.0682,
          energy: 11.3662,
        },
        Mb: {
          weight: 0.64124,
          energy: 3.24,
        },
        Mg: {
          weight: 0.33505,
          energy: 3.4656,
        },
        Lb2: {
          weight: 0.236,
          energy: 16.0249,
        },
        Lb3: {
          weight: 0.06,
          energy: 16.9308,
        },
        M3O5: {
          energy: 4.08456,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.017,
          energy: 20.0979,
        },
        Lg1: {
          weight: 0.08,
          energy: 19.5703,
        },
        Mz: {
          weight: 0.03512,
          energy: 2.4351,
        },
      },
    },
    generalProperties: {
      number: 91,
      atomicWeight: 231.03586,
      name: 'protactinium',
    },
  },
  Pd: {
    physicalProperties: {
      density: 12.023,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.34375,
          energy: 2.9903,
        },
        Kb: {
          weight: 0.15,
          energy: 23.8188,
        },
        Ka: {
          weight: 1.0,
          energy: 21.177,
        },
        Lb2: {
          weight: 0.10349,
          energy: 3.16828,
        },
        Lb4: {
          weight: 0.0407,
          energy: 3.0452,
        },
        La: {
          weight: 1.0,
          energy: 2.8386,
        },
        Ln: {
          weight: 0.0129,
          energy: 2.6604,
        },
        Ll: {
          weight: 0.0412,
          energy: 2.5034,
        },
        Lb3: {
          weight: 0.0682,
          energy: 3.0728,
        },
        Lg3: {
          weight: 0.0125,
          energy: 3.5532,
        },
        Lg1: {
          weight: 0.03256,
          energy: 3.32485,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 335.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 340.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 531.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 559.0,
        },
      },
    },
    generalProperties: {
      number: 46,
      atomicWeight: 106.42,
      name: 'palladium',
    },
  },
  Cd: {
    physicalProperties: {
      density: 8.65,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.35704,
          energy: 3.3165,
        },
        Kb: {
          weight: 0.15,
          energy: 26.0947,
        },
        Ka: {
          weight: 1.0,
          energy: 23.1737,
        },
        Lb2: {
          weight: 0.1288,
          energy: 3.5282,
        },
        Lb4: {
          weight: 0.0469,
          energy: 3.3673,
        },
        La: {
          weight: 1.0,
          energy: 3.1338,
        },
        Ln: {
          weight: 0.0132,
          energy: 2.9568,
        },
        Ll: {
          weight: 0.04169,
          energy: 2.7673,
        },
        Lb3: {
          weight: 0.07719,
          energy: 3.4015,
        },
        Lg3: {
          weight: 0.0151,
          energy: 3.9511,
        },
        Lg1: {
          weight: 0.0416,
          energy: 3.7177,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 404.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 411.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 616.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 651.0,
        },
      },
    },
    generalProperties: {
      number: 48,
      atomicWeight: 112.411,
      name: 'cadmium',
    },
  },
  Po: {
    physicalProperties: {
      density: 9.196,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.38536,
          energy: 13.4463,
        },
        Kb: {
          weight: 0.15,
          energy: 89.8031,
        },
        Ka: {
          weight: 1.0,
          energy: 79.2912,
        },
        M2N4: {
          weight: 0.00863,
          energy: 3.3539,
        },
        Lb4: {
          weight: 0.05709,
          energy: 13.0852,
        },
        La: {
          weight: 1.0,
          energy: 11.1308,
        },
        Ln: {
          weight: 0.0133,
          energy: 12.0949,
        },
        Ll: {
          weight: 0.0607,
          energy: 9.6644,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.62266,
        },
        Mg: {
          weight: 0.21845,
          energy: 2.8285,
        },
        Lb2: {
          weight: 0.2289,
          energy: 13.3404,
        },
        Lg3: {
          weight: 0.017,
          energy: 16.2343,
        },
        Lg1: {
          weight: 0.08,
          energy: 15.7441,
        },
        Lb3: {
          weight: 0.0603,
          energy: 13.6374,
        },
        Mz: {
          weight: 0.00354,
          energy: 1.978,
        },
      },
    },
    generalProperties: {
      number: 84,
      atomicWeight: 209,
      name: 'polonium',
    },
  },
  Pm: {
    physicalProperties: {
      density: 7.264,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4308,
          energy: 5.9613,
        },
        Kb: {
          weight: 0.15,
          energy: 43.8271,
        },
        Ka: {
          weight: 1.0,
          energy: 38.7247,
        },
        M2N4: {
          weight: 0.028,
          energy: 1.351,
        },
        Ma: {
          weight: 1.0,
          energy: 0.9894,
        },
        Lb4: {
          weight: 0.07799,
          energy: 5.9565,
        },
        La: {
          weight: 1.0,
          energy: 5.4324,
        },
        Ln: {
          weight: 0.01479,
          energy: 5.3663,
        },
        Ll: {
          weight: 0.0448,
          energy: 4.8128,
        },
        Mb: {
          weight: 0.89,
          energy: 1.0475,
        },
        Mg: {
          weight: 0.4,
          energy: 1.2365,
        },
        Lb2: {
          weight: 0.196,
          energy: 6.3389,
        },
        Lb3: {
          weight: 0.1247,
          energy: 6.071,
        },
        Lg3: {
          weight: 0.0311,
          energy: 7.1919,
        },
        Lg1: {
          weight: 0.0784,
          energy: 6.8924,
        },
        Mz: {
          weight: 0.068,
          energy: 0.7909,
        },
      },
    },
    generalProperties: {
      number: 61,
      atomicWeight: 145,
      name: 'promethium',
    },
  },
  Ho: {
    physicalProperties: {
      density: 8.795,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.45056,
          energy: 7.5263,
        },
        Kb: {
          weight: 0.15,
          energy: 53.8765,
        },
        Ka: {
          weight: 1.0,
          energy: 47.5466,
        },
        M2N4: {
          weight: 0.072,
          energy: 1.7618,
        },
        Ma: {
          weight: 1.0,
          energy: 1.3477,
        },
        Lb4: {
          weight: 0.09039,
          energy: 7.4714,
        },
        La: {
          weight: 1.0,
          energy: 6.7197,
        },
        Ln: {
          weight: 0.0151,
          energy: 6.7895,
        },
        Ll: {
          weight: 0.04759,
          energy: 5.9428,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.3878,
        },
        Mg: {
          weight: 0.1418,
          energy: 1.5802,
        },
        Lb2: {
          weight: 0.23563,
          energy: 7.9101,
        },
        Lb3: {
          weight: 0.06,
          energy: 7.653,
        },
        Lg3: {
          weight: 0.0321,
          energy: 9.0876,
        },
        Lg1: {
          weight: 0.08448,
          energy: 8.7568,
        },
        Mz: {
          weight: 0.6629,
          energy: 1.0448,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1351.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1391.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1741.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1923.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 161.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 161.0,
        },
      },
    },
    generalProperties: {
      number: 67,
      atomicWeight: 164.93032,
      name: 'holmium',
    },
  },
  Hf: {
    physicalProperties: {
      density: 13.31,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.46231,
          energy: 9.023,
        },
        Kb: {
          weight: 0.15,
          energy: 63.2432,
        },
        Ka: {
          weight: 1.0,
          energy: 55.7901,
        },
        M2N4: {
          weight: 0.01,
          energy: 2.1416,
        },
        Ma: {
          weight: 1.0,
          energy: 1.6446,
        },
        Lb4: {
          weight: 0.10189,
          energy: 8.9053,
        },
        La: {
          weight: 1.0,
          energy: 7.899,
        },
        Ln: {
          weight: 0.0158,
          energy: 8.1385,
        },
        Ll: {
          weight: 0.05089,
          energy: 6.9598,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.6993,
        },
        Mg: {
          weight: 0.08505,
          energy: 1.8939,
        },
        Lb2: {
          weight: 0.2048,
          energy: 9.347,
        },
        Lb3: {
          weight: 0.1316,
          energy: 9.1631,
        },
        Lg3: {
          weight: 0.0347,
          energy: 10.8903,
        },
        Lg1: {
          weight: 0.08968,
          energy: 10.5156,
        },
        Mz: {
          weight: 0.06,
          energy: 1.2813,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1662.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1716.0,
        },
      },
    },
    generalProperties: {
      number: 72,
      atomicWeight: 178.49,
      name: 'hafnium',
    },
  },
  Hg: {
    physicalProperties: {
      density: 13.534,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.39504,
          energy: 11.8238,
        },
        Kb: {
          weight: 0.15,
          energy: 80.2552,
        },
        Ka: {
          weight: 1.0,
          energy: 70.8184,
        },
        M2N4: {
          weight: 0.02901,
          energy: 2.9002,
        },
        Ma: {
          weight: 1.0,
          energy: 2.1964,
        },
        Lb4: {
          weight: 0.0566,
          energy: 11.5608,
        },
        La: {
          weight: 1.0,
          energy: 9.989,
        },
        Ln: {
          weight: 0.0136,
          energy: 10.6471,
        },
        M3O4: {
          energy: 2.8407,
          weight: 0.005,
        },
        Ll: {
          weight: 0.05709,
          energy: 8.7223,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.2827,
        },
        Mg: {
          weight: 0.08505,
          energy: 2.4873,
        },
        Lb2: {
          weight: 0.2221,
          energy: 11.9241,
        },
        Lb3: {
          weight: 0.06469,
          energy: 11.9922,
        },
        M3O5: {
          energy: 2.8407,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.0184,
          energy: 14.2683,
        },
        Lg1: {
          weight: 0.0832,
          energy: 13.8304,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.7239,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2295.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2385.0,
        },
      },
    },
    generalProperties: {
      number: 80,
      atomicWeight: 200.59,
      name: 'mercury',
    },
  },
  Mg: {
    physicalProperties: {
      density: 1.738,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.01,
          energy: 1.305,
        },
        Ka: {
          weight: 1.0,
          energy: 1.2536,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1305.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 51.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 51.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 89.0,
        },
      },
    },
    generalProperties: {
      number: 12,
      atomicWeight: 24.305,
      name: 'magnesium',
    },
  },
  K: {
    physicalProperties: {
      density: 0.856,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.1039,
          energy: 3.5896,
        },
        Ka: {
          weight: 1.0,
          energy: 3.3138,
        },
      },
      bindingEnergies: {
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 294.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 296.0,
        },
        L1a: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 377.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 377.0,
        },
      },
    },
    generalProperties: {
      number: 19,
      atomicWeight: 39.0983,
      name: 'potassium',
    },
  },
  Mn: {
    physicalProperties: {
      density: 7.47,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.1252,
          energy: 6.4904,
        },
        Ka: {
          weight: 1.0,
          energy: 5.8987,
        },
        La: {
          weight: 1.0,
          energy: 0.63316,
        },
        Ln: {
          weight: 0.1898,
          energy: 0.5675,
        },
        Ll: {
          weight: 0.3898,
          energy: 0.5564,
        },
        Lb3: {
          weight: 0.0263,
          energy: 0.7204,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 51.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 651.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 640.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 51.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 769.0,
        },
      },
    },
    generalProperties: {
      number: 25,
      atomicWeight: 54.938045,
      name: 'manganese',
    },
  },
  O: {
    physicalProperties: {
      density: 0.001429,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.5249,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 532.0,
        },
      },
    },
    generalProperties: {
      number: 8,
      atomicWeight: 15.9994,
      name: 'oxygen',
    },
  },
  S: {
    physicalProperties: {
      density: 1.96,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.06525,
          energy: 2.46427,
        },
        Ka: {
          weight: 1.0,
          energy: 2.3072,
        },
      },
      bindingEnergies: {
        L2_3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 165.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 229.0,
        },
      },
    },
    generalProperties: {
      number: 16,
      atomicWeight: 32.065,
      name: 'sulfur',
    },
  },
  W: {
    physicalProperties: {
      density: 19.25,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.3679,
          energy: 9.6724,
        },
        Kb: {
          weight: 0.15,
          energy: 67.244,
        },
        Ka: {
          weight: 1.0,
          energy: 59.3182,
        },
        M2N4: {
          weight: 0.01,
          energy: 2.3161,
        },
        Ma: {
          weight: 1.0,
          energy: 1.7756,
        },
        Lb4: {
          weight: 0.05649,
          energy: 9.5249,
        },
        La: {
          weight: 1.0,
          energy: 8.3976,
        },
        Ln: {
          weight: 0.01155,
          energy: 8.7244,
        },
        M3O4: {
          energy: 2.2749,
          weight: 0.001,
        },
        Ll: {
          weight: 0.04169,
          energy: 7.3872,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.8351,
        },
        Mg: {
          weight: 0.08505,
          energy: 2.0356,
        },
        Lb2: {
          weight: 0.21385,
          energy: 9.9614,
        },
        Lb3: {
          weight: 0.07077,
          energy: 9.8188,
        },
        M3O5: {
          energy: 2.281,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.0362,
          energy: 11.6745,
        },
        Lg1: {
          weight: 0.05658,
          energy: 11.2852,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.3839,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1809.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1872.0,
        },
      },
    },
    generalProperties: {
      number: 74,
      atomicWeight: 183.84,
      name: 'tungsten',
    },
  },
  Zn: {
    physicalProperties: {
      density: 7.14,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.1679,
          energy: 1.0347,
        },
        Kb: {
          weight: 0.12605,
          energy: 9.572,
        },
        Ka: {
          weight: 1.0,
          energy: 8.6389,
        },
        La: {
          weight: 1.0,
          energy: 1.0116,
        },
        Ln: {
          weight: 0.0368,
          energy: 0.9069,
        },
        Ll: {
          weight: 0.0603,
          energy: 0.8838,
        },
        Lb3: {
          weight: 0.002,
          energy: 1.107,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 87.0,
        },
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1043.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1020.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 87.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1194.0,
        },
      },
    },
    generalProperties: {
      number: 30,
      atomicWeight: 65.38,
      name: 'zinc',
    },
  },
  Eu: {
    physicalProperties: {
      density: 5.244,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43904,
          energy: 6.4565,
        },
        Kb: {
          weight: 0.15,
          energy: 47.0384,
        },
        Ka: {
          weight: 1.0,
          energy: 41.5421,
        },
        M2N4: {
          weight: 0.013,
          energy: 1.4807,
        },
        Ma: {
          weight: 1.0,
          energy: 1.0991,
        },
        Lb4: {
          weight: 0.0874,
          energy: 6.4381,
        },
        La: {
          weight: 1.0,
          energy: 5.846,
        },
        Ln: {
          weight: 0.015,
          energy: 5.8171,
        },
        Ll: {
          weight: 0.04559,
          energy: 5.1769,
        },
        Mb: {
          weight: 0.87,
          energy: 1.15769,
        },
        Mg: {
          weight: 0.26,
          energy: 1.3474,
        },
        Lb2: {
          weight: 0.1985,
          energy: 6.8437,
        },
        Lb3: {
          weight: 0.1265,
          energy: 6.5714,
        },
        Lg3: {
          weight: 0.0318,
          energy: 7.7954,
        },
        Lg1: {
          weight: 0.08064,
          energy: 7.4839,
        },
        Mz: {
          weight: 0.06,
          energy: 0.8743,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1131.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1161.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1481.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1614.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 134.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 134.0,
        },
      },
    },
    generalProperties: {
      number: 63,
      atomicWeight: 151.964,
      name: 'europium',
    },
  },
  Zr: {
    physicalProperties: {
      density: 6.511,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.37912,
          energy: 2.1243,
        },
        Kb: {
          weight: 0.15,
          energy: 17.6671,
        },
        Ka: {
          weight: 1.0,
          energy: 15.7753,
        },
        Lb2: {
          weight: 0.0177,
          energy: 2.2223,
        },
        La: {
          weight: 1.0,
          energy: 2.0423,
        },
        Ln: {
          weight: 0.0153,
          energy: 1.8764,
        },
        Ll: {
          weight: 0.04209,
          energy: 1.792,
        },
        Lb3: {
          weight: 0.05219,
          energy: 2.2011,
        },
        Lg3: {
          weight: 0.0082,
          energy: 2.5029,
        },
        Lg1: {
          weight: 0.006,
          energy: 2.30268,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 344.0,
        },
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 181.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 181.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2307.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2222.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 330.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 2532.0,
        },
      },
    },
    generalProperties: {
      number: 40,
      atomicWeight: 91.224,
      name: 'zirconium',
    },
  },
  Er: {
    physicalProperties: {
      density: 9.066,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.45263,
          energy: 7.811,
        },
        Kb: {
          weight: 0.15,
          energy: 55.6737,
        },
        Ka: {
          weight: 1.0,
          energy: 49.1276,
        },
        M2N4: {
          weight: 0.0045,
          energy: 1.8291,
        },
        Ma: {
          weight: 1.0,
          energy: 1.405,
        },
        Lb4: {
          weight: 0.0922,
          energy: 7.7455,
        },
        La: {
          weight: 1.0,
          energy: 6.9486,
        },
        Ln: {
          weight: 0.0153,
          energy: 7.0578,
        },
        Ll: {
          weight: 0.0482,
          energy: 6.1514,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.449,
        },
        Mg: {
          weight: 0.08505,
          energy: 1.6442,
        },
        Lb2: {
          weight: 0.2005,
          energy: 8.1903,
        },
        Lb3: {
          weight: 0.1258,
          energy: 7.9395,
        },
        Lg3: {
          weight: 0.0324,
          energy: 9.4313,
        },
        Lg1: {
          weight: 0.08487,
          energy: 9.0876,
        },
        Mz: {
          weight: 0.06,
          energy: 1.0893,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1409.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1453.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1812.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 2006.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 168.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 168.0,
        },
      },
    },
    generalProperties: {
      number: 68,
      atomicWeight: 167.259,
      name: 'erbium',
    },
  },
  Ni: {
    physicalProperties: {
      density: 8.908,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.1677,
          energy: 0.8683,
        },
        Kb: {
          weight: 0.1277,
          energy: 8.2647,
        },
        Ka: {
          weight: 1.0,
          energy: 7.4781,
        },
        La: {
          weight: 1.0,
          energy: 0.8511,
        },
        Ln: {
          weight: 0.09693,
          energy: 0.7601,
        },
        Ll: {
          weight: 0.14133,
          energy: 0.7429,
        },
        Lb3: {
          weight: 0.00199,
          energy: 0.94,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 68.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 872.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 855.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 68.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1008.0,
        },
      },
    },
    generalProperties: {
      number: 28,
      atomicWeight: 58.6934,
      name: 'nickel',
    },
  },
  Na: {
    physicalProperties: {
      density: 0.968,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.01,
          energy: 1.0721,
        },
        Ka: {
          weight: 1.0,
          energy: 1.041,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1072.0,
        },
        L2_3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 31.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 63.0,
        },
      },
    },
    generalProperties: {
      number: 11,
      atomicWeight: 22.98976928,
      name: 'sodium',
    },
  },
  Nb: {
    physicalProperties: {
      density: 8.57,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.32519,
          energy: 2.2573,
        },
        Kb: {
          weight: 0.15,
          energy: 18.6226,
        },
        Ka: {
          weight: 1.0,
          energy: 16.6151,
        },
        Lb2: {
          weight: 0.03299,
          energy: 2.3705,
        },
        La: {
          weight: 1.0,
          energy: 2.1659,
        },
        Ln: {
          weight: 0.0129,
          energy: 1.9963,
        },
        Ll: {
          weight: 0.04169,
          energy: 1.9021,
        },
        Lb3: {
          weight: 0.06429,
          energy: 2.3347,
        },
        Lg3: {
          weight: 0.0103,
          energy: 2.6638,
        },
        Lg1: {
          weight: 0.00975,
          energy: 2.4615,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 378.0,
        },
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 202.3,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 205.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2465.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2371.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 363.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 2698.0,
        },
      },
    },
    generalProperties: {
      number: 41,
      atomicWeight: 92.90638,
      name: 'niobium',
    },
  },
  Nd: {
    physicalProperties: {
      density: 7.01,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.42672,
          energy: 5.722,
        },
        Kb: {
          weight: 0.15,
          energy: 42.2715,
        },
        Ka: {
          weight: 1.0,
          energy: 37.361,
        },
        M2N4: {
          weight: 0.052,
          energy: 1.2853,
        },
        Ma: {
          weight: 1.0,
          energy: 0.9402,
        },
        Lb4: {
          weight: 0.0858,
          energy: 5.7232,
        },
        La: {
          weight: 1.0,
          energy: 5.2302,
        },
        Ln: {
          weight: 0.01469,
          energy: 5.1462,
        },
        Ll: {
          weight: 0.04429,
          energy: 4.6326,
        },
        Mb: {
          weight: 0.99,
          energy: 0.9965,
        },
        Mg: {
          weight: 0.625,
          energy: 1.1799,
        },
        Lb2: {
          weight: 0.1957,
          energy: 6.0904,
        },
        Lb3: {
          weight: 0.12869,
          energy: 5.8286,
        },
        Lg3: {
          weight: 0.0318,
          energy: 6.9014,
        },
        Lg1: {
          weight: 0.07712,
          energy: 6.604,
        },
        Mz: {
          weight: 0.069,
          energy: 0.7531,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 978.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1000.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1297.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1403.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 118.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 118.0,
        },
      },
    },
    generalProperties: {
      number: 60,
      atomicWeight: 144.242,
      name: 'neodymium',
    },
  },
  Ne: {
    physicalProperties: {
      density: 0.0009,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.01,
          energy: 0.8669,
        },
        Ka: {
          weight: 1.0,
          energy: 0.8486,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 867.0,
        },
      },
    },
    generalProperties: {
      number: 10,
      atomicWeight: 20.1791,
      name: 'neon',
    },
  },
  Fr: {
    physicalProperties: {
      density: 'NaN',
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.38327,
          energy: 14.7703,
        },
        Kb: {
          weight: 0.15,
          energy: 97.474,
        },
        Ka: {
          weight: 1.0,
          energy: 86.1058,
        },
        M2N4: {
          weight: 0.00674,
          energy: 3.7237,
        },
        Lb4: {
          weight: 0.0603,
          energy: 14.312,
        },
        La: {
          weight: 1.0,
          energy: 12.0315,
        },
        Ln: {
          weight: 0.0134,
          energy: 13.2545,
        },
        Ll: {
          weight: 0.06339,
          energy: 10.3792,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.88971,
        },
        Mg: {
          weight: 0.21845,
          energy: 3.086,
        },
        Lb2: {
          weight: 0.2337,
          energy: 14.4542,
        },
        Lg3: {
          weight: 0.017,
          energy: 17.829,
        },
        Lg1: {
          weight: 0.08,
          energy: 17.3032,
        },
        Lb3: {
          weight: 0.05969,
          energy: 14.976,
        },
        Mz: {
          weight: 0.0058,
          energy: 2.1897,
        },
      },
    },
    generalProperties: {
      number: 87,
      atomicWeight: 223,
      name: 'francium',
    },
  },
  Fe: {
    physicalProperties: {
      density: 7.874,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.1272,
          energy: 7.058,
        },
        Ka: {
          weight: 1.0,
          energy: 6.4039,
        },
        La: {
          weight: 1.0,
          energy: 0.7045,
        },
        Ln: {
          weight: 0.12525,
          energy: 0.6282,
        },
        Ll: {
          weight: 0.3086,
          energy: 0.6152,
        },
        Lb3: {
          weight: 0.02448,
          energy: 0.7921,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 7113.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 846.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 721.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 708.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 57.0,
        },
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 57.0,
        },
      },
    },
    generalProperties: {
      number: 26,
      atomicWeight: 55.845,
      name: 'iron',
    },
  },
  B: {
    physicalProperties: {
      density: 2.46,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.1833,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 188.0,
        },
      },
    },
    generalProperties: {
      number: 5,
      atomicWeight: 10.811,
      name: 'boron',
    },
  },
  F: {
    physicalProperties: {
      density: 0.001696,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.6768,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 685.0,
        },
      },
    },
    generalProperties: {
      number: 9,
      atomicWeight: 18.9984032,
      name: 'fluorine',
    },
  },
  Sr: {
    physicalProperties: {
      density: 2.63,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.37975,
          energy: 1.8718,
        },
        Kb: {
          weight: 0.15,
          energy: 15.8355,
        },
        Ka: {
          weight: 1.0,
          energy: 14.165,
        },
        La: {
          weight: 1.0,
          energy: 1.8065,
        },
        Ln: {
          weight: 0.01669,
          energy: 1.6493,
        },
        Ll: {
          weight: 0.04309,
          energy: 1.5821,
        },
        Lb3: {
          weight: 0.047,
          energy: 1.9472,
        },
        Lg3: {
          weight: 0.0065,
          energy: 2.1964,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 280.0,
        },
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 134.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 134.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2007.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1940.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 269.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 2216.0,
        },
      },
    },
    generalProperties: {
      number: 38,
      atomicWeight: 87.62,
      name: 'strontium',
    },
  },
  N: {
    physicalProperties: {
      density: 0.001251,
    },
    atomicProperties: {
      xrayLines: {
        Ka: {
          weight: 1.0,
          energy: 0.3924,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 401.0,
        },
      },
    },
    generalProperties: {
      number: 7,
      atomicWeight: 14.0067,
      name: 'nitrogen',
    },
  },
  Kr: {
    physicalProperties: {
      density: 0.00375,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.39031,
          energy: 1.6383,
        },
        Kb: {
          weight: 0.1538,
          energy: 14.1118,
        },
        Ka: {
          weight: 1.0,
          energy: 12.6507,
        },
        La: {
          weight: 1.0,
          energy: 1.586,
        },
        Ln: {
          weight: 0.0175,
          energy: 1.43887,
        },
        Ll: {
          weight: 0.04509,
          energy: 1.38657,
        },
        Lb3: {
          weight: 0.0465,
          energy: 1.7072,
        },
        Lg3: {
          weight: 0.005,
          energy: 1.921,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 89.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 89.0,
        },
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1727.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1675.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1921.0,
        },
      },
    },
    generalProperties: {
      number: 36,
      atomicWeight: 83.798,
      name: 'krypton',
    },
  },
  Si: {
    physicalProperties: {
      density: 2.33,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.02779,
          energy: 1.8389,
        },
        Ka: {
          weight: 1.0,
          energy: 1.7397,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1839.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 99.8,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 99.2,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 149.7,
        },
      },
    },
    generalProperties: {
      number: 14,
      atomicWeight: 28.0855,
      name: 'silicon',
    },
  },
  Sn: {
    physicalProperties: {
      density: 7.31,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43456,
          energy: 3.6628,
        },
        Kb: {
          weight: 0.15,
          energy: 28.4857,
        },
        Ka: {
          weight: 1.0,
          energy: 25.2713,
        },
        Lb2: {
          weight: 0.14689,
          energy: 3.9049,
        },
        Lb4: {
          weight: 0.0948,
          energy: 3.7083,
        },
        La: {
          weight: 1.0,
          energy: 3.444,
        },
        Ln: {
          weight: 0.0158,
          energy: 3.2723,
        },
        Ll: {
          weight: 0.0416,
          energy: 3.045,
        },
        Lb3: {
          weight: 0.1547,
          energy: 3.7503,
        },
        Lg3: {
          weight: 0.0321,
          energy: 4.3761,
        },
        Lg1: {
          weight: 0.058,
          energy: 4.1322,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 485.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 494.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 714.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 756.0,
        },
      },
    },
    generalProperties: {
      number: 50,
      atomicWeight: 118.71,
      name: 'tin',
    },
  },
  Sm: {
    physicalProperties: {
      density: 7.353,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43512,
          energy: 6.2058,
        },
        Kb: {
          weight: 0.15,
          energy: 45.4144,
        },
        Ka: {
          weight: 1.0,
          energy: 40.118,
        },
        M2N4: {
          weight: 0.012,
          energy: 1.4117,
        },
        Ma: {
          weight: 1.0,
          energy: 1.0428,
        },
        Lb4: {
          weight: 0.08689,
          energy: 6.1961,
        },
        La: {
          weight: 1.0,
          energy: 5.636,
        },
        Ln: {
          weight: 0.01489,
          energy: 5.589,
        },
        Ll: {
          weight: 0.04519,
          energy: 4.9934,
        },
        Mb: {
          weight: 0.88,
          energy: 1.1005,
        },
        Mg: {
          weight: 0.26,
          energy: 1.2908,
        },
        Lb2: {
          weight: 0.19769,
          energy: 6.5872,
        },
        Lb3: {
          weight: 0.12669,
          energy: 6.317,
        },
        Lg3: {
          weight: 0.0318,
          energy: 7.4894,
        },
        Lg1: {
          weight: 0.07951,
          energy: 7.1828,
        },
        Mz: {
          weight: 0.06,
          energy: 0.8328,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1080.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1106.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1420.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1541.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 130.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 130.0,
        },
      },
    },
    generalProperties: {
      number: 62,
      atomicWeight: 150.36,
      name: 'samarium',
    },
  },
  V: {
    physicalProperties: {
      density: 6.11,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.1225,
          energy: 5.4273,
        },
        Ka: {
          weight: 1.0,
          energy: 4.9522,
        },
        La: {
          weight: 1.0,
          energy: 0.5129,
        },
        Ln: {
          weight: 0.2805,
          energy: 0.454,
        },
        Ll: {
          weight: 0.5745,
          energy: 0.4464,
        },
        Lb3: {
          weight: 0.0154,
          energy: 0.5904,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 38.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 521.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 513.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 38.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 628.0,
        },
      },
    },
    generalProperties: {
      number: 23,
      atomicWeight: 50.9415,
      name: 'vanadium',
    },
  },
  Sc: {
    physicalProperties: {
      density: 2.985,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.12839,
          energy: 4.4605,
        },
        Ka: {
          weight: 1.0,
          energy: 4.0906,
        },
        La: {
          weight: 0.308,
          energy: 0.4022,
        },
        Ln: {
          weight: 0.488,
          energy: 0.3529,
        },
        Ll: {
          weight: 1.0,
          energy: 0.3484,
        },
        Lb3: {
          weight: 0.037,
          energy: 0.4681,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 32.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 407.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 402.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 32.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 500.0,
        },
      },
    },
    generalProperties: {
      number: 21,
      atomicWeight: 44.955912,
      name: 'scandium',
    },
  },
  Sb: {
    physicalProperties: {
      density: 6.697,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4276,
          energy: 3.8435,
        },
        Kb: {
          weight: 0.15,
          energy: 29.7256,
        },
        Ka: {
          weight: 1.0,
          energy: 26.359,
        },
        Lb2: {
          weight: 0.1556,
          energy: 4.1008,
        },
        Lb4: {
          weight: 0.0932,
          energy: 3.8864,
        },
        La: {
          weight: 1.0,
          energy: 3.6047,
        },
        Ln: {
          weight: 0.0155,
          energy: 3.4367,
        },
        Ll: {
          weight: 0.0419,
          energy: 3.1885,
        },
        Lb3: {
          weight: 0.15099,
          energy: 3.9327,
        },
        Lg3: {
          weight: 0.0321,
          energy: 4.5999,
        },
        Lg1: {
          weight: 0.06064,
          energy: 4.349,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 528.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 537.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 766.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 812.0,
        },
      },
    },
    generalProperties: {
      number: 51,
      atomicWeight: 121.76,
      name: 'antimony',
    },
  },
  Se: {
    physicalProperties: {
      density: 4.819,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.38848,
          energy: 1.4195,
        },
        Kb: {
          weight: 0.1505,
          energy: 12.4959,
        },
        Ka: {
          weight: 1.0,
          energy: 11.222,
        },
        La: {
          weight: 1.0,
          energy: 1.3791,
        },
        Ln: {
          weight: 0.0187,
          energy: 1.2447,
        },
        Ll: {
          weight: 0.04759,
          energy: 1.2043,
        },
        Lb3: {
          weight: 0.047,
          energy: 1.492,
        },
      },
      bindingEnergies: {
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1476.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1436.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1654.0,
        },
      },
    },
    generalProperties: {
      number: 34,
      atomicWeight: 78.96,
      name: 'selenium',
    },
  },
  Co: {
    physicalProperties: {
      density: 8.9,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.1277,
          energy: 7.6494,
        },
        Ka: {
          weight: 1.0,
          energy: 6.9303,
        },
        La: {
          weight: 1.0,
          energy: 0.7757,
        },
        Ln: {
          weight: 0.0833,
          energy: 0.6929,
        },
        Ll: {
          weight: 0.2157,
          energy: 0.6779,
        },
        Lb3: {
          weight: 0.0238,
          energy: 0.8661,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 59.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 794.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 779.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 59.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 926.0,
        },
      },
    },
    generalProperties: {
      number: 27,
      atomicWeight: 58.933195,
      name: 'cobalt',
    },
  },
  Cl: {
    physicalProperties: {
      density: 0.003214,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.0838,
          energy: 2.8156,
        },
        Ka: {
          weight: 1.0,
          energy: 2.6224,
        },
      },
      bindingEnergies: {
        L2_3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 200.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 270.0,
        },
      },
    },
    generalProperties: {
      number: 17,
      atomicWeight: 35.453,
      name: 'chlorine',
    },
  },
  Ca: {
    physicalProperties: {
      density: 1.55,
    },
    atomicProperties: {
      xrayLines: {
        Ln: {
          weight: 0.23,
          energy: 0.3063,
        },
        Kb: {
          weight: 0.112,
          energy: 4.0127,
        },
        Ka: {
          weight: 1.0,
          energy: 3.6917,
        },
        Ll: {
          weight: 1.0,
          energy: 0.3027,
        },
        La: {
          weight: 0.0,
          energy: 0.3464,
        },
      },
      bindingEnergies: {
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 350.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 346.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 438.0,
        },
      },
    },
    generalProperties: {
      number: 20,
      atomicWeight: 40.078,
      name: 'calcium',
    },
  },
  Ce: {
    physicalProperties: {
      density: 6.689,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43,
          energy: 5.2629,
        },
        Kb: {
          weight: 0.15,
          energy: 39.2576,
        },
        Ka: {
          weight: 1.0,
          energy: 34.7196,
        },
        M2N4: {
          weight: 0.08,
          energy: 1.1628,
        },
        Ma: {
          weight: 1.0,
          energy: 0.8455,
        },
        Lb4: {
          weight: 0.08699,
          energy: 5.276,
        },
        La: {
          weight: 1.0,
          energy: 4.8401,
        },
        Ln: {
          weight: 0.015,
          energy: 4.7296,
        },
        Ll: {
          weight: 0.0436,
          energy: 4.2888,
        },
        Mb: {
          weight: 0.91,
          energy: 0.8154,
        },
        Mg: {
          weight: 0.5,
          energy: 1.0754,
        },
        Lb2: {
          weight: 0.19399,
          energy: 5.6134,
        },
        Lb3: {
          weight: 0.1325,
          energy: 5.3634,
        },
        Lg3: {
          weight: 0.0324,
          energy: 6.3416,
        },
        Lg1: {
          weight: 0.0764,
          energy: 6.0542,
        },
        Mz: {
          weight: 0.07,
          energy: 0.6761,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 883.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 901.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1185.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1273.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 110.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 110.0,
        },
      },
    },
    generalProperties: {
      number: 58,
      atomicWeight: 140.116,
      name: 'cerium',
    },
  },
  Xe: {
    physicalProperties: {
      density: 0.0059,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.42248,
          energy: 4.4183,
        },
        Kb: {
          weight: 0.15,
          energy: 33.6244,
        },
        Ka: {
          weight: 1.0,
          energy: 29.7792,
        },
        Lb2: {
          weight: 0.17699,
          energy: 4.7182,
        },
        Lb4: {
          weight: 0.08929,
          energy: 4.4538,
        },
        La: {
          weight: 1.0,
          energy: 4.1099,
        },
        Ln: {
          weight: 0.015,
          energy: 3.9591,
        },
        Ll: {
          weight: 0.0424,
          energy: 3.6376,
        },
        Lb3: {
          weight: 0.14119,
          energy: 4.5158,
        },
        Lg3: {
          weight: 0.0323,
          energy: 5.3061,
        },
        Lg1: {
          weight: 0.06848,
          energy: 5.0397,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 672.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 685.0,
        },
      },
    },
    generalProperties: {
      number: 54,
      atomicWeight: 131.293,
      name: 'xenon',
    },
  },
  Tm: {
    physicalProperties: {
      density: 9.321,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.45831,
          energy: 8.1023,
        },
        Kb: {
          weight: 0.15,
          energy: 57.5051,
        },
        Ka: {
          weight: 1.0,
          energy: 50.7416,
        },
        M2N4: {
          weight: 0.01,
          energy: 1.9102,
        },
        Ma: {
          weight: 1.0,
          energy: 1.4624,
        },
        Lb4: {
          weight: 0.09449,
          energy: 8.0259,
        },
        La: {
          weight: 1.0,
          energy: 7.1803,
        },
        Ln: {
          weight: 0.0156,
          energy: 7.3101,
        },
        Ll: {
          weight: 0.04889,
          energy: 6.3412,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.5093,
        },
        Mg: {
          weight: 0.08505,
          energy: 1.7049,
        },
        Lb2: {
          weight: 0.20059,
          energy: 8.4684,
        },
        Lb3: {
          weight: 0.1273,
          energy: 8.2312,
        },
        Lg3: {
          weight: 0.0329,
          energy: 9.7791,
        },
        Lg1: {
          weight: 0.08615,
          energy: 9.4373,
        },
        Mz: {
          weight: 0.06,
          energy: 1.1311,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1468.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1515.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1884.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 2090.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 180.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 180.0,
        },
      },
    },
    generalProperties: {
      number: 69,
      atomicWeight: 168.93421,
      name: 'thulium',
    },
  },
  Cs: {
    physicalProperties: {
      density: 1.879,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.42983,
          energy: 4.6199,
        },
        Kb: {
          weight: 0.15,
          energy: 34.987,
        },
        Ka: {
          weight: 1.0,
          energy: 30.9727,
        },
        Lb2: {
          weight: 0.19589,
          energy: 4.9354,
        },
        Lb4: {
          weight: 0.08869,
          energy: 4.6493,
        },
        La: {
          weight: 1.0,
          energy: 4.2864,
        },
        Ln: {
          weight: 0.0152,
          energy: 4.1423,
        },
        Ll: {
          weight: 0.04269,
          energy: 3.7948,
        },
        Lb3: {
          weight: 0.1399,
          energy: 4.7167,
        },
        Lg3: {
          weight: 0.0325,
          energy: 5.5527,
        },
        Lg1: {
          weight: 0.07215,
          energy: 5.2806,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 726.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 740.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 998.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1065.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 78.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 78.0,
        },
      },
    },
    generalProperties: {
      number: 55,
      atomicWeight: 132.9054519,
      name: 'cesium',
    },
  },
  Cr: {
    physicalProperties: {
      density: 7.14,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.134,
          energy: 5.9467,
        },
        Ka: {
          weight: 1.0,
          energy: 5.4147,
        },
        La: {
          weight: 1.0,
          energy: 0.5722,
        },
        Ln: {
          weight: 0.2353,
          energy: 0.5096,
        },
        Ll: {
          weight: 0.6903,
          energy: 0.5004,
        },
        Lb3: {
          weight: 0.0309,
          energy: 0.6521,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 43.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 584.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 575.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 43.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 695.0,
        },
      },
    },
    generalProperties: {
      number: 24,
      atomicWeight: 51.9961,
      name: 'chromium',
    },
  },
  Cu: {
    physicalProperties: {
      density: 8.92,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.03197,
          energy: 0.9494,
        },
        Kb: {
          weight: 0.13157,
          energy: 8.9053,
        },
        Ka: {
          weight: 1.0,
          energy: 8.0478,
        },
        La: {
          weight: 1.0,
          energy: 0.9295,
        },
        Ln: {
          weight: 0.01984,
          energy: 0.8312,
        },
        Ll: {
          weight: 0.08401,
          energy: 0.8113,
        },
        Lb3: {
          weight: 0.00114,
          energy: 1.0225,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 74.0,
        },
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 951.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 931.0,
        },
        M3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 74.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1096.0,
        },
      },
    },
    generalProperties: {
      number: 29,
      atomicWeight: 63.546,
      name: 'copper',
    },
  },
  La: {
    physicalProperties: {
      density: 6.146,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.42631,
          energy: 5.0421,
        },
        Kb: {
          weight: 0.15,
          energy: 37.8012,
        },
        Ka: {
          weight: 1.0,
          energy: 33.4419,
        },
        M2N4: {
          weight: 0.022,
          energy: 1.1055,
        },
        Ma: {
          weight: 1.0,
          energy: 0.8173,
        },
        Lb4: {
          weight: 0.0872,
          energy: 5.0619,
        },
        La: {
          weight: 1.0,
          energy: 4.651,
        },
        Ln: {
          weight: 0.015,
          energy: 4.5293,
        },
        Ll: {
          weight: 0.0432,
          energy: 4.1214,
        },
        Mb: {
          weight: 0.9,
          energy: 0.8162,
        },
        Mg: {
          weight: 0.4,
          energy: 1.0245,
        },
        Lb2: {
          weight: 0.19579,
          energy: 5.3838,
        },
        Lb3: {
          weight: 0.1341,
          energy: 5.1429,
        },
        Lg3: {
          weight: 0.0329,
          energy: 6.0749,
        },
        Lg1: {
          weight: 0.07656,
          energy: 5.7917,
        },
        Mz: {
          weight: 0.06,
          energy: 0.6403,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 832.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 849.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1123.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1204.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 99.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 99.0,
        },
      },
    },
    generalProperties: {
      number: 57,
      atomicWeight: 138.90547,
      name: 'lanthanum',
    },
  },
  Li: {
    physicalProperties: {
      density: 0.534,
    },
    atomicProperties: {
      xrayLines: {},
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 55.0,
        },
      },
    },
    generalProperties: {
      atomicWeight: 6.939,
      number: 3,
      name: 'lithium',
    },
  },
  Tl: {
    physicalProperties: {
      density: 11.85,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.39112,
          energy: 12.2128,
        },
        Kb: {
          weight: 0.15,
          energy: 82.5738,
        },
        Ka: {
          weight: 1.0,
          energy: 72.8729,
        },
        M2N4: {
          weight: 0.00863,
          energy: 3.0091,
        },
        Ma: {
          weight: 1.0,
          energy: 2.2708,
        },
        Lb4: {
          weight: 0.05419,
          energy: 11.931,
        },
        La: {
          weight: 1.0,
          energy: 10.2682,
        },
        Ln: {
          weight: 0.0134,
          energy: 10.9938,
        },
        M3O4: {
          energy: 2.9413,
          weight: 0.005,
        },
        Ll: {
          weight: 0.0578,
          energy: 8.9534,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.3623,
        },
        Mg: {
          weight: 0.21845,
          energy: 2.5704,
        },
        Lb2: {
          weight: 0.22289,
          energy: 12.2713,
        },
        Lb3: {
          weight: 0.0607,
          energy: 12.3901,
        },
        M3O5: {
          energy: 2.9435,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.0175,
          energy: 14.7377,
        },
        Lg1: {
          weight: 0.08304,
          energy: 14.2913,
        },
        Mz: {
          weight: 0.0058,
          energy: 1.7803,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2389.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2485.0,
        },
      },
    },
    generalProperties: {
      number: 81,
      atomicWeight: 204.3833,
      name: 'thallium',
    },
  },
  Lu: {
    physicalProperties: {
      density: 9.841,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.46975,
          energy: 8.7092,
        },
        Kb: {
          weight: 0.15,
          energy: 61.2902,
        },
        Ka: {
          weight: 1.0,
          energy: 54.0697,
        },
        M2N4: {
          weight: 0.01,
          energy: 2.0587,
        },
        Ma: {
          weight: 1.0,
          energy: 1.5816,
        },
        Lb4: {
          weight: 0.0996,
          energy: 8.6069,
        },
        La: {
          weight: 1.0,
          energy: 7.6556,
        },
        Ln: {
          weight: 0.016,
          energy: 7.8574,
        },
        Ll: {
          weight: 0.05009,
          energy: 6.7529,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.6325,
        },
        Mg: {
          weight: 0.08505,
          energy: 1.8286,
        },
        Lb2: {
          weight: 0.20359,
          energy: 9.0491,
        },
        Lb3: {
          weight: 0.13099,
          energy: 8.8468,
        },
        Lg3: {
          weight: 0.0342,
          energy: 10.5111,
        },
        Lg1: {
          weight: 0.08968,
          energy: 10.1438,
        },
        Mz: {
          weight: 0.06,
          energy: 1.2292,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1588.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1639.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 2024.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 2263.0,
        },
        N4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Very delayed',
          onsetEnergy: 195.0,
        },
        N5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Very delayed',
          onsetEnergy: 195.0,
        },
      },
    },
    generalProperties: {
      number: 71,
      atomicWeight: 174.9668,
      name: 'lutetium',
    },
  },
  Th: {
    physicalProperties: {
      density: 11.724,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4,
          energy: 16.2024,
        },
        Kb: {
          weight: 0.15,
          energy: 105.6049,
        },
        Ka: {
          weight: 1.0,
          energy: 93.3507,
        },
        M2N4: {
          weight: 0.00674,
          energy: 4.1163,
        },
        Ma: {
          weight: 1.0,
          energy: 2.9968,
        },
        Lb4: {
          weight: 0.05,
          energy: 15.6417,
        },
        La: {
          weight: 1.0,
          energy: 12.9683,
        },
        Ln: {
          weight: 0.0134,
          energy: 14.5109,
        },
        M3O4: {
          energy: 3.9518,
          weight: 0.01,
        },
        Ll: {
          weight: 0.06709,
          energy: 11.118,
        },
        Mb: {
          weight: 0.64124,
          energy: 3.1464,
        },
        Mg: {
          weight: 0.33505,
          energy: 3.3697,
        },
        Lb2: {
          weight: 0.236,
          energy: 15.6239,
        },
        Lb3: {
          weight: 0.06,
          energy: 16.426,
        },
        M3O5: {
          energy: 3.9582,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.017,
          energy: 19.5048,
        },
        Lg1: {
          weight: 0.08,
          energy: 18.9791,
        },
        Mz: {
          weight: 0.03512,
          energy: 2.3647,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 3332.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 3491.0,
        },
        O5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 83.0,
        },
        O4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 83.0,
        },
      },
    },
    generalProperties: {
      number: 90,
      atomicWeight: 232.03806,
      name: 'thorium',
    },
  },
  Ti: {
    physicalProperties: {
      density: 4.507,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.11673,
          energy: 4.9318,
        },
        Ka: {
          weight: 1.0,
          energy: 4.5109,
        },
        La: {
          weight: 0.694,
          energy: 0.4555,
        },
        Ln: {
          weight: 0.491,
          energy: 0.4012,
        },
        Ll: {
          weight: 1.0,
          energy: 0.3952,
        },
        Lb3: {
          weight: 0.166,
          energy: 0.5291,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 35.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 462.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 456.0,
        },
        M3: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 35.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 564.0,
        },
      },
    },
    generalProperties: {
      number: 22,
      atomicWeight: 47.867,
      name: 'titanium',
    },
  },
  Te: {
    physicalProperties: {
      density: 6.24,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43183,
          energy: 4.0295,
        },
        Kb: {
          weight: 0.15,
          energy: 30.9951,
        },
        Ka: {
          weight: 1.0,
          energy: 27.4724,
        },
        Lb2: {
          weight: 0.16269,
          energy: 4.3016,
        },
        Lb4: {
          weight: 0.0906,
          energy: 4.0695,
        },
        La: {
          weight: 1.0,
          energy: 3.7693,
        },
        Ln: {
          weight: 0.0154,
          energy: 3.606,
        },
        Ll: {
          weight: 0.0419,
          energy: 3.3354,
        },
        Lb3: {
          weight: 0.1458,
          energy: 4.1205,
        },
        Lg3: {
          weight: 0.0317,
          energy: 4.829,
        },
        Lg1: {
          weight: 0.06375,
          energy: 4.5722,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 572.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 582.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 819.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 870.0,
        },
      },
    },
    generalProperties: {
      number: 52,
      atomicWeight: 127.6,
      name: 'tellurium',
    },
  },
  Tb: {
    physicalProperties: {
      density: 8.219,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.44104,
          energy: 6.9766,
        },
        Kb: {
          weight: 0.15,
          energy: 50.3844,
        },
        Ka: {
          weight: 1.0,
          energy: 44.4817,
        },
        M2N4: {
          weight: 0.014,
          energy: 1.6207,
        },
        Ma: {
          weight: 1.0,
          energy: 1.2326,
        },
        Lb4: {
          weight: 0.0874,
          energy: 6.9403,
        },
        La: {
          weight: 1.0,
          energy: 6.2728,
        },
        Ln: {
          weight: 0.01479,
          energy: 6.2841,
        },
        Ll: {
          weight: 0.0465,
          energy: 5.5465,
        },
        Mb: {
          weight: 0.78,
          energy: 1.2656,
        },
        Mg: {
          weight: 0.2615,
          energy: 1.4643,
        },
        Lb2: {
          weight: 0.19929,
          energy: 7.367,
        },
        Lb3: {
          weight: 0.124,
          energy: 7.0967,
        },
        Lg3: {
          weight: 0.0315,
          energy: 8.423,
        },
        Lg1: {
          weight: 0.08168,
          energy: 8.1046,
        },
        Mz: {
          weight: 0.06,
          energy: 0.9562,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1241.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1275.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1611.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1768.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 148.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 148.0,
        },
      },
    },
    generalProperties: {
      number: 65,
      atomicWeight: 158.92535,
      name: 'terbium',
    },
  },
  Tc: {
    physicalProperties: {
      density: 11.5,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.32951,
          energy: 2.5368,
        },
        Kb: {
          weight: 0.15,
          energy: 20.619,
        },
        Ka: {
          weight: 1.0,
          energy: 18.3671,
        },
        Lb2: {
          weight: 0.05839,
          energy: 2.67017,
        },
        La: {
          weight: 1.0,
          energy: 2.424,
        },
        Ln: {
          weight: 0.0127,
          energy: 2.2456,
        },
        Ll: {
          weight: 0.0412,
          energy: 2.1293,
        },
        Lb3: {
          weight: 0.0644,
          energy: 2.6175,
        },
        Lg3: {
          weight: 0.0111,
          energy: 3.0036,
        },
        Lg1: {
          weight: 0.01744,
          energy: 2.78619,
        },
      },
    },
    generalProperties: {
      number: 43,
      atomicWeight: 98,
      name: 'technetium',
    },
  },
  Ta: {
    physicalProperties: {
      density: 16.65,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.46248,
          energy: 9.3429,
        },
        Kb: {
          weight: 0.15,
          energy: 65.2224,
        },
        Ka: {
          weight: 1.0,
          energy: 57.5353,
        },
        M2N4: {
          weight: 0.01,
          energy: 2.2274,
        },
        Ma: {
          weight: 1.0,
          energy: 1.7101,
        },
        Lb4: {
          weight: 0.10449,
          energy: 9.2128,
        },
        La: {
          weight: 1.0,
          energy: 8.146,
        },
        Ln: {
          weight: 0.0158,
          energy: 8.4281,
        },
        M3O4: {
          energy: 2.1883,
          weight: 0.0001,
        },
        Ll: {
          weight: 0.0515,
          energy: 7.1731,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.7682,
        },
        Mg: {
          weight: 0.08505,
          energy: 1.9647,
        },
        Lb2: {
          weight: 0.2076,
          energy: 9.6518,
        },
        Lb3: {
          weight: 0.1333,
          energy: 9.4875,
        },
        M3O5: {
          energy: 2.194,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.0354,
          energy: 11.277,
        },
        Lg1: {
          weight: 0.09071,
          energy: 10.8948,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.3306,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1735.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1793.0,
        },
      },
    },
    generalProperties: {
      number: 73,
      atomicWeight: 180.94788,
      name: 'tantalum',
    },
  },
  Yb: {
    physicalProperties: {
      density: 6.57,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.46224,
          energy: 8.4019,
        },
        Kb: {
          weight: 0.15,
          energy: 59.3825,
        },
        Ka: {
          weight: 1.0,
          energy: 52.3887,
        },
        M2N4: {
          weight: 0.01,
          energy: 1.9749,
        },
        Ma: {
          weight: 1.0,
          energy: 1.5215,
        },
        Lb4: {
          weight: 0.09589,
          energy: 8.3134,
        },
        La: {
          weight: 1.0,
          energy: 7.4158,
        },
        Ln: {
          weight: 0.0157,
          energy: 7.5801,
        },
        Ll: {
          weight: 0.0494,
          energy: 6.5455,
        },
        Mb: {
          weight: 0.59443,
          energy: 1.57,
        },
        Mg: {
          weight: 0.08505,
          energy: 1.7649,
        },
        Lb2: {
          weight: 0.2017,
          energy: 8.7587,
        },
        Lb3: {
          weight: 0.12789,
          energy: 8.5366,
        },
        Lg3: {
          weight: 0.0331,
          energy: 10.1429,
        },
        Lg1: {
          weight: 0.08728,
          energy: 9.7801,
        },
        Mz: {
          weight: 0.06,
          energy: 1.1843,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1528.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1576.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1950.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 2173.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 184.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 184.0,
        },
      },
    },
    generalProperties: {
      number: 70,
      atomicWeight: 173.054,
      name: 'ytterbium',
    },
  },
  Dy: {
    physicalProperties: {
      density: 8.551,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.444,
          energy: 7.2481,
        },
        Kb: {
          weight: 0.15,
          energy: 52.1129,
        },
        Ka: {
          weight: 1.0,
          energy: 45.9984,
        },
        M2N4: {
          weight: 0.008,
          energy: 1.6876,
        },
        Ma: {
          weight: 1.0,
          energy: 1.2907,
        },
        Lb4: {
          weight: 0.0891,
          energy: 7.204,
        },
        La: {
          weight: 1.0,
          energy: 6.4952,
        },
        Ln: {
          weight: 0.01489,
          energy: 6.5338,
        },
        Ll: {
          weight: 0.0473,
          energy: 5.7433,
        },
        Mb: {
          weight: 0.76,
          energy: 1.3283,
        },
        Mg: {
          weight: 0.08505,
          energy: 1.5214,
        },
        Lb2: {
          weight: 0.2,
          energy: 7.6359,
        },
        Lb3: {
          weight: 0.12529,
          energy: 7.3702,
        },
        Lg3: {
          weight: 0.0319,
          energy: 8.7529,
        },
        Lg1: {
          weight: 0.08295,
          energy: 8.4264,
        },
        Mz: {
          weight: 0.06,
          energy: 1.002,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1295.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 1332.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1676.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 1842.0,
        },
        N4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 154.0,
        },
        N5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 154.0,
        },
      },
    },
    generalProperties: {
      number: 66,
      atomicWeight: 162.5,
      name: 'dysprosium',
    },
  },
  I: {
    physicalProperties: {
      density: 4.94,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.43087,
          energy: 4.2208,
        },
        Kb: {
          weight: 0.15,
          energy: 32.2948,
        },
        Ka: {
          weight: 1.0,
          energy: 28.6123,
        },
        Lb2: {
          weight: 0.17059,
          energy: 4.5075,
        },
        Lb4: {
          weight: 0.09189,
          energy: 4.2576,
        },
        La: {
          weight: 1.0,
          energy: 3.9377,
        },
        Ln: {
          weight: 0.0154,
          energy: 3.78,
        },
        Ll: {
          weight: 0.0423,
          energy: 3.485,
        },
        Lb3: {
          weight: 0.1464,
          energy: 4.3135,
        },
        Lg3: {
          weight: 0.0327,
          energy: 5.0654,
        },
        Lg1: {
          weight: 0.06704,
          energy: 4.8025,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 620.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 631.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 875.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 930.0,
        },
      },
    },
    generalProperties: {
      number: 53,
      atomicWeight: 126.90447,
      name: 'iodine',
    },
  },
  U: {
    physicalProperties: {
      density: 19.05,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4,
          energy: 17.22,
        },
        Kb: {
          weight: 0.15,
          energy: 111.3026,
        },
        Ka: {
          weight: 1.0,
          energy: 98.4397,
        },
        M2N4: {
          weight: 0.00674,
          energy: 4.4018,
        },
        Ma: {
          weight: 1.0,
          energy: 3.1708,
        },
        Lb4: {
          weight: 0.04,
          energy: 16.5752,
        },
        La: {
          weight: 1.0,
          energy: 13.6146,
        },
        Ln: {
          weight: 0.01199,
          energy: 15.3996,
        },
        M3O4: {
          energy: 4.1984,
          weight: 0.01,
        },
        Ll: {
          weight: 0.069,
          energy: 11.6183,
        },
        Mb: {
          weight: 0.6086,
          energy: 3.3363,
        },
        Mg: {
          weight: 0.33505,
          energy: 3.5657,
        },
        Lb2: {
          weight: 0.236,
          energy: 16.4286,
        },
        Lb3: {
          weight: 0.06,
          energy: 17.454,
        },
        M3O5: {
          energy: 4.2071,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.017,
          energy: 20.7125,
        },
        Lg1: {
          weight: 0.08,
          energy: 20.1672,
        },
        Mz: {
          weight: 0.03512,
          energy: 2.5068,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 3552.0,
        },
        M4: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 3728.0,
        },
        O5: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 96.0,
        },
        O4: {
          relevance: 'Major',
          threshold: 'Broad peak',
          edge: 'Delayed maximum',
          onsetEnergy: 96.0,
        },
      },
    },
    generalProperties: {
      number: 92,
      atomicWeight: 238.02891,
      name: 'uranium',
    },
  },
  Y: {
    physicalProperties: {
      density: 4.472,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.39127,
          energy: 1.9959,
        },
        Kb: {
          weight: 0.15,
          energy: 16.7381,
        },
        Ka: {
          weight: 1.0,
          energy: 14.9584,
        },
        Lb2: {
          weight: 0.00739,
          energy: 2.08,
        },
        La: {
          weight: 1.0,
          energy: 1.9226,
        },
        Ln: {
          weight: 0.0162,
          energy: 1.7619,
        },
        Ll: {
          weight: 0.0428,
          energy: 1.6864,
        },
        Lb3: {
          weight: 0.05059,
          energy: 2.0722,
        },
        Lg3: {
          weight: 0.0075,
          energy: 2.3469,
        },
        Lg1: {
          weight: 0.00264,
          energy: 2.1555,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 312.0,
        },
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 160.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 160.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2155.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2080.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 300.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 2372.0,
        },
      },
    },
    generalProperties: {
      number: 39,
      atomicWeight: 88.90585,
      name: 'yttrium',
    },
  },
  Ac: {
    physicalProperties: {
      density: 10.07,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4,
          energy: 15.713,
        },
        Kb: {
          weight: 0.15,
          energy: 102.846,
        },
        Ka: {
          weight: 1.0,
          energy: 90.884,
        },
        M2N4: {
          weight: 0.00674,
          energy: 3.9811,
        },
        Ma: {
          energy: 2.9239330000000003,
          weight: 1.0,
        },
        La: {
          weight: 1.0,
          energy: 12.652,
        },
        Ln: {
          weight: 0.0133,
          energy: 14.0812,
        },
        M3O4: {
          energy: 3.82586,
          weight: 0.01,
        },
        Ll: {
          weight: 0.06549,
          energy: 10.869,
        },
        Mb: {
          weight: 0.64124,
          energy: 3.06626,
        },
        Mg: {
          weight: 0.33505,
          energy: 3.272,
        },
        M3O5: {
          energy: 3.83206,
          weight: 0.01,
        },
        Lb2: {
          weight: 0.236,
          energy: 15.234,
        },
        Lg3: {
          weight: 0.017,
          energy: 18.95,
        },
        Lg1: {
          weight: 0.08,
          energy: 18.4083,
        },
        Lb3: {
          weight: 0.06,
          energy: 15.931,
        },
        Mz: {
          weight: 0.03512,
          energy: 2.329,
        },
      },
    },
    generalProperties: {
      number: 89,
      atomicWeight: 227,
      name: 'actinium',
    },
  },
  Ag: {
    physicalProperties: {
      density: 10.49,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.35175,
          energy: 3.1509,
        },
        Kb: {
          weight: 0.15,
          energy: 24.9426,
        },
        Ka: {
          weight: 1.0,
          energy: 22.1629,
        },
        Lb2: {
          weight: 0.1165,
          energy: 3.3478,
        },
        Lb4: {
          weight: 0.0444,
          energy: 3.2034,
        },
        La: {
          weight: 1.0,
          energy: 2.9844,
        },
        Ln: {
          weight: 0.0131,
          energy: 2.8062,
        },
        Ll: {
          weight: 0.04129,
          energy: 2.6336,
        },
        Lb3: {
          weight: 0.0737,
          energy: 3.2344,
        },
        Lg3: {
          weight: 0.014,
          energy: 3.7499,
        },
        Lg1: {
          weight: 0.03735,
          energy: 3.5204,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 367.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 373.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 571.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 602.0,
        },
      },
    },
    generalProperties: {
      number: 47,
      atomicWeight: 107.8682,
      name: 'silver',
    },
  },
  Ir: {
    physicalProperties: {
      density: 22.56,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.4168,
          energy: 10.708,
        },
        Kb: {
          weight: 0.15,
          energy: 73.5603,
        },
        Ka: {
          weight: 1.0,
          energy: 64.8958,
        },
        M2N4: {
          weight: 0.02901,
          energy: 2.5973,
        },
        Ma: {
          weight: 1.0,
          energy: 1.9799,
        },
        Lb4: {
          weight: 0.07269,
          energy: 10.5098,
        },
        La: {
          weight: 1.0,
          energy: 9.1748,
        },
        Ln: {
          weight: 0.01429,
          energy: 9.6504,
        },
        M3O4: {
          energy: 2.54264,
          weight: 0.005,
        },
        Ll: {
          weight: 0.05429,
          energy: 8.0415,
        },
        Mb: {
          weight: 0.59443,
          energy: 2.0527,
        },
        Mg: {
          weight: 0.08505,
          energy: 2.2558,
        },
        Lb2: {
          weight: 0.216,
          energy: 10.9203,
        },
        Lb3: {
          weight: 0.0874,
          energy: 10.8678,
        },
        M3O5: {
          energy: 2.54385,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.024,
          energy: 12.9242,
        },
        Lg1: {
          weight: 0.08543,
          energy: 12.5127,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.5461,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2040.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2116.0,
        },
      },
    },
    generalProperties: {
      number: 77,
      atomicWeight: 192.217,
      name: 'iridium',
    },
  },
  Al: {
    physicalProperties: {
      density: 2.7,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.0132,
          energy: 1.5596,
        },
        Ka: {
          weight: 1.0,
          energy: 1.4865,
        },
      },
      bindingEnergies: {
        K: {
          relevance: 'Major',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1560.0,
        },
        L2_3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 73.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 118.0,
        },
      },
    },
    generalProperties: {
      number: 13,
      atomicWeight: 26.9815386,
      name: 'aluminum',
    },
  },
  As: {
    physicalProperties: {
      density: 5.727,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.16704,
          energy: 1.3174,
        },
        Kb: {
          weight: 0.14589,
          energy: 11.7262,
        },
        Ka: {
          weight: 1.0,
          energy: 10.5436,
        },
        La: {
          weight: 1.0,
          energy: 1.2819,
        },
        Ln: {
          weight: 0.01929,
          energy: 1.1551,
        },
        Ll: {
          weight: 0.04929,
          energy: 1.1196,
        },
        Lb3: {
          weight: 0.04769,
          energy: 1.386,
        },
      },
      bindingEnergies: {
        L2: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1359.0,
        },
        L3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 1323.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 1526.0,
        },
      },
    },
    generalProperties: {
      number: 33,
      atomicWeight: 74.9216,
      name: 'arsenic',
    },
  },
  Ar: {
    physicalProperties: {
      density: 0.001784,
    },
    atomicProperties: {
      xrayLines: {
        Kb: {
          weight: 0.10169,
          energy: 3.1905,
        },
        Ka: {
          weight: 1.0,
          energy: 2.9577,
        },
      },
      bindingEnergies: {
        L2_3: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 245.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 320.0,
        },
      },
    },
    generalProperties: {
      number: 18,
      atomicWeight: 39.948,
      name: 'argon',
    },
  },
  Au: {
    physicalProperties: {
      density: 19.3,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.40151,
          energy: 11.4425,
        },
        Kb: {
          weight: 0.15,
          energy: 77.9819,
        },
        Ka: {
          weight: 1.0,
          energy: 68.8062,
        },
        M2N4: {
          weight: 0.02901,
          energy: 2.7958,
        },
        Ma: {
          weight: 1.0,
          energy: 2.1229,
        },
        Lb4: {
          weight: 0.0594,
          energy: 11.205,
        },
        La: {
          weight: 1.0,
          energy: 9.713,
        },
        Ln: {
          weight: 0.01379,
          energy: 10.3087,
        },
        M3O4: {
          energy: 2.73469,
          weight: 0.005,
        },
        Ll: {
          weight: 0.0562,
          energy: 8.4938,
        },
        Mb: {
          weight: 0.59443,
          energy: 2.2047,
        },
        Mg: {
          weight: 0.08505,
          energy: 2.4091,
        },
        Lb2: {
          weight: 0.21949,
          energy: 11.5848,
        },
        Lb3: {
          weight: 0.069,
          energy: 11.6098,
        },
        M3O5: {
          energy: 2.73621,
          weight: 0.01,
        },
        Lg3: {
          weight: 0.0194,
          energy: 13.8074,
        },
        Lg1: {
          weight: 0.08407,
          energy: 13.3816,
        },
        Mz: {
          weight: 0.01344,
          energy: 1.6603,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2206.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 2291.0,
        },
      },
    },
    generalProperties: {
      number: 79,
      atomicWeight: 196.966569,
      name: 'gold',
    },
  },
  At: {
    physicalProperties: {
      density: 'NaN',
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.38048,
          energy: 13.876,
        },
        Kb: {
          weight: 0.15,
          energy: 92.3039,
        },
        Ka: {
          weight: 1.0,
          energy: 81.5164,
        },
        M2N4: {
          weight: 0.00863,
          energy: 3.4748,
        },
        Lb4: {
          weight: 0.05809,
          energy: 13.485,
        },
        La: {
          weight: 1.0,
          energy: 11.4268,
        },
        Ln: {
          weight: 0.0132,
          energy: 12.4677,
        },
        Ll: {
          weight: 0.06179,
          energy: 9.8965,
        },
        Mb: {
          weight: 0.64124,
          energy: 2.71162,
        },
        Mg: {
          weight: 0.21845,
          energy: 2.95061,
        },
        Lb2: {
          weight: 0.2305,
          energy: 13.73812,
        },
        Lg3: {
          weight: 0.017,
          energy: 16.753,
        },
        Lg1: {
          weight: 0.08,
          energy: 16.2515,
        },
        Lb3: {
          weight: 0.06,
          energy: 14.067,
        },
        Mz: {
          weight: 0.00354,
          energy: 2.0467,
        },
      },
    },
    generalProperties: {
      number: 85,
      atomicWeight: 210,
      name: 'astatine',
    },
  },
  In: {
    physicalProperties: {
      density: 7.31,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.3616,
          energy: 3.4872,
        },
        Kb: {
          weight: 0.15,
          energy: 27.2756,
        },
        Ka: {
          weight: 1.0,
          energy: 24.2098,
        },
        Lb2: {
          weight: 0.1371,
          energy: 3.7139,
        },
        Lb4: {
          weight: 0.05349,
          energy: 3.5353,
        },
        La: {
          weight: 1.0,
          energy: 3.287,
        },
        Ln: {
          weight: 0.0132,
          energy: 3.1124,
        },
        Ll: {
          weight: 0.0415,
          energy: 2.9045,
        },
        Lb3: {
          weight: 0.08779,
          energy: 3.5732,
        },
        Lg3: {
          weight: 0.0177,
          energy: 4.1601,
        },
        Lg1: {
          weight: 0.04535,
          energy: 3.9218,
        },
      },
      bindingEnergies: {
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 443.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 451.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 664.0,
        },
        M2: {
          relevance: 'Minor',
          threshold: '',
          edge: '',
          onsetEnergy: 702.0,
        },
      },
    },
    generalProperties: {
      number: 49,
      atomicWeight: 114.818,
      name: 'indium',
    },
  },
  Mo: {
    physicalProperties: {
      density: 10.28,
    },
    atomicProperties: {
      xrayLines: {
        Lb1: {
          weight: 0.32736,
          energy: 2.3948,
        },
        Kb: {
          weight: 0.15,
          energy: 19.6072,
        },
        Ka: {
          weight: 1.0,
          energy: 17.4793,
        },
        Lb2: {
          weight: 0.04509,
          energy: 2.5184,
        },
        La: {
          weight: 1.0,
          energy: 2.2932,
        },
        Ln: {
          weight: 0.0128,
          energy: 2.1205,
        },
        Ll: {
          weight: 0.0415,
          energy: 2.0156,
        },
        Lb3: {
          weight: 0.06299,
          energy: 2.4732,
        },
        Lg3: {
          weight: 0.0105,
          energy: 2.8307,
        },
        Lg1: {
          weight: 0.01335,
          energy: 2.6233,
        },
      },
      bindingEnergies: {
        M2: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 410.0,
        },
        M5: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 227.0,
        },
        M4: {
          relevance: 'Major',
          threshold: '',
          edge: 'Delayed maximum',
          onsetEnergy: 228.0,
        },
        L2: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2625.0,
        },
        L3: {
          relevance: 'Major',
          threshold: 'Sharp peak',
          edge: 'Delayed maximum',
          onsetEnergy: 2520.0,
        },
        M3: {
          relevance: 'Minor',
          threshold: 'Sharp peak',
          edge: '',
          onsetEnergy: 392.0,
        },
        L1: {
          relevance: 'Minor',
          threshold: '',
          edge: 'Abrupt onset',
          onsetEnergy: 2866.0,
        },
      },
    },
    generalProperties: {
      number: 42,
      atomicWeight: 95.96,
      name: 'molybdenum',
    },
  },
} as Partial<Record<ElementSymbol, PeriodicElementProperties>>
