<template>
  <div
    class="periodic-element"
    :class="{
      [`periodic-element--${elementDefinition.block}-block`]: true,
      [`periodic-element--${elementDefinition.symbol}`]: true,
      ['periodic-element--selected']: isSelected,
      ['periodic-element--highlighted']: isHighlighted,
      ['periodic-element--excluded']: isExcluded,
      ['periodic-element--disable']: isDisabled,
      ['periodic-element--standalone']: standalone,
      ['periodic-element--dense']: dense,
    }"
  >
    <div class="periodic-element__top-right">
      <i v-if="isExcluded" class="ri-close-fill" />
      <i v-else-if="isDetected" class="ri-sparkling-fill" />
      <i v-else-if="isSelected" class="ri-add-fill" />
    </div>
    <div class="periodic-element__top-left">
      {{ elementDefinition.number }}
    </div>
    <div class="periodic-element__symbol">{{ elementDefinition.symbol }}</div>
    <div class="periodic-element__name">
      {{ $t(`elements.${elementDefinition.symbol}`) }}
    </div>
    <div class="periodic-element__color"></div>
  </div>
</template>

<script setup lang="ts">
import type { ElementSymbol } from 'src/types/ElementSymbol'
import periodicElements from 'src/constants/periodicElements'
import periodicElementProperties from 'src/constants/periodicElementProperties'
import { computed } from 'vue'
import { PeriodicElement } from 'src/types/PeriodicElement'
import { useElementSelection } from 'src/stores/useElementSelection'
import { useAutoDetect } from 'src/stores/useAutoDetect'

const props = defineProps<{
  element: ElementSymbol
  standalone?: boolean
  disable?: boolean
  dense?: boolean
}>()

const elementDefinition = computed<PeriodicElement>(() => {
  const definition = periodicElements.find(
    ({ symbol }) => symbol === props.element,
  )
  if (!definition) {
    throw Error(`Unknown element: ${props.element}`)
  }
  if (periodicElementProperties[props.element]) {
    definition.properties = periodicElementProperties[props.element]
  }

  return definition
})
const elementSelection = useElementSelection()
const autoDetect = useAutoDetect()
const isSelected = computed<boolean>(() => {
  return elementSelection.selected.includes(props.element)
})
const isExcluded = computed<boolean>(() => {
  return elementSelection.excluded.includes(props.element)
})
const isHighlighted = computed<boolean>(() => {
  return elementSelection.highlighted === props.element
})
const isDetected = computed<boolean>(() => {
  return autoDetect.detected.includes(props.element)
})
const isDisabled = computed<boolean>(() => {
  if (!elementDefinition.value.properties) {
    return true
  }

  return !Object.values(
    elementDefinition.value.properties.atomicProperties.xrayLines,
  ).some(({ energy }) => energy < 20)
})
</script>

<style scoped lang="scss">
.periodic-element {
  position: relative;
  display: flex;
  font-size: 100%;
  text-align: center;
  background-color: #23333f;
  color: white;

  &--standalone {
    display: inline-block;
    width: 65px;
    height: 65px;
    padding: 12px;
  }

  &--dense {
    width: 30px;
    height: 30px;
    padding: 0;

    .periodic-element__top-left,
    .periodic-element__name {
      display: none;
    }

    .periodic-element__symbol {
      font-size: 20px;
    }
  }

  &--selected {
    background-color: var(--main-button-back);

    .periodic-element__top-right {
      display: block;
    }
  }

  &--highlighted {
    outline: 1px solid white;
  }

  &--excluded {
    background-color: grey;

    .periodic-element__symbol,
    .periodic-element__name,
    .periodic-element__top-left,
    .periodic-element__top-right {
      z-index: 1;
    }

    .periodic-element__top-right {
      display: block;
    }
  }

  &--disable {
    color: grey;
  }

  &:hover:not(&--dense),
  &--standalone:not(&--dense) {
    .periodic-element__top-left,
    .periodic-element__name {
      display: block;
    }

    .periodic-element__top-right {
      top: -20px;
      right: -15px;
      font-size: 28px;
      -webkit-text-stroke-width: 7px;
    }

    .periodic-element__symbol {
      font-size: 25px;
      font-weight: bold;
    }
  }

  &--standalone:not(&--dense) {
    .periodic-element__color {
      display: none;
    }
  }

  &__top-right {
    display: none;
    top: -12px;
    right: -10px;
    padding: 0 2px 0 4px;
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    z-index: 1;

    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: black;
    paint-order: stroke fill;
  }

  &__top-left {
    font-weight: lighter;
    display: none;
    top: 0;
    left: 0;
    padding: 0 2px 0 4px;
    position: absolute;
    font-size: 14px;
  }

  &__name {
    display: none;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    padding: 0 2px;
    position: absolute;
    font-size: 10px;
    font-weight: bold;
  }

  &__symbol {
    font-size: 12px;
    align-self: center;
    width: 100%;
  }
}
</style>
