import ApolloManager from 'src/api/ApolloManager'
import gql from 'graphql-tag'
import { ZenObservable } from 'zen-observable-ts'

type SpectrumData = {
  spectrum: number[]
  inputCounts: number
  outputCounts: number
}
export default {
  async ping(url: string | null = null) {
    const result = await ApolloManager.getClient(url).query<{ ping: string }>({
      query: gql`
        query ping {
          ping
        }
      `,
      context: {
        dontDisconnect: true,
      },
    })
    return result.data?.ping
  },

  getSpectrumSubscription(
    onResult: (result: { spectrum: SpectrumData } | null | undefined) => void,
  ): ZenObservable.Subscription {
    return ApolloManager.subscription<{ spectrum: SpectrumData }>(
      gql`
        subscription spectrumSubscription {
          spectrum {
            inputCounts
            outputCounts
            spectrum
          }
        }
      `,
      onResult,
    )
  },
}
