import { SpectrumFile } from 'src/types/SpectrumFile'
import { EdxSpectrum } from 'src/types/EdxSpectrum'

export class MsaFile implements SpectrumFile {
  counts: number = 0
  title: string | undefined
  date: string | undefined
  liveTime: number | undefined
  realTime: number | undefined
  spectrum: EdxSpectrum | undefined

  parse(contents: string): void {
    const fields: Record<string, string> = contents
      .split('#')
      .filter((field) => field !== '')
      .reduce(
        (fields, fieldString) => {
          const splitPosition = fieldString.indexOf(':')
          const key = fieldString.substring(0, splitPosition).trim()
          const value = fieldString.substring(splitPosition + 1).trim()

          if (key !== 'SPECTRUM') {
            fields[key] = value.replace(/\r?\n|\r|\n/g, '')
          } else {
            fields[key] = value
          }
          return fields
        },
        {} as Record<string, string>,
      )

    if (!fields.SPECTRUM || !fields.DATATYPE) {
      throw Error('msa file does not contain valid spectrum information')
    }

    this.title = fields.SPECIMEN || 'unknown'
    this.date = 'unknown'
    this.counts = 0

    if (fields.DATE) {
      this.date = fields.DATE
      if (fields.TIME) {
        this.date += ` ${fields.TIME}`
      }
    }
    this.liveTime = fields.LIVETIME ? parseInt(fields.LIVETIME) : -1
    this.realTime = fields.REALTIME ? parseInt(fields.REALTIME) : -1
    const channels = fields.SPECTRUM.split(/\r?\n|\r|\n/g)
    if (channels.length === 0) {
      throw Error('No channels in msa file')
    }
    if (fields.NPOINTS && channels.length !== parseInt(fields.NPOINTS)) {
      console.warn(
        `Unexpected amount of channels: ${parseInt(fields.NPOINTS)} !== ${channels.length}`,
      )
    }

    if (fields.DATATYPE === 'Y') {
      const offset = parseFloat(fields.OFFSET)
      const xPerChannel = parseFloat(fields.XPERCHAN)
      if (isNaN(offset) || isNaN(xPerChannel)) {
        throw Error(
          'msa file does not contain the neccessary fields to interpret the spectrum',
        )
      }

      this.spectrum = channels.map((countString, index) => {
        const counts = parseInt(countString)
        if (isNaN(counts)) {
          throw Error(
            `Unable to parse spectrum data to integers: ${countString}`,
          )
        }
        const kev = offset + index * xPerChannel
        this.counts += counts
        return { kev, counts }
      })
    } else if (fields.DATATYPE === 'XY') {
      this.spectrum = channels.map((countString) => {
        const parts = countString.split(',')
        if (parts.length !== 2) {
          throw Error(`Spectrum is not of expected format: ${countString}`)
        }
        const kev = parseInt(parts[0])
        const counts = parseInt(parts[1])
        if (isNaN(kev) || isNaN(counts)) {
          throw Error(
            `Unable to parse spectrum data to integers: ${countString}`,
          )
        }
        this.counts += counts
        return { kev, counts }
      })
    }
  }
}
