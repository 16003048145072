import { PeriodicElement } from 'src/types/PeriodicElement'

export default [
  { number: 1, symbol: 'H', block: 'S' },
  { number: 2, symbol: 'He', block: 'S' },
  { number: 3, symbol: 'Li', block: 'S' },
  { number: 4, symbol: 'Be', block: 'S' },
  { number: 5, symbol: 'B', block: 'P' },
  { number: 6, symbol: 'C', block: 'P' },
  { number: 7, symbol: 'N', block: 'P' },
  { number: 8, symbol: 'O', block: 'P' },
  { number: 9, symbol: 'F', block: 'P' },
  { number: 10, symbol: 'Ne', block: 'P' },

  { number: 11, symbol: 'Na', block: 'S' },
  { number: 12, symbol: 'Mg', block: 'S' },
  { number: 13, symbol: 'Al', block: 'P' },
  { number: 14, symbol: 'Si', block: 'P' },
  { number: 15, symbol: 'P', block: 'P' },
  { number: 16, symbol: 'S', block: 'P' },
  { number: 17, symbol: 'Cl', block: 'P' },
  { number: 18, symbol: 'Ar', block: 'P' },

  { number: 19, symbol: 'K', block: 'S' },
  { number: 20, symbol: 'Ca', block: 'S' },
  { number: 21, symbol: 'Sc', block: 'D' },
  { number: 22, symbol: 'Ti', block: 'D' },
  { number: 23, symbol: 'V', block: 'D' },
  { number: 24, symbol: 'Cr', block: 'D' },
  { number: 25, symbol: 'Mn', block: 'D' },
  { number: 26, symbol: 'Fe', block: 'D' },
  { number: 27, symbol: 'Co', block: 'D' },
  { number: 28, symbol: 'Ni', block: 'D' },
  { number: 29, symbol: 'Cu', block: 'D' },
  { number: 30, symbol: 'Zn', block: 'D' },
  { number: 31, symbol: 'Ga', block: 'P' },
  { number: 32, symbol: 'Ge', block: 'P' },
  { number: 33, symbol: 'As', block: 'P' },
  { number: 34, symbol: 'Se', block: 'P' },
  { number: 35, symbol: 'Br', block: 'P' },
  { number: 36, symbol: 'Kr', block: 'P' },

  { number: 37, symbol: 'Rb', block: 'S' },
  { number: 38, symbol: 'Sr', block: 'S' },
  { number: 39, symbol: 'Y', block: 'D' },
  { number: 40, symbol: 'Zr', block: 'D' },
  { number: 41, symbol: 'Nb', block: 'D' },
  { number: 42, symbol: 'Mo', block: 'D' },
  { number: 43, symbol: 'Tc', block: 'D' },
  { number: 44, symbol: 'Ru', block: 'D' },
  { number: 45, symbol: 'Rh', block: 'D' },
  { number: 46, symbol: 'Pd', block: 'D' },
  { number: 47, symbol: 'Ag', block: 'D' },
  { number: 48, symbol: 'Cd', block: 'D' },
  { number: 49, symbol: 'In', block: 'P' },
  { number: 50, symbol: 'Sn', block: 'P' },
  { number: 51, symbol: 'Sb', block: 'P' },
  { number: 52, symbol: 'Te', block: 'P' },
  { number: 53, symbol: 'I', block: 'P' },
  { number: 54, symbol: 'Xe', block: 'P' },

  { number: 55, symbol: 'Cs', block: 'S' },
  { number: 56, symbol: 'Ba', block: 'S' },
  { number: 71, symbol: 'Lu', block: 'D' },
  { number: 72, symbol: 'Hf', block: 'D' },
  { number: 73, symbol: 'Ta', block: 'D' },
  { number: 74, symbol: 'W', block: 'D' },
  { number: 75, symbol: 'Re', block: 'D' },
  { number: 76, symbol: 'Os', block: 'D' },
  { number: 77, symbol: 'Ir', block: 'D' },
  { number: 78, symbol: 'Pt', block: 'D' },
  { number: 79, symbol: 'Au', block: 'D' },
  { number: 80, symbol: 'Hg', block: 'D' },
  { number: 81, symbol: 'Tl', block: 'P' },
  { number: 82, symbol: 'Pb', block: 'P' },
  { number: 83, symbol: 'Bi', block: 'P' },
  { number: 84, symbol: 'Po', block: 'P' },
  { number: 85, symbol: 'At', block: 'P' },
  { number: 86, symbol: 'Rn', block: 'P' },

  { number: 87, symbol: 'Fr', block: 'S' },
  { number: 88, symbol: 'Ra', block: 'S' },
  { number: 103, symbol: 'Lr', block: 'D' },
  { number: 104, symbol: 'Rf', block: 'D' },
  { number: 105, symbol: 'Db', block: 'D' },
  { number: 106, symbol: 'Sg', block: 'D' },
  { number: 107, symbol: 'Bh', block: 'D' },
  { number: 108, symbol: 'Hs', block: 'D' },
  { number: 109, symbol: 'Mt', block: 'D' },
  { number: 110, symbol: 'Ds', block: 'D' },
  { number: 111, symbol: 'Rg', block: 'D' },
  { number: 112, symbol: 'Cn', block: 'D' },
  { number: 113, symbol: 'Nh', block: 'P' },
  { number: 114, symbol: 'Fl', block: 'P' },
  { number: 115, symbol: 'Mc', block: 'P' },
  { number: 116, symbol: 'Lv', block: 'P' },
  { number: 117, symbol: 'Ts', block: 'P' },
  { number: 118, symbol: 'Og', block: 'P' },

  { number: 57, symbol: 'La', block: 'F' },
  { number: 58, symbol: 'Ce', block: 'F' },
  { number: 59, symbol: 'Pr', block: 'F' },
  { number: 60, symbol: 'Nd', block: 'F' },
  { number: 61, symbol: 'Pm', block: 'F' },
  { number: 62, symbol: 'Sm', block: 'F' },
  { number: 63, symbol: 'Eu', block: 'F' },
  { number: 64, symbol: 'Gd', block: 'F' },
  { number: 65, symbol: 'Tb', block: 'F' },
  { number: 66, symbol: 'Dy', block: 'F' },
  { number: 67, symbol: 'Ho', block: 'F' },
  { number: 68, symbol: 'Er', block: 'F' },
  { number: 69, symbol: 'Tm', block: 'F' },
  { number: 70, symbol: 'Yb', block: 'F' },

  { number: 89, symbol: 'Ac', block: 'F' },
  { number: 90, symbol: 'Th', block: 'F' },
  { number: 91, symbol: 'Pa', block: 'F' },
  { number: 92, symbol: 'U', block: 'F' },
  { number: 93, symbol: 'Np', block: 'F' },
  { number: 94, symbol: 'Pu', block: 'F' },
  { number: 95, symbol: 'Am', block: 'F' },
  { number: 96, symbol: 'Cm', block: 'F' },
  { number: 97, symbol: 'Bk', block: 'F' },
  { number: 98, symbol: 'Cf', block: 'F' },
  { number: 99, symbol: 'Es', block: 'F' },
  { number: 100, symbol: 'Fm', block: 'F' },
  { number: 101, symbol: 'Md', block: 'F' },
  { number: 102, symbol: 'No', block: 'F' },
] as PeriodicElement[]
