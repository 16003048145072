<template>
  <q-bar class="row">
    <div class="col">
      <small class="q-mx-sm text-white">Counts: {{ position.counts }}</small>
    </div>
    <div class="col">
      <small class="q-mx-sm text-white"
        >Voltage: {{ (position.kev / 1000).toFixed(1) }} kev</small
      >
    </div>
    <q-space></q-space>
    <q-btn dense flat square @click="emit('update:position', null)">
      <i class="ri-close-line ri-xl text-white"></i>
    </q-btn>
  </q-bar>
  <div
    class="inspected-position"
    :class="{ 'inspected-position--tiles': !inspectionInRows }"
  >
    <div
      v-for="element in possibleElements"
      :key="element"
      class="inspected-position__match"
      :class="{
        [`inspected-position__match--selected`]:
          elementSelection.selected.includes(element),
      }"
      :style="`${
        selectedColorOverwrites[element]
          ? `--selected-color: ${selectedColorOverwrites[element]}`
          : ''
      }`"
      @click.stop="elementSelection.toggleHighlighted(element)"
      @dblclick.stop="elementSelection.toggleSelected(element)"
    >
      <div
        class="inspected-position__match__element"
        :style="`height: ${matchElementHeight}px`"
      >
        <PeriodicElement :element="element" />
      </div>
      <div class="inspected-position__match__plot">
        <SpectrumPlot
          :cursor="position"
          :fixed-bounds="positionBounds"
          :spectra="[baselineSpectrum]"
          :spectrum-line-types="[PlotType.AreaLine]"
          :visible-element-areas="[element]"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { convertCountsToWeight } from 'src/lib/convertKLMWeights'
import periodicElementProperties from 'src/constants/periodicElementProperties'
import periodicElements from 'src/constants/periodicElements'
import SpectrumPlot from 'src/components/SpectrumPlot.vue'
import { EdxSpectrumPosition } from 'src/types/EdxSpectrumPosition'
import { ElementSymbol } from 'src/types/ElementSymbol'
import { PeriodicElementProperties } from 'src/types/PeriodicElement'
import { PlotType } from 'src/types/PlotType'
import PreferencesManager from 'src/classes/PreferencesManager'
import { useElementSelection } from 'src/stores/useElementSelection'
import PeriodicElement from 'src/components/PeriodicElement.vue'
import { NamedEdxSpectrum } from 'src/types/NamedEdxSpectrum'

const props = withDefaults(
  defineProps<{
    baselineSpectrum: NamedEdxSpectrum
    splitterHeight: number
    kevTolerance?: number
    weightTolerance?: number
    elementCurves?: boolean
    position: EdxSpectrumPosition
    selectedColorOverwrites?: Record<string, string>
  }>(),
  {
    kevTolerance: 500,
    weightTolerance: 0.1,
    selectedColorOverwrites: () => ({}),
  },
)

const emit = defineEmits<{
  (e: 'update:position', position: EdxSpectrumPosition | null): void
}>()

const inspectionInRows = PreferencesManager.get<boolean>(
  'inspectionInRows',
  false,
)
const elementSelection = useElementSelection()
const lookupWeight = ref(0)
const maxCounts = computed<number>(() =>
  Math.max(...props.baselineSpectrum.spectrum.map((point) => point.counts)),
)
const minLineWeight = PreferencesManager.get<number>('minLineWeight', 0)
const possibleElements = computed<ElementSymbol[]>(() => {
  const kvBounds = [
    (props.position.kev - props.kevTolerance) / 1000,
    (props.position.kev + props.kevTolerance) / 1000,
  ]
  const weightBounds = [
    lookupWeight.value - props.weightTolerance,
    lookupWeight.value + props.weightTolerance,
  ]
  return periodicElements
    .filter((element) => periodicElementProperties[element.symbol])
    .map((element) => {
      const properties = periodicElementProperties[
        element.symbol
      ] as PeriodicElementProperties
      const lines = Object.values(properties.atomicProperties.xrayLines)
      const matches = lines
        .filter(
          (line) =>
            line.energy > kvBounds[0] &&
            line.energy < kvBounds[1] &&
            line.weight > minLineWeight.value &&
            line.weight > weightBounds[0] &&
            line.weight < weightBounds[1],
        )
        .map((line) => ({
          closeness:
            Math.abs(line.energy - props.position.kev) *
            Math.abs(line.weight - lookupWeight.value),
        }))
        .sort((lineA, lineB) => lineA.closeness - lineB.closeness)
      return {
        match: matches[0],
        symbol: element.symbol,
      }
    })
    .filter((element) => !!element.match)
    .sort(
      (elementA, elementB) =>
        elementA.match.closeness - elementB.match.closeness,
    )
    .map(({ symbol }) => symbol)
})
const positionBounds = computed(() => {
  return {
    minX: props.position.kev - 500,
    maxX: props.position.kev + 500,
    minY: 0,
    maxY: spectrumPosition.value.counts,
  }
})
const spectrumPosition = computed<EdxSpectrumPosition>(() => {
  return props.baselineSpectrum.spectrum.reduce(
    (closest, spectrumPosition) => {
      if (
        Math.abs(props.position.kev - spectrumPosition.kev) <
        Math.abs(props.position.kev - closest.kev)
      ) {
        return spectrumPosition
      }
      return closest
    },
    { kev: Number.NEGATIVE_INFINITY, counts: 0 },
  )
})
watch(
  () => props.position,
  ({ kev }) => {
    const position = props.baselineSpectrum.spectrum.reduce(
      (closest, spectrumPosition) => {
        if (
          Math.abs(kev - spectrumPosition.kev) < Math.abs(kev - closest.kev)
        ) {
          return spectrumPosition
        }
        return closest
      },
      { kev: Number.NEGATIVE_INFINITY, counts: 0 },
    )
    lookupWeight.value = convertCountsToWeight(
      maxCounts.value,
      position.counts,
      position.kev,
    )
  },
  { immediate: true },
)

const matchElementHeight = computed<number>(() => {
  const barSize = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--window-bar-height')
      .replaceAll(/\D/g, ''),
  )
  const splitterHeightPx =
    ((window.innerHeight - barSize) / 100) * (100 - props.splitterHeight) - 1
  return (splitterHeightPx - 9 * 6.5) / 9.5
})
</script>

<style lang="scss">
.inspected-position {
  display: grid;
  overflow-y: auto;
  max-height: calc(100% - 32px);

  &--tiles {
    grid-template-columns: 1fr 1fr;
  }
}

.inspected-position__match {
  position: relative;
  height: 120px;
  width: calc(100% - 12px);

  &__element {
    position: absolute;
    left: 6px;
    top: 3px;
    z-index: 1;
    text-align: center;

    .periodic-element {
      height: 100%;
      aspect-ratio: 1/1;
      width: auto;
      max-width: calc((58.3333vw - 102px) / 18);
      pointer-events: none;
    }
  }

  &__plot {
    height: 100%;
    pointer-events: none;
  }

  &__toggle {
    position: absolute;
    top: 0;
    bottom: 45px;
    right: 0;
    width: 20px;
    box-sizing: content-box;
    display: flex;
    background-color: var(--main-button-back);
    align-items: center;
    justify-content: center;
    color: black;
    border-left: 1px solid var(--bg-base);
  }

  &--selected {
    .inspected-position__match__toggle {
      background-color: buttonface;
    }
  }
}
</style>
