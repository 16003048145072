<template>
  <div class="q-mb-sm text-h6 text-center">
    {{ spectrumFile.loadedSource?.title ?? '' }}
  </div>
  <div class="navigation_tools">
    <SemImageNavigator v-if="semImageShown" />
    <StubImageNavigator v-else />
    <q-btn color="primary" dense round @click="switchDisplay">
      <i class="ri-token-swap-line" :class="{ 'rotate-180': !semImageShown }" />
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import SemImageNavigator from './navigation/ImageNavigator.vue'
import StubImageNavigator from './navigation/StubNavigator.vue'
import { ref, computed } from 'vue'
import { useSpectrumSource } from 'src/stores/useSpectrumSource'

const spectrumFile = useSpectrumSource()
const displaySate = ref<'semImage' | 'stubImage'>('semImage')
const semImageShown = computed<boolean>(() => {
  return displaySate.value === 'semImage'
})
const switchDisplay = (): void => {
  if (semImageShown.value) {
    displaySate.value = 'stubImage'
  } else {
    displaySate.value = 'semImage'
  }
}
</script>

<style lang="scss">
.navigation_tools {
  position: relative;
  height: 256px;
  margin-right: 8px;

  .q-btn {
    position: absolute;
    bottom: 8px;
    left: 10px;
    margin-bottom: -14px;
    z-index: 9999;
  }
}
</style>
