import { SpectrumFile } from 'src/types/SpectrumFile'
import { EdxSpectrum } from 'src/types/EdxSpectrum'

const TITLE_LINE = 0
const DATE_LINE = 2
const LIFETIME_LINE = 3
const REALTIME_LINE = 4

export class MsedsFile implements SpectrumFile {
  title: string | undefined
  date: string | undefined
  liveTime: number | undefined
  realTime: number | undefined
  counts: number = 0
  spectrum: EdxSpectrum | undefined

  parse(contents: string): void {
    const lines = contents.split(/\r?\n|\r|\n/g)
    this.title = this.removeQuotes(lines[TITLE_LINE])
    this.date = this.unwrapValue(lines[DATE_LINE])
    this.liveTime = this.unwrapNumberValue(lines[LIFETIME_LINE])
    this.realTime = this.unwrapNumberValue(lines[REALTIME_LINE])
    this.counts = 0

    this.spectrum = lines
      .slice(9)
      .filter((line) => !!line)
      .map((line) => {
        const parts = line.split(',')
        if (parts.length !== 2) {
          throw Error(`Spectrum is not of expected format: ${line}`)
        }
        const kev = parseInt(parts[0])
        const counts = parseInt(parts[1])
        if (isNaN(kev) || isNaN(counts)) {
          throw Error(`Unable to parse spectrum data to integers: ${line}`)
        }

        this.counts += counts
        return { kev, counts }
      })
  }

  unwrapValue(line: string | undefined): string {
    if (line === undefined) {
      throw Error('File does not contain expected line')
    }
    line = line.toLowerCase()
    const splitPosition = line.indexOf(',')
    if (splitPosition === -1) {
      throw Error(`Line does not contain expected information: ${line}`)
    }
    const key = this.removeQuotes(line.substring(0, splitPosition))
    const value = this.removeQuotes(line.substring(splitPosition + 1))

    return value.replace(`${key} = `, '')
  }

  unwrapNumberValue(line: string | undefined): number {
    const value = this.unwrapValue(line)
    const floatValue = parseFloat(value.replace(',', '.'))

    if (isNaN(floatValue)) {
      throw Error(`Unable to parse data in file to number: ${line}`)
    }
    return floatValue
  }

  removeQuotes(string: string | undefined): string {
    if (string === undefined) {
      throw Error('File does not contain expected line')
    }
    return string.replaceAll('"', '')
  }
}
