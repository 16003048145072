<template>
  <div v-if="currentImage" class="image-navigator">
    <img class="image-navigator__image" :src="currentImage" />

    <svg
      v-if="spectra.currentSpectrum"
      class="image-navigator__svg"
      viewbox="0 0 100 100"
    >
      <g
        v-for="position in spectrumPositions"
        :key="position.id"
        @click="spectra.selectSpectrum(position.id)"
      >
        <g
          v-if="position.type === 'point'"
          class="image-navigator__svg__point"
          :class="{
            ['image-navigator__svg__point--selected']:
              spectra.currentSpectrum.id === position.id,
          }"
        >
          <circle
            :cx="`${position.coordinates[0].x}%`"
            :cy="`${position.coordinates[0].y}%`"
            :r="3"
          />
          <line
            :x1="`${position.coordinates[0].x + 1}%`"
            :x2="`${position.coordinates[0].x + 6}%`"
            :y1="`${position.coordinates[0].y}%`"
            :y2="`${position.coordinates[0].y}%`"
          />
          <line
            :x1="`${position.coordinates[0].x - 1}%`"
            :x2="`${position.coordinates[0].x - 6}%`"
            :y1="`${position.coordinates[0].y}%`"
            :y2="`${position.coordinates[0].y}%`"
          />
          <line
            :x1="`${position.coordinates[0].x}%`"
            :x2="`${position.coordinates[0].x}%`"
            :y1="`${position.coordinates[0].y + 1}%`"
            :y2="`${position.coordinates[0].y + 6}%`"
          />
          <line
            :x1="`${position.coordinates[0].x}%`"
            :x2="`${position.coordinates[0].x}%`"
            :y1="`${position.coordinates[0].y - 1}%`"
            :y2="`${position.coordinates[0].y - 6}%`"
          />
        </g>
        <g
          v-else-if="position.type === 'area'"
          class="image-navigator__svg__area"
          :class="{
            ['image-navigator__svg__area--selected']:
              spectra.currentSpectrum.id === position.id,
          }"
        >
          <rect
            :height="`${Math.abs(position.coordinates[0].y - position.coordinates[1].y)}%`"
            :width="`${Math.abs(position.coordinates[0].x - position.coordinates[1].x)}%`"
            :x="`${Math.min(position.coordinates[0].x, position.coordinates[1].x)}%`"
            :y="`${Math.min(position.coordinates[0].y, position.coordinates[1].y)}%`"
          />
        </g>
        <g
          v-else-if="position.type === 'line'"
          class="image-navigator__svg__line"
          :class="{
            ['image-navigator__svg__line--selected']:
              spectra.currentSpectrum.id === position.id,
          }"
        >
          <line
            :x1="`${position.coordinates[0].x}%`"
            :x2="`${position.coordinates[1].x}%`"
            :y1="`${position.coordinates[0].y}%`"
            :y2="`${position.coordinates[1].y}%`"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { useSpectra } from 'src/stores/useSpectra'
import { computed } from 'vue'

const spectra = useSpectra()
const currentImage = computed<string | null>(() => {
  if (!spectra.currentSpectrum) {
    return null
  }
  return spectra.currentSpectrum.capture.image
})
const spectrumPositions = computed<
  {
    type: 'area' | 'line' | 'point'
    coordinates: { x: number; y: number }[]
    id: number | string
  }[]
>(() => {
  if (!currentImage.value) {
    return []
  }

  return spectra.allSpectra
    .filter(({ capture }) => capture.image === currentImage.value)
    .map((spectrum) => {
      return {
        id: spectrum.id,
        type: spectrum.type,
        coordinates: spectrum.captureCoordinates,
      }
    })
})
</script>

<style scoped lang="scss">
.image-navigator {
  width: 100%;
  padding: 0 10px;
  position: relative;

  &__image {
    width: 100%;
    height: auto;
  }

  &__svg {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    left: 10px;
    aspect-ratio: 1/1;

    &__area {
      fill: none;
      stroke: white;
      stroke-width: 1%;

      &--selected {
        stroke: var(--main-button-back);
      }
    }

    &__line {
      stroke: white;
      stroke-width: 1%;

      &--selected {
        stroke: var(--main-button-back);
      }
    }

    &__point {
      stroke: white;
      stroke-width: 1%;
      fill: none;

      &--selected {
        stroke: var(--main-button-back);
      }
    }
  }
}
</style>
