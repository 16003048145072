export default class Singleton {
  constructor(
    classObject: { new (): any; [key: string]: any },
    constructor?: (instance: any) => void,
  ) {
    const instanceKey = `_${classObject.name}singletonInstance`
    if (classObject[instanceKey]) {
      return classObject[instanceKey]
    }
    classObject[instanceKey] = this

    if (constructor) {
      constructor(this)
    }
  }
}
