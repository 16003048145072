import { defineStore } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { NamedEdxSpectrum } from 'src/types/NamedEdxSpectrum'
import { useSpectrumSource } from 'src/stores/useSpectrumSource'
import { SpectrumFileType } from 'src/types/SpectrumFileType'

export const useSpectra = defineStore('spectra', () => {
  const spectrumFile = useSpectrumSource()
  const currentIndex = ref<number>(0)
  const currentSpectrum = computed<NamedEdxSpectrum | null>(() => {
    if (!allSpectra.value[currentIndex.value]) {
      return null
    }
    return allSpectra.value[currentIndex.value]
  })

  const extraSpectra = ref<NamedEdxSpectrum[]>([])

  const allSpectra = computed<NamedEdxSpectrum[]>(() => {
    if (spectrumFile.loadedSource?.spectrum) {
      return [
        {
          name: spectrumFile.loadedSource.title ?? 'File',
          spectrum: spectrumFile.loadedSource.spectrum,
          type: 'point',
          captureCoordinates: [{ x: 50, y: 80 }],
          stubCoordinates: [{ x: 84, y: 50 }],
          capture: {
            image: '/sem.jpg',
            name: 'Weave',
          },
          id: 1,
        } as NamedEdxSpectrum,
        ...extraSpectra.value,
      ]
    }
    return []
  })

  onMounted(async () => {
    const file1 = await spectrumFile.readPublicFileContents(
      '/spectra/ss316.msa',
      SpectrumFileType.MSA,
    )
    if (file1.spectrum) {
      extraSpectra.value.push({
        name: file1.title ?? 'File 2',
        spectrum: file1.spectrum,
        type: 'area',
        captureCoordinates: [
          { x: 63, y: 67 },
          { x: 83, y: 83 },
        ],
        stubCoordinates: [
          { x: 28, y: 26 },
          { x: 33, y: 32 },
        ],
        capture: {
          image: '/sem2.png',
          name: 'Deposit',
        },
        id: 2,
      })
    }
    const file2 = await spectrumFile.readPublicFileContents(
      '/spectra/sn_60_pb_40_20kv.msa',
      SpectrumFileType.MSA,
    )
    if (file2.spectrum) {
      extraSpectra.value.push({
        name: file2.title ?? 'File 2',
        spectrum: file2.spectrum,
        type: 'point',
        captureCoordinates: [{ x: 48, y: 75 }],
        stubCoordinates: [{ x: 84, y: 50 }],
        capture: {
          image: '/sem.jpg',
          name: 'Weave',
        },
        id: 3,
      })
    }
  })

  const selectSpectrum = (id: number | string): void => {
    const index = allSpectra.value.findIndex((spectrum) => spectrum.id === id)
    if (index === -1) {
      throw Error('Unknown spectrum')
    }
    comparedSpectraIds.value = []
    currentIndex.value = index
  }

  type SpectraGroup = {
    image: string
    name: string
    spectra: NamedEdxSpectrum[]
  }
  const groupedSpectra = computed<SpectraGroup[]>(() => {
    return allSpectra.value.reduce((groups, spectrum) => {
      const index = groups.findIndex(
        ({ image }) => spectrum.capture.image === image,
      )
      if (index === -1) {
        groups.push({
          image: spectrum.capture.image,
          name: spectrum.capture.name,
          spectra: [spectrum],
        })
      } else {
        groups[index].spectra.push(spectrum)
      }
      return groups
    }, [] as SpectraGroup[])
  })

  const comparedSpectraIds = ref<(number | string)[]>([])
  const comparedSpectra = computed<NamedEdxSpectrum[]>(() => {
    return comparedSpectraIds.value
      .map((id): NamedEdxSpectrum | undefined =>
        allSpectra.value.find((namedSpectrum) => namedSpectrum.id === id),
      )
      .filter((spectrum) => spectrum !== undefined) as NamedEdxSpectrum[]
  })
  const toggleCompare = (id: number | string): void => {
    if (comparedSpectraIds.value.includes(id)) {
      comparedSpectraIds.value = comparedSpectraIds.value.filter(
        (comparedId) => comparedId !== id,
      )
    } else {
      comparedSpectraIds.value.push(id)
    }
  }

  return {
    currentSpectrum,
    allSpectra,
    groupedSpectra,
    comparedSpectraIds,
    comparedSpectra,
    selectSpectrum,
    toggleCompare,
  }
})
