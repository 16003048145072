<template>
  <div :key="PreferencesManager.redrawKey.value" class="spectrum-plot">
    <Chart
      v-if="chartVisible"
      ref="chartRef"
      class="spectrum-plot__chart plot"
      :options="chartOptions.static"
      @wheel.prevent="wheelZoom"
    />
    <div class="spectrum-plot__controls">
      <q-btn
        v-if="!fixedBounds && chartZoomedIn"
        class="spectrum-plot__reset-button"
        no-caps
        outline
        square
        @click="resetView"
        >Reset
      </q-btn>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart } from 'highcharts-vue'
import { computed, onMounted, ref, toRef, watch, watchEffect } from 'vue'
import type {
  Chart as HighChart,
  XAxisPlotLinesOptions,
  PointerEventObject,
} from 'highcharts'
import type { EdxSpectrumPosition } from 'src/types/EdxSpectrumPosition'
import type { ElementSymbol } from 'src/types/ElementSymbol'
import type { PeriodicElementProperties } from 'src/types/PeriodicElement'
import { PlotType } from 'src/types/PlotType'
import { XRayLineDefinition } from 'src/types/PeriodicElement'
import { useHighchartOptionsForPlot } from 'src/composables/useHighchartOptionsForPlot'
import periodicElementProperties from 'src/constants/periodicElementProperties'
import PreferencesManager from 'src/classes/PreferencesManager'
import { promiseTimeout } from '@vueuse/core'
import { KMLDisplayMode, ZoomMode } from 'src/types/Preferences'
import { XRayLine } from 'src/types/XRayLine'
import { clamp } from 'lodash'
import { Series, SeriesOptionsType } from 'highcharts'
import { generateElementXraySeries } from 'src/lib/generateElementXraySeries'
import { useElementSelection } from 'src/stores/useElementSelection'
import { NamedEdxSpectrum } from 'src/types/NamedEdxSpectrum'
import { getNearestPoint } from 'src/lib/nearestPoint'

const props = withDefaults(
  defineProps<{
    spectra: NamedEdxSpectrum[]
    cursor?: EdxSpectrumPosition | null
    visibleElementLines?: ElementSymbol[]
    visibleElementAreas?: ElementSymbol[]
    spectrumLineTypes: PlotType[]
    fixedBounds?: {
      minX: number
      maxX: number
      minY: number
      maxY: number
    } | null
  }>(),
  {
    visibleElementLines: () => [],
    visibleElementAreas: () => [],
    cursor: null,
    fixedBounds: null,
    spectra: undefined,
  },
)
const emit = defineEmits<{
  (e: 'update:cursor', position: EdxSpectrumPosition | null): void
}>()

const highchartOptionsForPlot = useHighchartOptionsForPlot()
const chartRef = ref<typeof Chart>()
const chartVisible = ref<boolean>(true)
const chartZoomedIn = ref<boolean>(false)
const zoomKey = ref<number>(0)
const chartReady = ref<boolean>(false)

const minLineWeight = PreferencesManager.get<number>('minLineWeight', 0)

const xrayLines = computed<XRayLineDefinition[]>(() => {
  type DefinitionWithSymbol = PeriodicElementProperties & {
    symbol: ElementSymbol
  }

  const elementDefinitions: DefinitionWithSymbol[] = props.visibleElementLines
    .map((symbol: ElementSymbol): DefinitionWithSymbol | undefined => {
      if (!periodicElementProperties[symbol]) {
        return undefined
      }
      return {
        ...periodicElementProperties[symbol],
        symbol,
      } as DefinitionWithSymbol
    })
    .filter(
      (definition: DefinitionWithSymbol | undefined) =>
        definition !== undefined,
    ) as DefinitionWithSymbol[]

  const xrayLines: XRayLineDefinition[] = elementDefinitions.reduce(
    (lines, definition: DefinitionWithSymbol) => {
      Object.keys(definition.atomicProperties.xrayLines)
        .filter((key: string) => {
          const xrayLine = key as unknown as XRayLine
          const lineDefinition = definition.atomicProperties.xrayLines[xrayLine]
          return lineDefinition && lineDefinition.weight >= minLineWeight.value
        })
        .forEach((key: string) => {
          const xrayLine = key as unknown as XRayLine
          const lineDefinition = definition.atomicProperties.xrayLines[xrayLine]
          if (!lineDefinition) {
            return
          }

          lines.push({
            element: definition.symbol,
            lineType: xrayLine,
            weight: lineDefinition.weight,
            energy: lineDefinition.energy,
          })
        })
      return lines
    },
    [] as XRayLineDefinition[],
  )

  return xrayLines.sort((lineA, lineB) => lineA.energy - lineB.energy)
})

const kmlDisplayMode = PreferencesManager.get<KMLDisplayMode>(
  'kmlDisplayMode',
  KMLDisplayMode.marker,
)
const elementSelection = useElementSelection()
const plotLines = computed<XAxisPlotLinesOptions[]>(() => {
  const lines: XAxisPlotLinesOptions[] = []
  if (!chartReady.value) {
    return lines
  }
  if (props.cursor) {
    lines.push({
      className: 'cursor-line',
      value: props.cursor.kev,
      zIndex: 3,
    })
  }
  if (kmlDisplayMode.value === KMLDisplayMode.line) {
    let lastY = 16
    let lastKev = -1
    return xrayLines.value.reduce((plotLines, xRayLine: XRayLineDefinition) => {
      let y = 16
      if (xRayLine.energy - lastKev < 0.5) {
        y = lastY + 16
      }
      lastKev = xRayLine.energy
      lastY = y
      plotLines.push({
        className: 'element-line',
        value: xRayLine.energy * 1000,
        label: {
          text: `<span class="element-line__label">${xRayLine.element} - ${xRayLine.lineType}</span>`,
          verticalAlign: 'top',
          textAlign: 'left',
          rotation: 0,
          useHTML: true,
          x: 0,
          y,
        },
      })
      return plotLines
    }, lines)
  } else {
    if (!chartRef.value) {
      return lines
    }
    const { chart } = chartRef.value as unknown as { chart: HighChart }
    const series = chart.series[0]
    if (!series) {
      return lines
    }

    return xrayLines.value.reduce((plotLines, xRayLine: XRayLineDefinition) => {
      const value = xRayLine.energy * 1000
      const nearestPoint = getNearestPoint(series, value)
      if (nearestPoint?.plotY) {
        let labelClass = 'element-marker__label'
        let innerLabel = `<div class="element-marker__label__element">${xRayLine.element}</div>`
        if (
          kmlDisplayMode.value === KMLDisplayMode.marker ||
          elementSelection.highlighted !== null
        ) {
          innerLabel += `<div class="element-marker__label__line-name">${xRayLine.lineType}</div>`
          if (kmlDisplayMode.value === KMLDisplayMode.both) {
            labelClass += ' element-marker__label--with-line'
          }
        }

        plotLines.push({
          className: 'element-marker__line',
          value,
          label: {
            text: `<div class="${labelClass}" data-zoom="${zoomKey.value}">${innerLabel}</div>`,
            verticalAlign: 'top',
            textAlign: 'center',
            rotation: 0,
            useHTML: true,
            x: 0,
            y: nearestPoint.plotY - 25,
          },
        })
      }
      return plotLines
    }, lines)
  }
})

const elementXrayAreaSeries = computed<SeriesOptionsType[]>(() => {
  const data = generateElementXraySeries(props.visibleElementAreas)
  return [
    {
      id: 'areaspline-xray-series',
      className: 'line--xray',
      type: 'areaspline',
      data,
      yAxis: 'elementAreas',
    },
  ]
})

const dataExtremes = computed<
  | {
      xMin: number
      xMax: number
      yMin: number
      yMax: number
    }[]
  | null
>(() => {
  if (!props.spectra) {
    return null
  }
  return props.spectra.map(({ spectrum }) => {
    let xMin = Number.MAX_SAFE_INTEGER
    let xMax = -1
    let yMin = Number.MAX_SAFE_INTEGER
    let yMax = -1
    spectrum.forEach(({ kev, counts }) => {
      if (kev > xMax) {
        xMax = kev
      }
      if (kev < xMin) {
        xMin = kev
      }
      if (counts > yMax) {
        yMax = counts
      }
      if (counts < yMin) {
        yMin = counts
      }
    })
    return { xMin, xMax, yMin, yMax }
  })
})

const zoomMode = PreferencesManager.get<ZoomMode>('zoomMode', ZoomMode.box)
const wheelZoom = (e: WheelEvent) => {
  if (!chartRef.value || zoomMode.value === ZoomMode.box) {
    return
  }
  if (!dataExtremes.value) {
    return
  }
  const { chart } = chartRef.value as unknown as { chart: HighChart }
  const xAxis = chart.xAxis[0]

  chart.yAxis.forEach((yAxis, index) => {
    if (!dataExtremes.value) {
      return
    }
    const extremes = dataExtremes.value[index]
    if (!extremes) {
      return
    }

    const { min: xMin, max: xMax } = xAxis.getExtremes()
    const { min: yMin, max: yMax } = yAxis.getExtremes()
    const xFactor = (xMax - xMin) * (e.deltaY > 0 ? 1.11 : 0.9)
    const yFactor = (yMax - yMin) * (e.deltaY > 0 ? 1.25 : 0.8)
    const { chartX: mouseX, chartY: mouseY } = chart.pointer.normalize(e)

    let newXMin = clamp(
      xAxis.toValue(mouseX) - (mouseX / chart.plotWidth) * xFactor,
      extremes.xMin,
      extremes.xMax,
    )
    let newXMax = clamp(
      xAxis.toValue(mouseX) +
        ((chart.plotWidth - mouseX) / chart.plotWidth) * xFactor,
      extremes.xMin,
      extremes.xMax,
    )
    let newYMin = clamp(
      yAxis.toValue(mouseY) -
        ((chart.plotHeight - mouseY) / chart.plotHeight) * yFactor,
      extremes.yMin,
      extremes.yMax,
    )
    let newYMax = clamp(
      yAxis.toValue(mouseY) + (mouseY / chart.plotHeight) * yFactor,
      extremes.yMin,
      extremes.yMax,
    )
    if (
      e.altKey ||
      (mouseY < chart.plotHeight + chart.plotTop && !e.shiftKey)
    ) {
      yAxis.setExtremes(newYMin, newYMax)
    }
    if (!e.altKey) {
      xAxis.setExtremes(newXMin, newXMax)
    }
    if (
      newXMin !== extremes.xMin ||
      newXMax !== extremes.xMax ||
      newYMin !== extremes.yMin ||
      newYMax !== extremes.yMax
    ) {
      chartZoomedIn.value = true
    } else {
      chartZoomedIn.value = false
    }
  })
}
const resetView = () => {
  if (!chartRef.value) {
    return
  }
  const { chart } = chartRef.value as unknown as { chart: HighChart }
  chart.xAxis[0].setExtremes()
  chart.yAxis.forEach((yAxis) => yAxis.setExtremes())
  chartZoomedIn.value = false
}

const chartOptions = highchartOptionsForPlot.spectrumPlotOptions(
  toRef(props, 'spectra'),
  toRef(props, 'spectrumLineTypes'),
  elementXrayAreaSeries,
  plotLines,
  toRef(props, 'fixedBounds'),
  (event: PointerEventObject): void => {
    if (!chartRef.value) {
      return
    }
    const { chart } = chartRef.value as unknown as { chart: HighChart }
    const xAxis = chart.xAxis[0]
    const mainSeries = chart.series.find((series) => {
      if (!series.userOptions.id) {
        return false
      }
      return (
        !series.userOptions.id.includes('xray') &&
        !series.userOptions.id.includes('spline')
      )
    })
    if (!mainSeries) {
      return
    }
    const kev = xAxis.toValue(event.chartX)
    const nearestPoint = getNearestPoint(mainSeries, kev)
    if (!nearestPoint?.x || !nearestPoint?.y) {
      return
    }
    emit('update:cursor', {
      kev: nearestPoint.x,
      counts: nearestPoint.y,
    })
  },
  (event): void => {
    chartZoomedIn.value = event.min !== undefined && event.max !== undefined
    zoomKey.value++
    promiseTimeout(500).then(() => zoomKey.value++)
  },
)

watchEffect(() => {
  if (!chartRef.value) {
    return
  }
  const { chart } = chartRef.value as unknown as { chart: HighChart }
  chart.update(chartOptions.dynamic.value)
})

watchEffect(() => {
  if (!chartRef.value) {
    return
  }
  const { chart } = chartRef.value as unknown as { chart: HighChart }

  const activeSeries: (number | string)[] = []
  chartOptions.series.value.forEach((series) => {
    if (!series.id) {
      throw Error(`Series without id: ${JSON.stringify(series)}`)
    }
    activeSeries.push(series.id)
    const existingSeries = chart.get(series.id) as undefined | Series
    if (existingSeries) {
      const { data } = series as unknown as Series
      existingSeries.setData(data)
    } else {
      if (series.yAxis && !chart.get(series.yAxis as string)) {
        if (!Array.isArray(chartOptions.static.yAxis)) {
          throw 'No yAxis definition'
        }
        const baseAxis = chartOptions.static.yAxis.find(
          ({ id }) => id === 'spectrum-0',
        )
        if (!baseAxis) {
          throw 'Unable to find base axis'
        }
        chart.addAxis({
          ...baseAxis,
          events: {},
          id: series.yAxis as string,
        })
      }
      chart.addSeries(series)
    }
  })
  chart.series.forEach((series) => {
    if (!series.options.id || !activeSeries.includes(series.options.id)) {
      series.remove()
    }
  })
})

watch(PreferencesManager.redrawKey, async () => {
  chartVisible.value = false
  await promiseTimeout(300)
  chartVisible.value = true
})
watch(
  () => props.spectra,
  async () => {
    await promiseTimeout(300)
    zoomKey.value++
  },
)

onMounted(async (): Promise<void> => {
  await promiseTimeout(300)
  chartReady.value = true
})
</script>
<style lang="scss">
.spectrum-plot {
  position: relative;
  height: 100%;

  & &__controls {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    z-index: 10;
    width: fit-content;
    display: flex;
    gap: 5px;

    .q-btn {
      background-color: var(--base-color) !important;
      height: 36px;
    }
  }

  & &__chart {
    height: 100%;
    width: 100%;

    .highcharts {
      &-series {
        &.line--xray {
          path {
            stroke: none;
            fill: deeppink;
          }
        }

        &.line--spectrum {
          > path {
            stroke: white;
            stroke-width: 2px;
          }
        }

        &.spline--spectrum {
          > path {
            stroke: white;
            stroke-width: 2px;
          }
        }

        &.areaspline--spectrum {
          > path {
            stroke: none;
          }

          .highcharts-area {
            fill: var(--main-button-back);
          }
        }

        &.area--spectrum {
          > path {
            stroke: none;
          }

          .highcharts-area {
            fill: var(--main-button-back);
          }
        }
      }

      &-annotation-label {
        path {
          stroke: none;
        }
      }
    }

    .element-line {
      stroke: var(--text-color);

      &__label {
        padding: 0 2px;
        color: black;
        background: white;
        font-size: 13px;
      }
    }

    .crosshair-line {
      pointer-events: none;
      stroke: var(--text-color);
    }

    .element-marker__line {
      stroke: none;
    }

    .highcharts-plot-line-label:has(.element-marker__label) {
      overflow: visible !important;

      .element-marker__label {
        display: block;
        color: white;
        border-radius: 50%;
        background: black;
        border: 1px solid var(--main-button-back);
        width: 30px;
        height: 30px;
        padding: 2px;
        text-align: center;

        &__element {
          font-size: 11px;
          font-weight: bold;
        }

        &:has(> :first-child:last-child) {
          width: 25px;
          height: 25px;

          .element-marker__label__element {
            line-height: 18px;
            font-size: 13px;
            font-weight: normal;
          }
        }

        &__line-name {
          font-size: 9px;
        }

        &--with-line {
          position: relative;

          &:after {
            top: 30px;
            left: 14px;
            position: absolute;
            content: '';
            display: block;
            background: white;
            width: 1px;
            height: 6px;
          }
        }
      }

      &:hover {
        z-index: 100;
      }
    }

    .chart-tooltip {
      &__svg {
        path {
          stroke: white;
          stroke-width: 1px;
          fill: none; //var(--bg-tint)
        }
      }

      &__inner {
        color: white;

        &__x-label {
          clear: both;
          font-weight: bold;
          font-size: 12px;
        }

        &__y-label {
          display: block;
        }
      }
    }
  }
}
</style>
