import { defineStore } from 'pinia'
import { ref } from 'vue'
import { SpectrumFile } from 'src/types/SpectrumFile'
import { SpectrumFileType } from 'src/types/SpectrumFileType'
import { MsaFile } from 'src/classes/spectrumFile/MsaFile'
import { MsedsFile } from 'src/classes/spectrumFile/MsedsFile'
import { ZenObservable } from 'zen-observable-ts'
import spectrumModule from 'src/api/graphql/spectrumModule'
import { EdxSpectrumPosition } from 'src/types/EdxSpectrumPosition'

export const useSpectrumSource = defineStore('spectrumSource', () => {
  const isLive = ref<boolean>(false)
  let liveSubscription: ZenObservable.Subscription | null = null
  const loadedSource = ref<Omit<SpectrumFile, 'parse'> | null>(null)
  const setFile = (file: Omit<SpectrumFile, 'parse'>) => {
    loadedSource.value = file
    isLive.value = false
  }

  const getTypeForFile = (filename: string): SpectrumFileType | null => {
    const extension = filename
      .substring(filename.lastIndexOf('.') + 1)
      .toLowerCase()

    switch (extension) {
      case 'txt':
        return SpectrumFileType.MSEDS
      case 'msa':
        return SpectrumFileType.MSA
      default:
        return null
    }
  }

  const readUploadedFileContents = async (
    file: File,
    type: SpectrumFileType,
  ): Promise<SpectrumFile> => {
    const content = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const result = (event as { target: { result: string } }).target.result
        resolve(result)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsText(file, 'UTF-8')
    })
    return readSpectrumFile(content, type)
  }

  const readPublicFileContents = async (
    path: string,
    type: SpectrumFileType,
  ): Promise<SpectrumFile> => {
    const response = await fetch(path)
    if (!response.ok) {
      throw Error(response.statusText)
    }
    const content = await response.text()
    return readSpectrumFile(content, type)
  }

  const readSpectrumFile = async (
    contents: string,
    type: SpectrumFileType,
  ): Promise<SpectrumFile> => {
    let file: SpectrumFile
    switch (type) {
      case SpectrumFileType.MSA:
        file = new MsaFile()
        break
      case SpectrumFileType.MSEDS:
        file = new MsedsFile()
        break
    }
    file.parse(contents)
    return file
  }

  const connectLiveMode = (): void => {
    loadedSource.value = {
      title: 'liveMode',
      date: new Date().toLocaleTimeString(),
      liveTime: 0,
      realTime: 0,
      counts: 0,
      spectrum: [],
    }
    liveSubscription = spectrumModule.getSpectrumSubscription(
      (spectrumData) => {
        if (!spectrumData) {
          console.error('Empty payload on subscription')
          return
        }
        if (!loadedSource.value) {
          throw 'No source loaded for active subscription'
        }
        loadedSource.value.liveTime = spectrumData.spectrum.inputCounts
        loadedSource.value.realTime = spectrumData.spectrum.outputCounts
        loadedSource.value.spectrum = spectrumData.spectrum.spectrum.map(
          (counts, index, { length }): EdxSpectrumPosition => ({
            counts,
            kev: (20_000 / length) * index,
          }),
        )
      },
    )

    isLive.value = true
  }

  const disconnectLiveMode = (): void => {
    if (liveSubscription) {
      liveSubscription.unsubscribe()
    }
    isLive.value = false
    liveSubscription = null
  }

  return {
    readUploadedFileContents,
    readPublicFileContents,
    readSpectrumFile,
    connectLiveMode,
    disconnectLiveMode,
    setFile,
    loadedSource,
    getTypeForFile,
    isLive,
  }
})
