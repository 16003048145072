<template>
  <q-menu>
    <q-list>
      <q-item class="text-grey-5" dense>
        <div style="font-size: 12px; margin-top: 4px">Autodetect</div>
      </q-item>
      <q-item dense>
        <q-item-section>
          <q-input
            v-model="autoFoundElement"
            dense
            flat
            label="Element"
            outlined
            square
          />
        </q-item-section>
        <q-item-section side>
          <q-btn
            color="white"
            :label="autoFoundElement ? 'Find' : 'Random'"
            outline
            square
            style="width: 80px"
            @click="autoFindElement"
          />
        </q-item-section>
      </q-item>
      <q-item class="text-grey-5" dense>
        <div style="font-size: 12px; margin-top: 4px">Zoom type</div>
      </q-item>
      <q-item v-ripple dense tag="label">
        <q-item-section avatar class="q-pl-md">
          <q-item-label>Box</q-item-label>
        </q-item-section>
        <q-item-section class="items-center">
          <q-toggle
            v-model="zoomMode"
            dense
            :false-value="ZoomMode.box"
            :true-value="ZoomMode.scroll"
            @update:model-value="
              PreferencesManager.set('zoomMode', $event, true)
            "
          />
        </q-item-section>
        <q-item-section avatar>
          <q-item-label>Scroll</q-item-label>
        </q-item-section>
      </q-item>
      <q-item class="text-grey-5" dense>
        <div style="font-size: 12px; margin-top: 4px">X-ray Display</div>
      </q-item>
      <q-item v-ripple dense tag="label">
        <q-radio
          v-model="kmlDisplayMode"
          class="q-mr-sm"
          dense
          label="Marker"
          :val="KMLDisplayMode.marker"
          @update:model-value="PreferencesManager.set('kmlDisplayMode', $event)"
        />
        <q-radio
          v-model="kmlDisplayMode"
          class="q-mr-sm"
          dense
          label="Line"
          :val="KMLDisplayMode.line"
          @update:model-value="
            PreferencesManager.set('kmlDisplayMode', $event, true)
          "
        />
        <q-radio
          v-model="kmlDisplayMode"
          dense
          label="Both"
          :val="KMLDisplayMode.both"
          @update:model-value="
            PreferencesManager.set('kmlDisplayMode', $event, true)
          "
        />
      </q-item>
      <q-item dense>
        <q-slider
          v-model="minLineWeight"
          class="q-pl-md q-pr-lg"
          dense
          label
          label-text-color="black"
          :label-value="`Minimal KML weight: ${minLineWeight}`"
          :max="1"
          :min="0"
          :step="0.001"
          thumb-color="white"
          @update:model-value="PreferencesManager.set('minLineWeight', $event)"
        />
      </q-item>
      <q-item class="text-grey-5" dense>
        <div style="font-size: 12px; margin-top: 4px">X-ray graph</div>
      </q-item>
      <q-item v-ripple dense tag="label">
        <q-item-section avatar class="q-pl-md">
          <q-item-label>Tiles</q-item-label>
        </q-item-section>
        <q-item-section class="items-center">
          <q-toggle
            v-model="inspectionInRows"
            dense
            @update:model-value="
              PreferencesManager.set('inspectionInRows', $event, true)
            "
          />
        </q-item-section>
        <q-item-section avatar>
          <q-item-label>Rows</q-item-label>
        </q-item-section>
      </q-item>
      <q-item dense>
        <q-slider
          v-model="xrayPeakWidth"
          class="q-pl-md q-pr-lg"
          dense
          label
          label-text-color="black"
          :label-value="`Peak width: ${xrayPeakWidth}`"
          :max="5000"
          :min="10"
          :step="10"
          thumb-color="white"
          @update:model-value="PreferencesManager.set('xrayPeakWidth', $event)"
        />
      </q-item>
      <q-item class="text-grey-5" dense>
        <div style="font-size: 12px; margin-top: 4px">Lines</div>
      </q-item>
      <q-item v-ripple dense tag="label">
        <q-item-section class="q-pl-md">
          <q-item-label>Smooth line</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-toggle
            v-model="spectrumSpline"
            dense
            @update:model-value="
              PreferencesManager.set('spectrumSpline', $event)
            "
          />
        </q-item-section>
      </q-item>
      <q-item v-ripple dense tag="label">
        <q-item-section class="q-pl-md">
          <q-item-label>Raw line</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-toggle
            v-model="spectrumLine"
            dense
            @update:model-value="PreferencesManager.set('spectrumLine', $event)"
          />
        </q-item-section>
      </q-item>
      <q-item v-ripple dense tag="label">
        <q-item-section class="q-pl-md">
          <q-item-label>Smooth area</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-toggle
            v-model="spectrumAreaSpline"
            dense
            @update:model-value="
              PreferencesManager.set('spectrumAreaSpline', $event)
            "
          />
        </q-item-section>
      </q-item>
      <q-item v-ripple dense tag="label">
        <q-item-section class="q-pl-md">
          <q-item-label>Raw area</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-toggle
            v-model="spectrumAreaLine"
            dense
            @update:model-value="
              PreferencesManager.set('spectrumAreaLine', $event)
            "
          />
        </q-item-section>
      </q-item>
      <q-item class="text-grey-5" dense>
        <div style="font-size: 12px; margin-top: 4px">Smoothing</div>
      </q-item>
      <q-item dense>
        <q-slider
          v-model="smoothingSampleSize"
          class="q-pl-md q-pr-lg"
          dense
          label
          label-text-color="black"
          :label-value="`sample size: ${smoothingSampleSize}`"
          :max="50"
          :min="1"
          thumb-color="white"
          @update:model-value="
            PreferencesManager.set('smoothingSampleSize', $event)
          "
        />
      </q-item>
      <q-item class="text-grey-5" dense>
        <div style="font-size: 12px; margin-top: 4px">Emulate live counts</div>
      </q-item>
      <q-item dense>
        <q-slider
          v-model="liveCountsIntensity"
          class="q-pl-md q-pr-lg"
          dense
          label
          label-text-color="black"
          :label-value="`add between 0 and ${liveCountsIntensity}%`"
          :max="25"
          :min="1"
          thumb-color="white"
          @update:model-value="
            PreferencesManager.set('liveCountsIntensity', $event)
          "
        />
      </q-item>
      <q-item dense>
        <q-slider
          v-model="liveCountsProbability"
          class="q-pl-md q-pr-lg"
          dense
          label
          label-text-color="black"
          :label-value="`probability: ${(liveCountsProbability * 100).toFixed()}%`"
          :max="1"
          :min="0.01"
          :step="0.01"
          thumb-color="white"
          @update:model-value="
            PreferencesManager.set('liveCountsProbability', $event)
          "
        />
      </q-item>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import PreferencesManager from 'src/classes/PreferencesManager'
import { KMLDisplayMode, ZoomMode } from 'src/types/Preferences'
import { ref } from 'vue'
import { useAutoDetect } from 'src/stores/useAutoDetect'
import periodicElementProperties from 'src/constants/periodicElementProperties'
import { showToast } from 'src/composables/showDialogs'
import { ElementSymbol } from 'src/types/ElementSymbol'
import { useElementSelection } from 'src/stores/useElementSelection'

const zoomMode = PreferencesManager.get<ZoomMode>('zoomMode', ZoomMode.box)

const kmlDisplayMode = PreferencesManager.get<KMLDisplayMode>(
  'kmlDisplayMode',
  KMLDisplayMode.marker,
)
const minLineWeight = PreferencesManager.get<number>('minLineWeight', 0)

const xrayPeakWidth = PreferencesManager.get<number>('xrayPeakWidth', 100)

const spectrumSpline = PreferencesManager.get<boolean>('spectrumSpline', true)
const spectrumLine = PreferencesManager.get<boolean>('spectrumLine', false)
const spectrumAreaSpline = PreferencesManager.get<boolean>(
  'spectrumAreaSpline',
  false,
)

const spectrumAreaLine = PreferencesManager.get<boolean>(
  'spectrumAreaLine',
  true,
)

const availableElements: ElementSymbol[] = Object.keys(
  periodicElementProperties,
) as ElementSymbol[]
const autoDetect = useAutoDetect()
const elementSelection = useElementSelection()
const autoFoundElement = ref('')
const autoFindElement = (): void => {
  if (
    autoFoundElement.value &&
    !availableElements.includes(autoFoundElement.value as ElementSymbol)
  ) {
    autoFoundElement.value = ''
    showToast('Unknown element', undefined, 'negative')
    return
  }
  if (!autoFoundElement.value) {
    const possibleElements = availableElements.filter(
      (element) =>
        !elementSelection.excluded.includes(element) &&
        !elementSelection.selected.includes(element),
    )
    if (possibleElements.length === 0) {
      return
    }
    autoFoundElement.value =
      possibleElements[Math.floor(Math.random() * possibleElements.length)]
  }
  autoDetect.addResult(autoFoundElement.value as ElementSymbol)
  autoFoundElement.value = ''
}

const inspectionInRows = PreferencesManager.get<boolean>(
  'inspectionInRows',
  true,
)

const smoothingSampleSize = PreferencesManager.get<number>(
  'smoothingSampleSize',
  10,
)

const liveCountsIntensity = PreferencesManager.get<number>(
  'liveCountsIntensity',
  1,
)
const liveCountsProbability = PreferencesManager.get<number>(
  'liveCountsProbability',
  0.1,
)
</script>
