import type { StorageDriver } from 'src/types/StorageDriver'
import Singleton from './Singleton'

class Storage extends Singleton {
  private driver: StorageDriver | undefined
  private isInitialized: boolean = false
  private initializePromise: Promise<void> | undefined = undefined
  private resolveInitializePromise: (() => void) | undefined = undefined

  constructor() {
    super(Storage)
  }

  public awaitInitialized(): Promise<void> {
    if (!this.initializePromise) {
      this.initializePromise = new Promise((resolve) => {
        if (this.isInitialized) {
          resolve()
        }
        this.resolveInitializePromise = resolve
      })
    }
    return this.initializePromise
  }

  async initialize(driver: StorageDriver): Promise<void> {
    if (this.driver) {
      return
    }
    this.driver = driver
    await this.driver.initialize()
    if (this.resolveInitializePromise) {
      this.resolveInitializePromise()
    }
  }

  async has(key: string): Promise<boolean> {
    if (!this.driver) {
      throw Error('Storage not initialized')
    }
    return this.driver.has(key)
  }

  async get(key: string, fallback: any = undefined): Promise<any> {
    if (!this.driver) {
      throw Error('Storage not initialized')
    }
    return this.driver.get(key, fallback)
  }

  async pull(key: string, fallback: any = undefined) {
    if (!this.driver) {
      throw Error('Storage not initialized')
    }
    return this.driver.pull(key, fallback)
  }

  async set(key: string, value: any): Promise<void> {
    if (!this.driver) {
      throw Error('Storage not initialized')
    }
    return this.driver.set(key, value)
  }

  async forget(key: string): Promise<void> {
    if (!this.driver) {
      throw Error('Storage not initialized')
    }
    return this.driver.forget(key)
  }

  async flush(): Promise<void> {
    if (!this.driver) {
      throw Error('Storage not initialized')
    }
    return this.driver.flush()
  }
}

export default new Storage()
