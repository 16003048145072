import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Dialog, Notify, Quasar, QuasarUIConfiguration } from 'quasar'
import Highcharts from 'highcharts'
import draggablePoints from 'highcharts/modules/draggable-points'
import annotations from 'highcharts/modules/annotations'
import annotationsAdvanced from 'highcharts/modules/annotations-advanced'

import App from 'src/App.vue'
import router from 'src/router'
import translator from 'src/lang'
import Storage from 'src/classes/Storage'
import { LocalStorage } from 'src/classes/storageDrivers/LocalStorage'
import PreferencesManager from 'src/classes/PreferencesManager'
import tooltipDirective from './directives/tooltipDirective'

import 'remixicon/fonts/remixicon.css'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import 'src/css/app.scss'

const pinia = createPinia()

const quasarConfig: QuasarUIConfiguration = {
  // @ts-ignore (type definition is incomplete)
  dark: true,
  notify: {
    position: 'top-right',
  },
}
draggablePoints(Highcharts)
annotations(Highcharts)
annotationsAdvanced(Highcharts)
Highcharts.setOptions({
  plotOptions: {
    series: {
      animation: false,
    },
  },
})
const mountApplication = async () => {
  await Storage.initialize(new LocalStorage())
  createApp(App)
    .use(translator)
    .use(pinia)
    .use(router)
    .use(Quasar, {
      config: quasarConfig,
      plugins: {
        Notify,
        Dialog,
      },
    })
    .use(PreferencesManager)
    .directive('tooltip', tooltipDirective)
    .mount('#app')
}

mountApplication()
