import type {
  Options,
  Point,
  PointerEventObject,
  SeriesOptionsType,
  XAxisPlotLinesOptions,
  AxisSetExtremesEventObject,
} from 'highcharts'

// @ts-ignore
import smooth from 'array-smooth'
import { computed, ComputedRef, Ref } from 'vue'
import PreferencesManager from 'src/classes/PreferencesManager'
import { ZoomMode } from 'src/types/Preferences'
import { PlotType } from 'src/types/PlotType'
import { clamp } from 'lodash'
import { NamedEdxSpectrum } from 'src/types/NamedEdxSpectrum'

export const useHighchartOptionsForPlot = () => {
  const spectrumPlotOptions = (
    spectra: Ref<NamedEdxSpectrum[]>,
    lineTypes: Ref<PlotType[]>,
    extraSeries: ComputedRef<SeriesOptionsType[]>,
    plotLines: ComputedRef<XAxisPlotLinesOptions[]>,
    fixedBounds: Ref<{
      minX: number
      maxX: number
      minY: number
      maxY: number
    } | null>,
    onPlotClick?: (e: PointerEventObject) => void,
    onExtremesChanged?: (e: AxisSetExtremesEventObject) => void,
  ): {
    static: Options
    dynamic: ComputedRef<Options>
    series: ComputedRef<SeriesOptionsType[]>
  } => {
    return {
      static: {
        /* @ts-ignore */
        styledMode: true,
        title: {
          text: '',
        },
        legend: { enabled: false },
        chart: {
          animation: false,
          panning: {
            enabled: true,
            type: 'xy',
          },
          events: {
            click: onPlotClick,
          },
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            animation: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          line: {
            marker: {
              enabled: false,
            },
          },
          areaspline: {
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
          },
          area: {
            marker: {
              enabled: false,
            },
          },
        },
        credits: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        xAxis: {
          labels: {
            format: '{text}V',
          },
          crosshair: {
            className: 'crosshair-line',
          },
          gridLineWidth: 1,
          gridLineDashStyle: 'LongDash',
          events: {
            setExtremes: onExtremesChanged,
          },
        },
        yAxis: [
          {
            id: 'spectrum-0',
            labels: {
              enabled: false,
            },
            title: {
              text: '',
            },
            gridLineWidth: 0,
            events: {
              setExtremes: onExtremesChanged,
            },
          },
          {
            id: 'elementAreas',
            labels: {
              enabled: false,
            },
            title: {
              text: '',
            },
            gridLineWidth: 0,
          },
        ],
        tooltip: {
          className: 'chart-tooltip__svg',
          borderRadius: 0,
          hideDelay: 0,
          shadow: false,
          formatter: function () {
            type PointWithCustom = Point & {
              point: {
                series: {
                  options: { custom: { isSmooth: boolean; series: number } }
                }
              }
            }
            const points = this.points as unknown as PointWithCustom[]
            points.forEach((point) => {
              if (!point.point.series?.options?.custom) {
                console.log({ point })
              }
            })
            const uniqueRawPoints = Object.values(
              points
                .filter((point) => !point.point.series.options.custom.isSmooth)
                .reduce((uniquePoints, point) => {
                  uniquePoints[point.point.series.options.custom.series] = point
                  return uniquePoints
                }, [] as PointWithCustom[]),
            )
            if (!uniqueRawPoints.length) {
              return ''
            }
            const kev = (uniqueRawPoints[0].x / 1000).toFixed(2)
            return `<div class="chart-tooltip__inner">${uniqueRawPoints.reduce(
              (tip: string, point: PointWithCustom): string =>
                `${tip}<div class="chart-tooltip__inner__y-label">${point.y} counts</div>`,
              ``,
            )}<div class="chart-tooltip__inner__x-label">${kev}kV</div></div>`
          },
          positioner: function (labelWidth, _labelHeight, point) {
            const x = point.plotX + this.chart.plotLeft - labelWidth / 2
            return {
              x: clamp(
                x,
                0,
                this.chart.plotWidth + this.chart.plotLeft - labelWidth,
              ),
              y: this.chart.plotHeight + this.chart.plotTop,
            }
          },
          shared: true,
          useHTML: true,
        },
      },
      dynamic: computed<Options>(() => {
        const zoomMode = PreferencesManager.get<ZoomMode>(
          'zoomMode',
          ZoomMode.box,
        )
        return {
          chart: {
            zooming:
              zoomMode.value === ZoomMode.box
                ? {
                    type: 'xy',
                  }
                : {},
            panKey: zoomMode.value === ZoomMode.scroll ? undefined : 'alt',
          },
          xAxis: {
            min: clamp(
              fixedBounds.value?.minX ?? 0,
              0,
              Number.MAX_SAFE_INTEGER,
            ),
            max: clamp(fixedBounds.value?.maxX ?? 20000, 20000),
            plotLines: plotLines.value,
          },
          yAxis: {
            min: clamp(
              fixedBounds.value?.minY ?? 0,
              0,
              Number.MAX_SAFE_INTEGER,
            ),
            max: fixedBounds.value?.maxY,
          },
        }
      }),
      series: computed<SeriesOptionsType[]>(() => {
        const smoothingSampleSize = PreferencesManager.get<number>(
          'smoothingSampleSize',
          10,
        )
        let seriesData: {
          spectrum: number | string
          raw: [number, number][]
          smooth: [number, number][]
        }[] = []
        seriesData = spectra.value.map((spectrumObject) => {
          const rawCounts: number[] = spectrumObject.spectrum.map(
            ({ counts }) => counts,
          )
          const smoothCounts: number[] = smooth(
            rawCounts,
            smoothingSampleSize.value,
          )
          return {
            spectrum: spectrumObject.id,
            raw: spectrumObject.spectrum.map(({ kev }, index) => [
              kev,
              rawCounts[index],
            ]),
            smooth: spectrumObject.spectrum.map(({ kev }, index) => [
              kev,
              smoothCounts[index],
            ]),
          }
        })
        return [
          ...(lineTypes.value.includes(PlotType.AreaSpline)
            ? ([
                ...seriesData.map((series, index) => ({
                  ...series,
                  data: series.smooth,
                  type: 'areaspline',
                  id: `areaspline-spectrum-series-${series.spectrum}`,
                  yAxis: `spectrum-${index}`,
                  className: 'areaspline--spectrum',
                  custom: {
                    series: series.spectrum,
                    isSmooth: true,
                  },
                })),
              ] as SeriesOptionsType[])
            : []),
          ...(lineTypes.value.includes(PlotType.AreaLine)
            ? ([
                ...seriesData.map((series, index) => ({
                  ...series,
                  data: series.raw,
                  type: 'area',
                  id: `area-spectrum-series-${series.spectrum}`,
                  yAxis: `spectrum-${index}`,
                  className: 'area--spectrum',
                  custom: {
                    series: series.spectrum,
                    isSmooth: false,
                  },
                })),
              ] as SeriesOptionsType[])
            : []),
          ...(lineTypes.value.includes(PlotType.Spline)
            ? ([
                ...seriesData.map((series, index) => ({
                  ...series,
                  data: series.smooth,
                  type: 'spline',
                  id: `spline-spectrum-series-${series.spectrum}`,
                  yAxis: `spectrum-${index}`,
                  className: 'spline--spectrum',
                  custom: {
                    series: series.spectrum,
                    isSmooth: true,
                  },
                })),
              ] as SeriesOptionsType[])
            : []),
          ...(lineTypes.value.includes(PlotType.Line)
            ? ([
                ...seriesData.map((series, index) => ({
                  ...series,
                  data: series.raw,
                  type: 'line',
                  id: `line-spectrum-series-${series.spectrum}`,
                  yAxis: `spectrum-${index}`,
                  className: 'line--spectrum',
                  custom: {
                    series: series.spectrum,
                    isSmooth: false,
                  },
                })),
              ] as SeriesOptionsType[])
            : []),
          ...extraSeries.value,
        ]
      }),
    }
  }

  return {
    spectrumPlotOptions,
  }
}
