<template>
  <q-btn
    ref="uiButton"
    :color="color"
    :dense="dense"
    :disabled="disabled"
    :flat="flat"
    :round="round"
    :size="size"
    :text-color="textColor"
    @click="onClick"
    @keydown.space.prevent="onSpace"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <slot />
  </q-btn>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

defineProps<{
  color?: string
  textColor?: string
  size?: string
  disabled?: boolean
  dense?: boolean
  flat?: boolean
  round?: boolean
}>()

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void
  (e: 'mouseleave', event: MouseEvent): void
}>()

const uiButtonRef = ref<HTMLButtonElement>()
const onClick = (event: Event): void => {
  emit('click', event as MouseEvent)
}
const onSpace = (): void => {
  if (!uiButtonRef.value) {
    return
  }
  uiButtonRef.value.blur()
}
</script>

<style lang="scss">
button:not(.disabled) {
  &:has(#upperSparkle) {
    #upperSparkle {
      transform-origin: 19px 5px;
    }

    &:hover {
      #upperSparkle {
        animation-name: sparkleUp;
        animation-timing-function: ease-out;
        animation-duration: 300ms;
      }
    }
  }

  &:has(#lowerSparkle) {
    #lowerSparkle {
      transform-origin: 19px 19px;
    }

    &:hover {
      #lowerSparkle {
        animation-name: sparkleDown;
        animation-timing-function: ease-out;
        animation-duration: 300ms;
        animation-delay: 100ms;
      }
    }
  }
}

@keyframes sparkleUp {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  99% {
    transform: translate(2px, -2px) scale(0.8);
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sparkleDown {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(3px, 1px) scale(0.5);
    opacity: 0;
  }
}
</style>
