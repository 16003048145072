import { Dialog, Notify, QDialogOptions, QNotifyCreateOptions } from 'quasar'
import Translator from 'src/lang'
import { TOptions } from 'i18next'

export const showToast = async (
  messageTag: string,
  captionTag = '',
  type = 'positive',
  messageOptions: TOptions = {},
  notifyOptions: QNotifyCreateOptions = {},
) => {
  return Notify.create({
    type,
    position: 'bottom',
    message: Translator.t(messageTag, messageOptions),
    caption: captionTag ? Translator.t(captionTag) : undefined,
    ...notifyOptions,
  })
}

export const showAlert = async (alertTag: string, titleTag?: string) => {
  return new Promise((resolve) => {
    Dialog.create({
      title: titleTag ? Translator.t(titleTag) : Translator.t(alertTag),
      message: titleTag ? Translator.t(alertTag) : undefined,
      ok: {
        color: 'primary',
      },
    })
      .onOk(() => {
        resolve(true)
      })
      .onCancel(() => {
        resolve(false)
      })
      .onDismiss(() => {
        resolve(false)
      })
  })
}
export const showPrompt = async (
  questionTag: string,
  defaultInputTag = '',
  titleTag?: string,
): Promise<string | null> => {
  return new Promise((resolve) => {
    Dialog.create({
      title: titleTag ? Translator.t(titleTag) : Translator.t(questionTag),
      message: titleTag ? Translator.t(questionTag) : undefined,
      ok: {
        color: 'primary',
      },
      cancel: true,
      prompt: {
        model: Translator.t(defaultInputTag),
        color: 'primary',
        class: 'text-input',
        dense: true,
      },
    })
      .onOk((data) => {
        resolve(data)
      })
      .onCancel(() => {
        resolve(null)
      })
      .onDismiss(() => {
        resolve(null)
      })
  })
}

export const showConfirm = async (
  questionTag: string,
  titleTag?: string,
  questionOptions: TOptions = {},
) => {
  return new Promise((resolve) => {
    Dialog.create({
      title: titleTag
        ? Translator.t(titleTag)
        : Translator.t(questionTag, questionOptions),
      message: titleTag
        ? Translator.t(questionTag, questionOptions)
        : undefined,
      cancel: true,
      persistent: true,
      ok: {
        color: 'primary',
      },
    })
      .onOk(() => {
        resolve(true)
      })
      .onCancel(() => {
        resolve(false)
      })
      .onDismiss(() => {
        resolve(false)
      })
  })
}

export const showDialog = async <TResponse>(
  messageTag: string,
  options: QDialogOptions = {},
  titleTag?: string,
): Promise<null | TResponse | true> => {
  return new Promise((resolve) => {
    Dialog.create({
      title: titleTag ? Translator.t(titleTag) : Translator.t(messageTag),
      message: titleTag ? Translator.t(messageTag) : undefined,
      cancel: true,
      persistent: true,
      ok: {
        color: 'primary',
      },
      ...options,
    })
      .onOk((data?: TResponse) => {
        if (data === undefined) {
          return resolve(true)
        }
        resolve(data)
      })
      .onCancel(() => {
        resolve(null)
      })
      .onDismiss(() => {
        resolve(null)
      })
  })
}

export const showStaticHtmlModal = async (
  titleTag: string,
  assetPath: string,
): Promise<void> => {
  return new Promise((resolve) => {
    Dialog.create({
      message: `<iframe src="/${assetPath}" style="width: 100%; height: 600px"/>`,
      html: true,
      class: 'q-card--static-asset',
      cancel: false,
      ok: {
        color: 'primary',
      },
    }).onDismiss(resolve)
  })
}
