import { createRouter, createWebHistory } from 'vue-router'
import FileSelection from 'src/views/FileSelection.vue'
import SpectrumShow from 'src/views/SpectrumShow.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'fileSelection',
      component: FileSelection,
    },
    {
      path: '/spectrum',
      name: 'spectrum',
      component: SpectrumShow,
    },
  ],
})

export default router
