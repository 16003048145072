import { useMouseInElement } from '@vueuse/core'
import { ComputedRef, ref, watch } from 'vue'
import Translator from '../lang'
import { promiseTimeout } from '@vueuse/core/index'

let unwatch: ReturnType<typeof watch> | undefined
export default {
  mounted(
    el: HTMLElement,
    { value: tipTag }: { value: string | ComputedRef<string> },
  ) {
    if (!tipTag) {
      return
    }
    const target = ref(el)
    const { isOutside } = useMouseInElement(target)
    el.style.position = 'relative'
    el.addEventListener('mousedown', () => {
      removeTooltips()
    })
    unwatch = watch(isOutside, (outside) => {
      if (outside) {
        removeTooltips()
      } else {
        promiseTimeout(100).then(() => {
          if (isOutside.value) {
            return
          }
          if (typeof tipTag === 'string') {
            showTooltip(el, tipTag)
          } else {
            showTooltip(el, tipTag.value)
          }
        })
      }
    })
  },
  beforeUnmount() {
    if (unwatch) {
      unwatch()
    }
    removeTooltips()
  },
}

const showTooltip = (el: HTMLElement, tipTag: string) => {
  if (el.className.includes('disabled')) {
    return
  }
  const { bottom, right, left, top } = el.getBoundingClientRect()
  const tip = document.createElement('span')
  if (top > 28) {
    tip.style.top = `${top - 28}px`
  } else {
    tip.style.top = `${bottom + 4}px`
  }
  const leftPosition = left + (right - left) / 2
  tip.style.left = `${leftPosition}px`
  tip.className = 'px-tooltip'
  tip.innerText = Translator.has(`tooltips.${tipTag}`)
    ? Translator.t(`tooltips.${tipTag}`)
    : tipTag
  document.body.appendChild(tip)
  const { right: tipRight, left: tipLeft } = tip.getBoundingClientRect()
  if (tipRight > window.innerWidth) {
    tip.style.left = `${leftPosition - (tipRight - window.innerWidth)}px`
  } else if (tipLeft < 0) {
    tip.style.left = `${leftPosition - tipLeft}px`
  }

  setTimeout(() => {
    tip.style.opacity = '1'
  }, 200)
}
const removeTooltips = () => {
  const tips = document.querySelectorAll('.px-tooltip')
  for (const tip of Array.from(tips)) {
    tip.remove()
  }
}
