<template>
  <div class="window-bar__left-buttons">
    <slot name="left-buttons" />
  </div>
  <div class="window-bar__center-buttons">
    <slot name="main-buttons" />
  </div>
  <div class="window-bar__right-buttons">
    <slot name="right-buttons" />
  </div>
</template>
