<template>
  <div class="periodic-table">
    <PeriodicElement
      v-if="elementSelection.highlighted"
      class="periodic-table__highlight"
      :element="elementSelection.highlighted"
      standalone
      :style="`
  ${
    selectedColorOverwrites[elementSelection.highlighted]
      ? `--selected-color: ${selectedColorOverwrites[elementSelection.highlighted]}`
      : ''
  }
  `"
      @click.stop="includeHighlighted"
    />
    <div v-if="highlightedElementDefinition" class="periodic-table__controls">
      <q-badge
        v-if="isDetected"
        class="full-width q-py-sm justify-center no-border-radius"
        color="white"
        style="font-size: 14px"
        text-color="primary"
        @click.stop
      >
        <i class="ri-sparkling-fill q-mr-sm" />
        Auto
      </q-badge>
      <UIButton
        v-else
        :color="isSelected ? 'white' : 'primary'"
        dense
        no-caps
        square
        :text-color="isSelected ? 'primary' : ''"
        @click.stop="includeHighlighted"
      >
        Include
      </UIButton>
      <UIButton
        :color="isExcluded ? 'white' : 'primary'"
        dense
        no-caps
        square
        :text-color="isExcluded ? 'primary' : ''"
        @click.stop="excludeHighlighted"
      >
        Exclude
      </UIButton>
      <q-btn
        class="squared"
        dense
        :disable="highlightedElementDefinition.number === 1"
        no-caps
        outline
        square
        @click.stop="highlightPreviousElement"
      >
        <i class="ri-arrow-left-line"></i>
      </q-btn>
      <q-btn
        class="squared"
        dense
        :disable="highlightedElementDefinition.number === 92"
        no-caps
        outline
        square
        @click.stop="highlightNextElement"
      >
        <i class="ri-arrow-right-line"></i>
      </q-btn>
    </div>
    <PeriodicElement
      v-for="{ symbol } in periodicElements"
      :key="symbol"
      :element="symbol"
      :style="`
  grid-area: ${symbol};
  ${
    selectedColorOverwrites[symbol]
      ? `--selected-color: ${selectedColorOverwrites[symbol]}`
      : ''
  }
  `"
      @click.stop="elementSelection.toggleHighlighted(symbol)"
      @dblclick.stop="elementSelection.toggleSelected(symbol)"
    />
  </div>
</template>

<script setup lang="ts">
import periodicElements from 'src/constants/periodicElements'
import PeriodicElement from 'src/components/PeriodicElement.vue'
import { computed } from 'vue'
import periodicElementProperties from 'src/constants/periodicElementProperties'
import { PeriodicElement as TPeriodicElement } from 'src/types/PeriodicElement'
import { onKeyStroke } from '@vueuse/core'
import { useAutoDetect } from 'src/stores/useAutoDetect'
import { useElementSelection } from 'src/stores/useElementSelection'
import UIButton from 'src/components/UIButton.vue'

withDefaults(
  defineProps<{
    selectedColorOverwrites?: Record<string, string>
    showHighlightInfo?: boolean
  }>(),
  {
    selectedColorOverwrites: () => ({ Pb: 'red', Cu: 'yellow' }),
  },
)

const elementSelection = useElementSelection()
const highlightedElementDefinition = computed<
  (TPeriodicElement & { confidence?: number }) | null
>(() => {
  if (elementSelection.highlighted === null) {
    return null
  }
  const definition = periodicElements.find(
    ({ symbol }) => symbol === elementSelection.highlighted,
  ) as TPeriodicElement & { confidence?: number }

  if (!definition) {
    throw Error(`Unknown element: ${elementSelection.highlighted}`)
  }

  if (isDetected.value) {
    definition.confidence = parseFloat((Math.random() * 10 + 90).toFixed(2))
  }

  if (periodicElementProperties[elementSelection.highlighted]) {
    definition.properties =
      periodicElementProperties[elementSelection.highlighted]
  }
  return definition
})
const autoDetect = useAutoDetect()
const isDetected = computed<boolean>(() => {
  if (!elementSelection.highlighted) {
    return false
  }
  return autoDetect.detected.includes(elementSelection.highlighted)
})
const isSelected = computed<boolean>(() => {
  if (!elementSelection.highlighted) {
    return false
  }
  return elementSelection.selected.includes(elementSelection.highlighted)
})
const isExcluded = computed<boolean>(() => {
  if (!elementSelection.highlighted) {
    return false
  }
  return elementSelection.excluded.includes(elementSelection.highlighted)
})
const highlightPreviousElement = (): void => {
  moveHighlight(-1)
}
const highlightNextElement = (): void => {
  moveHighlight(1)
}

const moveHighlight = (direction: -1 | 1): void => {
  if (!highlightedElementDefinition.value) {
    return
  }
  let nextNumber = highlightedElementDefinition.value.number + direction
  let element
  let definition
  while (nextNumber) {
    element = periodicElements.find((element) => element.number === nextNumber)
    if (!element) {
      return
    }
    definition = periodicElementProperties[element.symbol]
    if (
      definition &&
      Object.values(definition.atomicProperties.xrayLines).some(
        ({ energy }) => energy < 20,
      )
    ) {
      return elementSelection.toggleHighlighted(element.symbol)
    }
    nextNumber += direction
  }
}

const includeHighlighted = () => {
  elementSelection.toggleSelected(elementSelection.highlighted)
}
const excludeHighlighted = () => {
  if (elementSelection.highlighted) {
    autoDetect.removeResult(elementSelection.highlighted)
  }
  elementSelection.toggleExcluded(elementSelection.highlighted)
}
onKeyStroke('ArrowLeft', (): void => {
  highlightPreviousElement()
})
onKeyStroke('ArrowRight', (): void => {
  highlightNextElement()
})
</script>

<style lang="scss">
.periodic-table {
  height: 100%;
  overflow: visible;
  max-width: 100%;
  aspect-ratio: 18/9.5;
  display: grid;
  padding-top: 3px;
  grid-template-columns: [metals-non-metals] repeat(18, 1fr);
  grid-template-rows: repeat(7, 1fr) 0.5fr repeat(2, 1fr);
  gap: 6px;
  grid-template-areas:
    'H  .  .  .  highlight  highlight  highlight  controls  controls  controls  controls .  .  .  .  .  .  He'
    'Li Be .  .  highlight  highlight  highlight  controls  controls  controls  controls .  B  C  N  O  F  Ne'
    'Na Mg .  .  highlight  highlight  highlight  controls  controls  controls  controls .  Al Si P  S  Cl Ar'
    'K  Ca Sc Ti V  Cr Mn Fe Co Ni Cu Zn Ga Ge As Se Br Kr'
    'Rb Sr Y  Zr Nb Mo Tc Ru Rh Pd Ag Cd In Sn Sb Te I  Xe'
    'Cs Ba Lu Hf Ta W  Re Os Ir Pt Au Hg Tl Pb Bi Po At Rn'
    'Fr Ra Lr Rf Db Sg Bh Hs Mt Ds Rg Cn Nh Fl Mc Lv Ts Og'
    '.  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .'
    '.  .  La Ce Pr Nd Pm Sm Eu Gd Tb Dy Ho Er Tm Yb .  .'
    '.  .  Ac Th Pa U  Np Pu Am Cm Bk Cf Es Fm Md No .  .';

  &__highlight {
    margin: 5px;
    grid-area: highlight;
    width: calc(100% - 10px) !important;
    height: calc(100% - 10px) !important;

    .periodic-element__symbol {
      font-size: 300% !important;
    }
  }

  &__controls {
    grid-area: controls;
    margin: 5px;
    width: 100% !important;
    height: calc(100% - 10px) !important;

    .q-badge,
    .q-btn {
      height: calc(33% - 4px);
      margin: 0 0 4px;
      width: 100%;

      &.squared {
        height: 33%;
        margin: 0;
        aspect-ratio: 1 / 1;
        width: auto;
      }
    }
  }

  .periodic-element {
    --grow-size: 15px;

    &--Li,
    &--Na,
    &--K,
    &--Rb,
    &--Cs,
    &--Fr {
      &:hover:not(.periodic-element--standalone) {
        margin: calc(var(--grow-size) * -1) calc(var(--grow-size) * -2)
          calc(var(--grow-size) * -1) 0 !important;
      }
    }

    &--Ne,
    &--Ar,
    &--Kr,
    &--Xe,
    &--Rn,
    &--Og {
      &:hover:not(.periodic-element--standalone) {
        margin: calc(var(--grow-size) * -1) 0 calc(var(--grow-size) * -1)
          calc(var(--grow-size) * -2) !important;
      }
    }

    &--H:hover:not(.periodic-element--standalone) {
      margin: 0 calc(var(--grow-size) * -2) calc(var(--grow-size) * -2) 0 !important;
    }

    &--He:hover:not(.periodic-element--standalone) {
      margin: 0 0 calc(var(--grow-size) * -2) calc(var(--grow-size) * -2) !important;
    }

    &:hover:not(.periodic-element--standalone) {
      overflow: visible;
      z-index: 2;
      margin: calc(var(--grow-size) * -1);
      box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
