<template>
  <q-header class="transparent">
    <q-toolbar class="window-bar">
      <div class="window-bar__left-section">
        <div class="window-bar__mode-buttons">
          <div class="main-button">
            <img class="main-button__logo" src="/icons/discoverLogo.svg" />
          </div>
          <slot name="mode-buttons" />
        </div>
      </div>
      <div class="window-bar__center-section">
        <slot />
      </div>
      <div class="window-bar__right-section">
        <div class="window-bar__extra-buttons">
          <slot name="extra-buttons"> </slot>
        </div>
        <div class="window-bar__window-buttons">
          <slot name="right" />
          <q-btn class="close-button" dense flat @click="emit('close')">
            <i class="ri-close-line"></i>
          </q-btn>
        </div>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'close'): void
}>()
</script>

<style lang="scss">
.window-bar {
  z-index: 2010;
  display: grid !important;
  grid-template-columns:
    [left] 1fr [center] min(
      calc(100vh - var(--window-bar-height)),
      calc(100vw - 2 * 290px)
    )
    [right] 1fr;
  padding: 0 !important;
  height: var(--window-bar-height);
  min-height: var(--window-bar-height);
  background-color: var(--window-bar-background) !important;

  &__mode-buttons {
    .q-btn {
      &.back-button {
        background-color: var(--main-button-back);
        color: black;
        font-size: 18px;
      }

      &:not(.back-button):not(.main-button) {
        text-align: center;
        width: 58px;
        height: var(--window-bar-height);
        padding: calc((var(--window-bar-height) - 31px) / 2) 0;
        margin-left: 5px;

        i {
          height: 16px;
          display: block;
        }

        span {
          display: block;
          font-size: 10px;
          text-transform: none;
          font-weight: bold;
        }
      }
    }
  }

  &__mode-crumbs {
    display: inline-block;
    margin: 0 12px;
    font-size: 12px;
    color: #8b8b8b;
    line-height: var(--window-bar-height);
  }

  &__mode-crumbs__crumb {
    color: #8b8b8b;
    font-size: 12px;
    border: none;
    background: none;

    i {
      font-size: 16px;
    }

    &:after {
      padding: 0 8px;
      font-family: 'remixicon' !important;
      content: '\ea6e';
    }
  }

  &__mode-crumbs__crumb--active {
    color: white;

    &:after {
      display: none;
    }
  }

  .q-btn-dropdown,
  .q-btn {
    height: var(--window-bar-height);
    min-width: var(--window-bar-height);
    border-radius: 0;
    box-shadow: none;

    &.active {
      background-color: white;
      color: var(--window-bar-background);
    }

    &.disable {
      opacity: 0.7;
    }

    &.round-button {
      height: calc(var(--window-bar-height) - 12px);
      margin: 6px;
      padding: 0 20px;
      border-radius: 4px;
      border: 1px solid var(--round-button-border);
      background-color: var(--round-button);
      color: var(--round-button-text);

      &:disabled {
        border: none;
        background-color: var(--round-button-disabled);
        color: var(--round-button-disabled-text);
      }
    }

    &.accent-button {
      background-color: var(--main-button-back);
      color: black;

      &.active {
        position: relative;

        &:before {
          width: 100%;
          height: 100%;
          position: absolute;
          display: block;
          content: '';
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
    }

    &.outlined-button {
      color: white;
      border: 1px solid white;
    }

    &.small-button {
      border-radius: 4px;
      height: calc(var(--window-bar-height) - 20px);
      margin: 5px;
      min-width: 80px;
      line-height: 1rem;
      min-height: unset;
    }

    &.small-wide-button {
      border-radius: 4px;
      height: calc(var(--window-bar-height) - 20px);
      margin: 5px;
      min-width: 140px;
      line-height: 1rem;
      min-height: unset;
    }
  }

  .divider {
    display: inline-block;
    width: 2px;
    height: 30px;
    margin: -10px 12px;
    background-color: var(--text-color);
  }

  .q-btn {
    padding: 4px;

    i {
      font-size: 18px;
    }

    &:before {
      display: none;
    }

    &.close-button:hover {
      background-color: #c42d1e;
    }
  }

  .q-btn-dropdown {
    &:has(.q-btn:hover) {
      .q-btn {
        .q-focus-helper {
          background: currentColor;
          opacity: 0.15;
        }
      }
    }

    > .q-btn__content > i,
    > .q-btn {
      min-width: initial;

      &:first-child {
        padding-right: 0;
      }

      &:nth-child(2) {
        margin-left: 0;
        border: none;
        width: 16px;
      }
    }

    > .q-btn:not([aria-expanded='true']) {
      &:last-child {
        &:not(.disabled):hover {
          .q-icon {
            animation-name: bounce;
            animation-timing-function: ease-in-out;
            animation-duration: 400ms;
          }
        }
      }
    }
  }

  .button-placeholder {
    width: var(--window-bar-height);
    height: var(--window-bar-height);
    display: inline-flex;
    vertical-align: middle;
    @for $i from 2 through 4 {
      &[data-btns='#{$i}'] {
        width: calc(var(--window-bar-height) * #{$i});
      }
    }
  }

  &__left-section,
  &__center-section,
  &__right-section {
    display: grid;
    height: var(--window-bar-height);
  }

  &__center-section {
    grid-template-columns: [left] 1fr [center] 3fr [right] 1fr;
  }

  &__right-section {
    grid-template-columns: [left] auto [right] auto;
  }

  &__mode-buttons,
  &__left-buttons {
    justify-self: start;
    display: flex;
    align-items: center;
  }

  &__extra-buttons,
  &__center-buttons {
    justify-self: center;
    white-space: nowrap;
  }

  &__right-buttons,
  &__window-buttons {
    justify-self: end;
  }

  &__right-buttons,
  &__window-buttons,
  &__mode-buttons,
  &__left-buttons,
  &__extra-buttons,
  &__center-buttons {
    white-space: nowrap;
  }

  &--hidden {
    top: calc(var(--window-bar-height) * -1);
    transition: top ease-out 200ms;

    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: var(--window-bar-height);
      transform: translateY(100%);
    }

    &:hover {
      top: 0;
    }
  }

  .main-button {
    height: var(--window-bar-height);
    width: var(--window-bar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    &__logo {
      width: 24px;
      height: 24px;
      background-color: var(--main-button-active);
      border-radius: 3px;
      padding: 3px;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15%);
  }
  80% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
