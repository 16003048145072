import { defineStore } from 'pinia'
import { ref, shallowRef } from 'vue'
import { useRouter } from 'vue-router'

export enum ViewportRatio {
  fit = 'fit',
  cover = 'cover',
}

export enum CenterDisplay {
  viewport = 'viewport',
  navigator = 'navigator',
  newStubCapture = 'newStubCapture',
}

export enum DrawerSide {
  left = 'left',
  right = 'right',
}

export type DeepDrawerProperties = {
  component: ReturnType<typeof shallowRef>
  props?: Record<string, unknown>
}

export const useWorkspaceView = defineStore('workspaceView', () => {
  const viewportRatio = ref<ViewportRatio>(ViewportRatio.fit)
  const centerDisplay = ref<CenterDisplay>(CenterDisplay.viewport)
  const setViewportRatio = (ratio: ViewportRatio) => {
    viewportRatio.value = ratio
  }
  const setCenterDisplay = (display: CenterDisplay) => {
    centerDisplay.value = display
  }
  const navigatorViewport = ref<{
    factor: number
    relativePosition: { x: number; y: number }
  }>({
    factor: 1,
    relativePosition: {
      x: 0.5,
      y: 0.5,
    },
  })
  const setNavigatorViewport = (
    factor: number,
    relativePosition: { x: number; y: number },
  ) => {
    navigatorViewport.value = { factor, relativePosition }
  }

  const isFullscreen = ref(false)
  const uiIsHidden = ref(false)
  const uiIsBlocked = ref(false)

  const drawersOpen = ref<Record<DrawerSide, boolean>>({
    left: false,
    right: false,
  })
  const deepDrawersOpen = ref<Record<DrawerSide, null | DeepDrawerProperties>>({
    left: null,
    right: null,
  })
  const toggleDrawer = (side: DrawerSide) => {
    drawersOpen.value[side] = !drawersOpen.value[side]
    closeDeepDrawer(side)
  }
  const openDeepDrawer = (
    side: DrawerSide,
    component: ReturnType<typeof shallowRef>,
    props?: Record<string, unknown>,
  ) => {
    deepDrawersOpen.value[side] = { component, props }
  }
  const closeDeepDrawer = (side: DrawerSide) => {
    deepDrawersOpen.value[side] = null
  }
  const hideUI = (blockUi = false) => {
    uiIsHidden.value = true
    uiIsBlocked.value = blockUi
  }
  const revealUi = () => {
    uiIsHidden.value = false
    uiIsBlocked.value = false
  }
  const router = useRouter()
  router.beforeEach(() => {
    deepDrawersOpen.value[DrawerSide.left] = null
    deepDrawersOpen.value[DrawerSide.right] = null
  })

  return {
    centerDisplay,
    viewportRatio,
    drawersOpen,
    deepDrawersOpen,
    isFullscreen,
    uiIsHidden,
    uiIsBlocked,
    navigatorViewport,
    setNavigatorViewport,
    toggleDrawer,
    openDeepDrawer,
    closeDeepDrawer,
    setViewportRatio,
    setCenterDisplay,
    hideUI,
    revealUi,
  }
})
