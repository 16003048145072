import { ElementSymbol } from 'src/types/ElementSymbol'
import periodicElementProperties from 'src/constants/periodicElementProperties'
import { convertWeightToCounts } from 'src/lib/convertKLMWeights'
import PreferencesManager from 'src/classes/PreferencesManager'

export const generateElementXraySeries = (
  elements: ElementSymbol[],
): { x: number; y: number }[] => {
  const xrayPeakWidth = PreferencesManager.get<number>('xrayPeakWidth', 100)
  const data: Record<number, { x: number; y: number }> = {}

  elements.forEach((symbol) => {
    const properties = periodicElementProperties[symbol]
    if (!properties) {
      return
    }
    const lines = Object.values(properties.atomicProperties.xrayLines)

    lines
      .filter(({ energy }) => energy <= 20)
      .forEach((line) => {
        const x = Math.round(line.energy * 1000)
        if (!data[x]) {
          data[x] = { x, y: 0 }
        }
        const y = convertWeightToCounts(100, line.weight, line.energy)
        if (y > data[x].y) {
          data[x].y = y
        }

        const startX = x - xrayPeakWidth.value
        if (!data[startX]) {
          data[startX] = { x: startX, y: 0 }
        }
        const endX = x + xrayPeakWidth.value
        if (!data[endX]) {
          data[endX] = { x: endX, y: 0 }
        }
      })
  })
  return Object.values(data)
}
