<template>
  <q-layout
    v-if="spectrumSource.loadedSource && spectra.currentSpectrum?.spectrum"
    class="frame-layout"
    view="hHh lpR fFf"
  >
    <WindowBar @close="router.back()">
      <template #right>
        <q-btn dense flat square>
          <i class="ri-settings-4-fill" />
          <PreferencesMenu />
        </q-btn>
      </template>
      <WindowBarControls>
        <template #main-buttons>
          <q-btn
            v-if="liveCountsEnabled"
            v-tooltip="'Stop acquisition'"
            dense
            flat
            square
            @click="PreferencesManager.set('liveCountsEnabled', false)"
          >
            <i class="ri-stop-fill"></i>
          </q-btn>
          <q-btn
            v-else
            v-tooltip="'Start acquisition'"
            class="accent-button"
            dense
            flat
            square
            @click="PreferencesManager.set('liveCountsEnabled', true)"
          >
            <i class="ri-play-fill"></i>
          </q-btn>
          <q-btn
            v-tooltip="'Clear spectrum'"
            dense
            flat
            square
            @click="restoreSpectrum"
          >
            <i class="ri-forbid-2-line" />
          </q-btn>
        </template>
      </WindowBarControls>
    </WindowBar>
    <MenuDrawer :side="DrawerSide.right">
      <NavigationTools v-if="spectrumSource.loadedSource" />
    </MenuDrawer>
    <q-page-container class="fixed-full">
      <q-page class="full-height">
        <q-splitter
          v-model="splitterHeight"
          class="full-height"
          horizontal
          :limits="[0, 100]"
        >
          <template #before>
            <q-bar>
              <q-btn-dropdown
                class="text-white"
                dropdown-icon="expand_more"
                flat
                square
              >
                <template #label>
                  <i class="ri-line-chart-line q-mr-md" />
                  {{ spectra.currentSpectrum.name }}
                </template>
                <q-list>
                  <template v-for="group in spectra.groupedSpectra">
                    <q-item-label class="text-overline" header
                      >{{ group.name }}
                    </q-item-label>
                    <q-item
                      v-for="selectableSpectrum in group.spectra"
                      class="q-py-sm"
                      clickable
                      dense
                      @click="spectra.selectSpectrum(selectableSpectrum.id)"
                    >
                      <q-item-section avatar style="min-width: 28px">
                        <i
                          v-if="
                            spectra.currentSpectrum.id === selectableSpectrum.id
                          "
                          class="ri-circle-fill"
                          style="font-size: 6px"
                        />
                      </q-item-section>
                      <q-item-section>
                        {{ selectableSpectrum.name }}
                      </q-item-section>
                      <q-item-section
                        v-if="
                          spectra.currentSpectrum.id !== selectableSpectrum.id
                        "
                        side
                      >
                        <q-btn
                          flat
                          square
                          @click.stop="
                            spectra.toggleCompare(selectableSpectrum.id)
                          "
                        >
                          <i
                            v-if="
                              spectra.comparedSpectraIds.includes(
                                selectableSpectrum.id,
                              )
                            "
                            class="ri-subtract-line"
                          />
                          <i v-else class="ri-add-line" />
                        </q-btn>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </q-btn-dropdown>
              <q-btn-dropdown dropdown-icon="expand_more" flat no-caps square>
                <template #label>
                  {{ 'XRay Line display' }}
                </template>
                <q-list>
                  <q-item
                    v-for="preset in lineWeightPresets"
                    class="q-py-sm"
                    clickable
                    dense
                    @click="
                      PreferencesManager.set('minLineWeight', preset.cutoff)
                    "
                  >
                    <q-item-section avatar style="min-width: 28px">
                      <i
                        v-if="minLineWeight === preset.cutoff"
                        class="ri-circle-fill"
                        style="font-size: 6px"
                      />
                    </q-item-section>
                    <q-item-section>
                      {{ preset.name }}
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
              <q-space />
              <small class="q-mx-sm">Counts: {{ readableCounts }}</small>
              <small class="q-mx-sm"
                >Live Time: {{ spectrumSource.loadedSource.liveTime }}</small
              >
              <small class="q-mx-sm"
                >Real Time: {{ spectrumSource.loadedSource.realTime }}</small
              >
            </q-bar>
            <SpectrumPlot
              v-if="spectrum || spectrumSource.isLive"
              v-model:cursor="cursor"
              :spectra="visibleSpectra"
              :spectrum-line-types="spectrumLineTypes"
              style="height: calc(100% - 32px)"
              :visible-element-areas="elementAreas"
              :visible-element-lines="elementSelection.selected"
            >
              <QuantificationTable />
            </SpectrumPlot>
          </template>
          <template #after>
            <div
              class="row full-height"
              @click="elementSelection.unsetHighlight()"
            >
              <div class="col full-height"></div>
              <div
                class="col-7 full-height"
                @click="elementSelection.unsetHighlight()"
              >
                <PeriodicTable class="q-mx-auto" />
              </div>
              <div
                class="col full-height"
                @click="elementSelection.unsetHighlight()"
              >
                <InspectedPositionPlots
                  v-if="cursor && visibleSpectra.length"
                  v-model:position="cursor"
                  :baseline-spectrum="visibleSpectra[0]"
                  :splitter-height="splitterHeight"
                  @clear="cursor = null"
                />
              </div>
            </div>
          </template>
        </q-splitter>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import { useSpectrumSource } from 'src/stores/useSpectrumSource'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import SpectrumPlot from 'src/components/SpectrumPlot.vue'
import PeriodicTable from 'src/components/PeriodicTable.vue'
import MenuDrawer from 'src/components/MenuDrawer.vue'
import { EdxSpectrumPosition } from 'src/types/EdxSpectrumPosition'
import PreferencesMenu from 'src/views/SpectrumShow/PreferencesMenu.vue'
import Storage from 'src/classes/Storage'
import { EdxSpectrum } from 'src/types/EdxSpectrum'
import PreferencesManager from 'src/classes/PreferencesManager'
import WindowBar from 'src/components/WindowBar.vue'
import NavigationTools from 'src/components/NavigationTools.vue'
import QuantificationTable from 'src/components/QuantificationDisplay.vue'
import InspectedPositionPlots from 'src/components/InspectedPositionPlots.vue'
import { PlotType } from 'src/types/PlotType'
import { useSpectra } from 'src/stores/useSpectra'
import { useElementSelection } from 'src/stores/useElementSelection'
import { ElementSymbol } from 'src/types/ElementSymbol'
import periodicElementProperties from 'src/constants/periodicElementProperties'
import { NamedEdxSpectrum } from 'src/types/NamedEdxSpectrum'
import { DrawerSide } from 'src/stores/useWorkspaceView'
import WindowBarControls from 'src/components/WindowBarControls.vue'
import { cloneDeep } from 'lodash'

const router = useRouter()
const spectra = useSpectra()
const spectrumSource = useSpectrumSource()
const elementSelection = useElementSelection()
const spectrum = ref<EdxSpectrum | null>(null)
const cursor = ref<EdxSpectrumPosition | null>(null)
const splitterHeight = ref<number>(60)

const totalCounts = computed<number>(() => {
  if (spectrum.value === null) {
    return -1
  }
  return spectrum.value.reduce((total, { counts }) => total + counts, 0)
})
const readableCounts = computed<string | number>(() => {
  if (totalCounts.value < 1_000_000) {
    return totalCounts.value.toString()
  }

  const decimal = Math.pow(10, Math.floor(Math.log10(totalCounts.value)))
  const power = Math.round(Math.log(decimal) / Math.log(10))
  return `${(totalCounts.value / decimal).toFixed(2)}e${power}`
})

const visibleSpectra = computed<NamedEdxSpectrum[]>(() => {
  const namedSpectra: NamedEdxSpectrum[] = [...spectra.comparedSpectra]
  if (spectra.currentSpectrum) {
    if (spectrum.value) {
      namedSpectra.unshift({
        ...spectra.currentSpectrum,
        spectrum: spectrum.value,
      })
    }
  }
  return namedSpectra
})

const elementAreas = computed<ElementSymbol[]>(() => {
  if (
    elementSelection.hovered &&
    periodicElementProperties[elementSelection.hovered]
  ) {
    return [elementSelection.hovered]
  }
  if (
    elementSelection.highlighted &&
    periodicElementProperties[elementSelection.highlighted]
  ) {
    return [elementSelection.highlighted]
  }
  return []
})

const spectrumSpline = PreferencesManager.get<boolean>('spectrumSpline', true)
const spectrumLine = PreferencesManager.get<boolean>('spectrumLine', false)
const spectrumAreaSpline = PreferencesManager.get<boolean>(
  'spectrumAreaSpline',
  false,
)
const spectrumAreaLine = PreferencesManager.get<boolean>(
  'spectrumAreaLine',
  true,
)
const minLineWeight = PreferencesManager.get<number>('minLineWeight', 0.2)
const lineWeightPresets: { name: string; cutoff: number }[] = [
  { name: 'All', cutoff: 0 },
  { name: 'Minor', cutoff: 0.05 },
  { name: 'Major', cutoff: 0.2 },
]

const spectrumLineTypes = computed<PlotType[]>(() => {
  const types: PlotType[] = []
  if (spectrumSpline.value) {
    types.push(PlotType.Spline)
  }
  if (spectrumLine.value) {
    types.push(PlotType.Line)
  }
  if (spectrumAreaSpline.value) {
    types.push(PlotType.AreaSpline)
  }
  if (spectrumAreaLine.value) {
    types.push(PlotType.AreaLine)
  }
  return types
})
const liveCountsEnabled = PreferencesManager.get<boolean>(
  'liveCountsEnabled',
  false,
)
const liveCountsIntensity = PreferencesManager.get<number>(
  'liveCountsIntensity',
  1,
)
const liveCountsProbability = PreferencesManager.get<number>(
  'liveCountsProbability',
  0.1,
)
let intervalId: ReturnType<typeof setInterval> | null = null
watch(
  liveCountsEnabled,
  () => {
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
    if (!liveCountsEnabled.value || spectrumSource.isLive) {
      return
    }
    intervalId = setInterval(() => {
      if (spectrum.value === null) {
        return
      }
      spectrum.value.forEach((position) => {
        if (Math.random() < 1 - liveCountsProbability.value) {
          return
        }
        position.counts += Math.ceil(
          ((Math.random() * position.counts) / 100) * liveCountsIntensity.value,
        )
      })
    }, 200)
  },
  { immediate: true },
)

watch(
  () => spectra.currentSpectrum,
  () => {
    if (spectra.currentSpectrum && !spectrumSource.isLive) {
      spectrum.value = cloneDeep(spectra.currentSpectrum.spectrum)
    }
  },
  {
    immediate: true,
  },
)
onMounted(() => {
  if (!spectrumSource.loadedSource?.spectrum) {
    return router.push({ name: 'fileSelection' })
  }
})
onBeforeUnmount(async () => {
  if (spectrumSource.loadedSource?.spectrum) {
    await Storage.forget('previous-example-file')
    await Storage.forget('previous-live-mode')
  }
  spectrumSource.disconnectLiveMode()
  if (intervalId) {
    clearInterval(intervalId)
  }
})

const restoreSpectrum = (): void => {
  if (spectra.currentSpectrum) {
    spectrum.value = cloneDeep(spectra.currentSpectrum.spectrum)
  }
}
</script>
