import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useElementSelection } from 'src/stores/useElementSelection'
import { ElementSymbol } from 'src/types/ElementSymbol'

export const useAutoDetect = defineStore('autoDetect', () => {
  const elementSelection = useElementSelection()
  const detected = ref<ElementSymbol[]>(['Cu'])

  const addResult = (element: ElementSymbol) => {
    if (
      elementSelection.excluded.includes(element) ||
      elementSelection.selected.includes(element)
    ) {
      return
    }
    elementSelection.toggleSelected(element, false)
    if (!detected.value.includes(element)) {
      detected.value.push(element)
    }
  }
  const removeResult = (element: ElementSymbol) => {
    detected.value = detected.value.filter(
      (detectedElement) => detectedElement !== element,
    )
  }

  return {
    detected,
    addResult,
    removeResult,
  }
})
