import { ITranslationDefinitions } from 'src/types/TranslationDefinitions'
import common from './common.json'
import elements from './elements.json'

export default {
  translation: {
    common,
    elements,
  },
} as ITranslationDefinitions
