import type { App } from 'vue'
import { Ref, ref } from 'vue'
import Singleton from './Singleton'
import Storage from './Storage'

class PreferencesManager extends Singleton {
  public redrawKey: Ref<number> = ref(0)
  private isInitialized: boolean = false
  private config: Record<string, Ref<any>> = {}

  constructor() {
    super(PreferencesManager)
  }

  install(app: App) {
    app.config.globalProperties.$preferences = {
      isSet: this.isSet.bind(this),
      get: this.get.bind(this),
      set: this.set.bind(this),
    }

    return this.initialize()
  }

  get<ValueType = any>(
    key: string,
    fallback: ValueType | null = null,
  ): Ref<ValueType> {
    if (!this.isInitialized) {
      console.warn(
        `Reading config key [${key}] before config is initialized. Call initialize or isSet to assure initialized config`,
      )
    }
    if (!this.config[key]) {
      this.config[key] = ref(fallback)
    }
    return this.config[key]
  }

  async initialize(): Promise<void> {
    if (this.isInitialized) {
      return
    }
    await this._loadConfig()
  }

  async isSet(key: string): Promise<boolean> {
    if (!this.isInitialized) {
      await this.initialize()
    }
    return this.config[key] !== undefined && this.config[key].value !== null
  }

  async set<ValueType = any>(
    key: string,
    value: ValueType,
    redraw: boolean = false,
  ): Promise<void> {
    if (!this.config[key]) {
      this.config[key] = ref<ValueType>()
    }
    this.config[key].value = value
    await this._persistConfig()
    if (redraw) {
      this.redrawKey.value++
    }
  }

  private async _persistConfig(): Promise<void> {
    await Storage.set(
      'preferences',
      Object.keys(this.config).reduce(
        (object, key) => ({
          ...object,
          [key]: this.config[key].value,
        }),
        {},
      ),
    )
  }

  private async _loadConfig(): Promise<void> {
    const config = await Storage.get('preferences', {})
    this.config = Object.keys(config).reduce(
      (object, key) => ({
        ...object,
        [key]: ref(config[key]),
      }),
      {},
    )
    this.isInitialized = true
  }
}

export default new PreferencesManager()
