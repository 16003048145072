import { Point, Series } from 'highcharts'

export const getNearestPoint = (
  series: Series,
  xValue: number,
): Point | null => {
  return series.data.reduce((nearest: Point | null, currentPoint: Point) => {
    if (
      nearest === null ||
      Math.abs(nearest.x - xValue) > Math.abs(currentPoint.x - xValue)
    ) {
      return currentPoint
    }
    return nearest
  }, null)
}
