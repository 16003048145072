<template>
  <div class="row full-height">
    <q-inner-loading :showing="fileIsLoading" />
    <div class="col-6 q-pa-md relative-position">
      <h1 class="pageTitle">Edx Spectrum UI Sandbox</h1>
      <div class="absolute-center full-width q-pa-md">
        <h2 class="text-h3 text-center">Select a spectrum file..</h2>
        <q-list>
          <q-item
            v-for="file in availableFiles"
            :key="file.path"
            v-ripple="!fileIsLoading"
            :clickable="!fileIsLoading"
            :disable="fileIsLoading"
            @click="readPublicFileContents(file.path, file.type)"
          >
            <q-item-section avatar class="gt-xs">
              <q-avatar color="primary" square text-color="white">
                <i class="ri-file-3-line" />
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label class="ellipsis">{{ file.name }}</q-item-label>
              <q-item-label caption>{{ file.type }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
    <div class="col-6 relative-position withBackground">
      <div class="absolute-center full-width q-pa-md text-center items-center">
        <h2 class="text-h3">..or upload your own</h2>
        <p>
          Drag a .msa or MSEDS spectrum file into the screen or select one on
          your device
        </p>
        <q-btn
          v-if="fileInputRef"
          class="no-border-radius"
          color="white"
          label="Select file"
          outline
          unelevated
          @click="fileInputRef.click()"
        />
      </div>
      <div
        v-if="fileIsDragging"
        class="absolute-full no-pointer-events items-center justify-center flex"
        style="background: rgba(255, 255, 255, 0.3)"
      >
        <div class="text-center">
          <i class="text-h2 text-black ri-upload-line" />
          <h2 class="text-h3 text-black">Drop the file here to upload</h2>
        </div>
      </div>
      <input
        ref="fileInputRef"
        accept=".txt, .msa"
        class="fileInput"
        :disabled="fileIsLoading"
        type="file"
        @change="fileUploaded"
        @dragenter="fileIsDragging = true"
        @dragleave="fileIsDragging = false"
      />
    </div>

    <button
      v-if="liveIsAvailable"
      class="liveModeButton"
      @click="connectLiveMode"
    >
      <span>Or try out <br /><strong>Live mode</strong></span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useSpectrumSource } from 'src/stores/useSpectrumSource'
import { useRouter } from 'vue-router'
import Storage from 'src/classes/Storage'
import { SpectrumFileType } from 'src/types/SpectrumFileType'
import { showToast } from 'src/composables/showDialogs'
import ApolloManager from 'src/api/ApolloManager'
import spectrumModule from 'src/api/graphql/spectrumModule'

type AvailableFile = { name: string; path: string; type: SpectrumFileType }

const router = useRouter()
const spectrumFile = useSpectrumSource()
const availableFiles = ref<AvailableFile[]>()
const fileIsDragging = ref(false)
const fileIsLoading = ref(false)
const liveIsAvailable = ref(false)
const fileInputRef = ref<HTMLInputElement>()
const loadAvailableFiles = async (): Promise<void> => {
  const files = await import.meta.glob('public/spectra/*')
  availableFiles.value = Object.keys(files)
    .map((path: string) => {
      const name = path.substring(path.lastIndexOf('/') + 1)
      const type = spectrumFile.getTypeForFile(path)
      path = path.replace('/public', '')
      return { name, path, type }
    })
    .filter((file) => file.type !== null) as AvailableFile[]
}

const fileUploaded = async (): Promise<void> => {
  await Storage.forget('previous-example-file')
  fileIsLoading.value = true
  fileIsDragging.value = false
  if (!fileInputRef.value?.files?.length) {
    return
  }
  const file = fileInputRef.value.files[0]
  const type = spectrumFile.getTypeForFile(file.name)
  if (type === null) {
    fileIsLoading.value = false
    await showToast('common.Error', 'Filetype is not supported', 'negative')
    return
  }
  try {
    const uploadedFile = await spectrumFile.readUploadedFileContents(file, type)
    spectrumFile.setFile(uploadedFile)
    await router.push({ name: 'spectrum' })
  } catch (e) {
    fileIsLoading.value = false
    console.error(e)
    await showToast('common.Error', 'negative')
  }
}

const readPublicFileContents = async (
  path: string,
  type: SpectrumFileType,
): Promise<void> => {
  fileIsLoading.value = true
  try {
    const publicFile = await spectrumFile.readPublicFileContents(path, type)
    await Storage.set('previous-example-file', path)
    await Storage.forget('previous-live-mode')
    spectrumFile.setFile(publicFile)
    await router.push({ name: 'spectrum' })
  } catch (e) {
    fileIsLoading.value = false
    console.error(e)
    await showToast('common.Error', 'negative')
  }
}

const loadPreviousFile = async (): Promise<void> => {
  fileIsLoading.value = true
  await loadAvailableFiles()
  if (await Storage.has('previous-example-file')) {
    const path = await Storage.get('previous-example-file')
    const file = availableFiles.value?.find((file) => file.path === path)
    if (file && file.type !== null) {
      return readPublicFileContents(file.path, file.type)
    }
    await Storage.forget('previous-example-file')
  }
  fileIsLoading.value = false
}
const checkLiveServerAvailability = async (): Promise<void> => {
  if (await spectrumModule.ping('localhost')) {
    liveIsAvailable.value = true
    ApolloManager.setBaseUrl('localhost')
    if (await Storage.has('previous-live-mode')) {
      connectLiveMode()
    }
  }
}

const connectLiveMode = async (): Promise<void> => {
  fileIsLoading.value = true
  await spectrumFile.connectLiveMode()
  await Storage.forget('previous-example-file')
  await Storage.set('previous-live-mode', true)
  await router.push({ name: 'spectrum' })
}

onMounted(() => {
  loadPreviousFile()
  checkLiveServerAvailability()
})
</script>

<style scoped lang="scss">
.withBackground {
  &:before {
    content: '';
    background-image: url('/background.jpg');
    background-size: cover;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
  }
}

.pageTitle {
  display: block;
  font-size: 5.5rem;
  max-height: 20vh;
  overflow: hidden;
}

.absolute-center {
  top: 30vh;
  transform: translate(-50%, 0);
  max-height: 70vh;
  overflow-y: auto;
}

.fileInput {
  position: absolute;
  opacity: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.liveModeButton {
  position: absolute;
  border: 1px solid black;
  bottom: 0;
  right: 0;
  height: 40%;
  max-width: 50%;
  aspect-ratio: 1/1;
  box-sizing: border-box;
  padding: 60px;
  display: flex;
  align-items: end;
  justify-content: right;
  font-size: 1.5rem;
  color: white;
  background-color: #576471;

  -webkit-mask: linear-gradient(-45deg, black 50%, transparent 50%);
  -webkit-mask-repeat: no-repeat;
}

@media (max-width: $breakpoint-md-min) or (max-height: 600px) {
  .pageTitle {
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 1;
  }
}

@media (max-width: $breakpoint-sm-min) or (max-height: 600px) {
  .pageTitle {
    font-size: 4rem;
    line-height: 4rem;
  }
  .text-h3 {
    font-size: 2rem;
  }
}
</style>
