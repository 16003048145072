<template>
  <div
    v-if="elementSelection.selected.length"
    class="quantification"
    :class="{ 'quantification--open': !collapsed }"
    @mouseleave="elementSelection.setHovered(null)"
  >
    <q-btn
      class="quantification__toggle"
      no-caps
      size="sm"
      square
      @click="collapsed = !collapsed"
    >
      <i v-if="collapsed" class="ri-arrow-down-s-line" />
      <i v-else class="ri-arrow-up-s-line" />
    </q-btn>
    <div v-if="!collapsed" class="quantification__content">
      <Chart
        v-if="displayType === DisplayTypes.BAR_CHART"
        class="quantification__content__bar-chart"
        :options="barChartOptions"
      />
      <Chart
        v-else-if="displayType === DisplayTypes.CIRCLE_DIAGRAM"
        class="quantification__content__circle-diagram"
        :options="circleDiagramOptions"
      />
      <q-table
        v-else-if="displayType === DisplayTypes.TABLE"
        class="quantification__content__table"
        :columns="tableRows"
        dense
        flat
        hide-bottom
        hide-pagination
        row-key="name"
        :rows="elements"
      >
        <template #body="props">
          <q-tr @mouseenter="elementSelection.setHovered(props.row.element)">
            <q-td key="element" :props="props">
              {{ props.row.element }}
            </q-td>
            <q-td key="weightPercentage" :props="props">
              {{ props.row.weightPercentage.toFixed(3) }}%
            </q-td>
            <q-td key="atomPercentage" :props="props">
              {{ props.row.atomPercentage.toFixed(3) }}%
            </q-td>
          </q-tr>
        </template>
      </q-table>
      <q-btn
        class="quantification__content__type-switch"
        no-caps
        size="sm"
        square
        @click="nextType"
      >
        <i
          v-if="displayType === DisplayTypes.BAR_CHART"
          class="ri-pie-chart-line"
        ></i>
        <i
          v-else-if="displayType === DisplayTypes.CIRCLE_DIAGRAM"
          class="ri-table-line"
        ></i>
        <i
          v-else-if="displayType === DisplayTypes.TABLE"
          class="ri-bar-chart-horizontal-fill"
        ></i>
      </q-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart } from 'highcharts-vue'
import { computed, ref } from 'vue'
import { DetectedElement } from 'src/types/detectedElement'
import { useElementSelection } from 'src/stores/useElementSelection'
import { Options } from 'highcharts'
import { ElementSymbol } from 'src/types/ElementSymbol'

const collapsed = ref<boolean>(false)

enum DisplayTypes {
  TABLE,
  BAR_CHART,
  CIRCLE_DIAGRAM,
}

const displayType = ref<DisplayTypes>(DisplayTypes.TABLE)
const elementSelection = useElementSelection()

const nextType = () => {
  switch (displayType.value) {
    case DisplayTypes.BAR_CHART:
      displayType.value = DisplayTypes.CIRCLE_DIAGRAM
      return
    case DisplayTypes.CIRCLE_DIAGRAM:
      displayType.value = DisplayTypes.TABLE
      return
    case DisplayTypes.TABLE:
      displayType.value = DisplayTypes.BAR_CHART
      return
  }
}
const elements = computed<DetectedElement[]>(() => {
  let weightRemainder = 100
  let atomRemainder = 100
  const lastIndex = elementSelection.selected.length - 1
  return elementSelection.selected
    .map((symbol, index) => {
      const weightPercentage =
        index !== lastIndex ? Math.random() * weightRemainder : weightRemainder
      weightRemainder -= weightPercentage
      const atomPercentage =
        index !== lastIndex ? Math.random() * atomRemainder : atomRemainder
      atomRemainder -= atomPercentage

      return {
        element: symbol,
        weightPercentage,
        atomPercentage,
      }
    })
    .sort((elementA, elementB) =>
      elementA.weightPercentage > elementB.weightPercentage ? -1 : 1,
    )
})

const tableRows: {
  name: string
  label: string
  field: string | ((row: any) => any)
  align?: 'left' | 'right' | 'center'
  sortable?: boolean
  format?: (val: any, row: any) => any
}[] = [
  {
    name: 'element',
    label: 'Element',
    align: 'left',
    field: 'element',
    sortable: true,
  },
  {
    name: 'weightPercentage',
    label: 'Weight',
    align: 'left',
    field: (row: any) => row.weightPercentage.toFixed(3),
    format: (value: any) => `${value}%`,
    sortable: true,
  },
  {
    name: 'atomPercentage',
    label: 'Atom',
    align: 'left',
    field: (row: any) => row.atomPercentage.toFixed(3),
    format: (value: any) => `${value}%`,
    sortable: true,
  },
]

const barChartOptions = computed<Options>(() => {
  return {
    styledMode: true,
    title: {
      text: '',
    },
    chart: {
      height: elements.value.length * 30,
      width: 200,
      margin: [0, 30, 0, 40],
      animation: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          className: 'percentage-label',
          formatter: function () {
            return `${this.y?.toFixed(3) ?? '-'}%`
          },
        },
      },
      bar: {
        borderRadius: 0,
        point: {
          events: {
            mouseOver: function () {
              elementSelection.setHovered(this.name as ElementSymbol)
            },
            mouseOut: function () {
              elementSelection.setHovered(null)
            },
          },
        },
      },
    },
    xAxis: {
      title: {
        text: '',
      },
      categories: elements.value.map(({ element }) => element),
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: '',
      },
      min: 0,
      max: 100,
      gridLineWidth: 0,
    },
    tooltip: { enabled: false },
    series: [
      {
        type: 'bar',
        data: elements.value.map((element) => {
          return {
            name: element.element,
            y: element.weightPercentage,
          }
        }),
      },
    ],
  }
})
const circleDiagramOptions = computed<Options>(() => {
  return {
    styledMode: true,
    title: {
      text: '',
    },
    chart: {
      height: 200,
      width: 200,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      className: 'tooltip__svg',
      formatter: function () {
        const element = this.key
        const amount = this.y?.toFixed(3) ?? '-'
        return `<div class="tooltip__inner"><div class="tooltip__inner__element">${element}</div><div class="tooltip__inner__amount">${amount}%</div></div>`
      },
      positioner: function (_labelWidth, labelHeight) {
        return {
          x: 0,
          y: this.chart.plotHeight + this.chart.plotTop - labelHeight,
        }
      },
      useHTML: true,
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        dataLabels: [
          {
            enabled: true,
            distance: -20,
            className: 'element-label',
            filter: {
              operator: '>',
              property: 'percentage',
              value: 4,
            },
          },
        ],
        states: {
          hover: {
            halo: {
              size: 3,
            },
          },
        },
      },
      pie: {
        point: {
          events: {
            mouseOver: function () {
              elementSelection.setHovered(this.name as ElementSymbol)
            },
            mouseOut: function () {
              elementSelection.setHovered(null)
            },
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        data: elements.value.map((element) => {
          return {
            name: element.element,
            y: element.weightPercentage,
          }
        }),
      },
    ],
  }
})
</script>

<style scoped lang="scss">
.quantification {
  position: relative;
  width: 36px;

  &__content__type-switch,
  &__toggle {
    position: absolute;
    padding: 0;
    top: 0;
    z-index: 1;
    color: var(--text-color);
    border: 1px solid currentColor;
  }

  &__toggle {
    right: 0;
    color: white;
    height: 36px;
    width: 36px;
  }

  &__content {
    background-color: var(--bg-tint);
    max-height: 30vh;
    overflow-y: auto;
    padding-top: 28px;

    &__type-switch {
      left: 0;
    }
  }

  &--open {
    width: fit-content;
    border: 1px solid var(--text-color);

    .quantification {
      &__content__type-switch,
      &__toggle {
        border-top: none;
        height: 28px;
        width: 28px;
      }

      &__content__type-switch {
        border-left: none;
      }

      &__toggle {
        color: var(--text-color);
        border-right: none;
      }
    }
  }
}
</style>
<style lang="scss">
.quantification {
  .highcharts-background {
    fill: var(--bg-tint);
  }

  .quantification__content__bar-chart {
    .highcharts-axis-labels {
      text {
        fill: white !important;
      }
    }

    .highcharts-data-label {
      .highcharts-text-outline {
        opacity: 0;
      }

      &.percentage-label {
        text {
          stroke: none !important;
          fill: white !important;
        }
      }
    }

    .highcharts-point {
      fill: transparent !important;
    }
  }

  .quantification__content__circle-diagram {
    .highcharts-tooltip {
      path {
        opacity: 0;
      }
    }

    .highcharts-data-label {
      .highcharts-text-outline {
        opacity: 0;
      }

      &.element-label {
        text {
          stroke: none !important;
          fill: white !important;
        }
      }
    }

    .highcharts-data-label-connector {
      stroke: white;
      fill: none;
    }

    .highcharts-halo {
      fill: var(--main-button-back) !important;
      fill-opacity: 1 !important;
    }

    .highcharts-point {
      fill: transparent !important;

      &.highcharts-point-hover {
        stroke: transparent !important;
      }

      &.highcharts-point-inactive {
        opacity: 1 !important;
      }
    }

    .tooltip__svg {
      span {
        left: 0 !important;
        top: 25px !important;
        width: 200px;
      }
    }

    .tooltip__inner {
      width: 100%;
      background: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: white;

      &__element {
        text-align: center;
        font-weight: bold;
      }

      &__amount {
        text-align: center;
      }
    }
  }
}
</style>
