import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ElementSymbol } from 'src/types/ElementSymbol'
import periodicElementProperties from 'src/constants/periodicElementProperties'

export const useElementSelection = defineStore('elementSelection', () => {
  const selected = ref<ElementSymbol[]>(['Cu', 'Pb'])
  const excluded = ref<ElementSymbol[]>(['C'])
  const highlighted = ref<ElementSymbol | null>(null)
  const hovered = ref<ElementSymbol | null>(null)

  const toggleSelected = (
    elementSymbol: ElementSymbol | null,
    applyHighlight: boolean = true,
  ) => {
    if (!elementSymbol) {
      return
    }
    const properties = periodicElementProperties[elementSymbol]
    if (
      !properties ||
      !Object.values(properties.atomicProperties.xrayLines).some(
        ({ energy }) => energy < 20,
      )
    ) {
      return
    }
    if (selected.value.includes(elementSymbol)) {
      selected.value = selected.value.filter(
        (symbol) => symbol != elementSymbol,
      )
    } else {
      selected.value.push(elementSymbol)
      if (excluded.value.includes(elementSymbol)) {
        excluded.value = excluded.value.filter(
          (symbol) => symbol != elementSymbol,
        )
      }
    }
    if (applyHighlight && highlighted.value !== elementSymbol) {
      toggleHighlighted(elementSymbol)
    }
  }
  const toggleHighlighted = (elementSymbol: ElementSymbol) => {
    if (highlighted.value === elementSymbol) {
      return unsetHighlight()
    }
    highlighted.value = elementSymbol
  }
  const unsetHighlight = () => {
    highlighted.value = null
  }
  const toggleExcluded = (elementSymbol: ElementSymbol | null) => {
    if (!elementSymbol) {
      return
    }
    if (excluded.value.includes(elementSymbol)) {
      excluded.value = excluded.value.filter(
        (symbol) => symbol != elementSymbol,
      )
    } else {
      excluded.value.push(elementSymbol)

      if (selected.value.includes(elementSymbol)) {
        selected.value = selected.value.filter(
          (symbol) => symbol != elementSymbol,
        )
      }
    }
  }

  const setHovered = (element: ElementSymbol | null): void => {
    hovered.value = element
  }

  const clear = (): void => {
    selected.value = []
  }
  const clearAll = (): void => {
    selected.value = []
    excluded.value = []
  }
  return {
    selected,
    highlighted,
    excluded,
    hovered,
    toggleSelected,
    toggleExcluded,
    toggleHighlighted,
    unsetHighlight,
    clear,
    clearAll,
    setHovered,
  }
})
